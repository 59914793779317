import styled from 'styled-components';
import { ExternalLink, ThemedText } from 'theme/components';

const StyledLink = styled(ExternalLink)`
  font-weight: 535;
  color: ${({ theme }) => theme.neutral2};
`;

const LastUpdatedText = styled.span`
  color: ${({ theme }) => theme.neutral3};
`;

const LAST_UPDATED_DATE = '6.7.23';

export default function PrivacyPolicyNotice() {
  return (
    <ThemedText.BodySmall color='neutral2'>
      By connecting a wallet, you agree to Uniswap Labs&apos;{' '}
      <StyledLink href='https://uniswap.org/terms-of-service/'>
        Terms of Service{' '}
      </StyledLink>
      and consent to its{' '}
      <StyledLink href='https://uniswap.org/privacy-policy'>
        Privacy Policy.
      </StyledLink>
      <LastUpdatedText>
        {' ('}
        Last updated
        {` ${LAST_UPDATED_DATE})`}
      </LastUpdatedText>
    </ThemedText.BodySmall>
  );
}
