import { ChainConfig, NetworkType } from './chain.type';

export const OasisSapphireTestnet: ChainConfig = {
  chainId: 23295,
  network: 'oasis-sapphire-testnet',
  label: 'Oasis Sapphire Testnet',
  docs: 'https://docs.oasis.io/dapp/sapphire/',
  infoLink: 'https://info.uniswap.org/#/',
  type: NetworkType.L1,
  explorer: 'https://testnet.explorer.sapphire.oasis.dev',
  rpcs: [
    'https://nd-618-450-031.p2pify.com/9d6f832c42a8f95be408552c6e45b3e8',
    'https://nd-401-822-539.p2pify.com/f917a370a928d06ff307495a85b8ac3f',
    'https://testnet.sapphire.oasis.dev',
  ],
  subgraph:
    'https://graph.dev.neby.exchange/subgraphs/name/uniswap-v3-sapphire-testnet',
  blockSubgraph:
    'https://graph.dev.neby.exchange/subgraphs/name/blocks-sapphire-testnet',
  harvesterSubgraph:
    'https://graph.dev.neby.exchange/subgraphs/name/harvester-sapphire-testnet',
  stakerSubgraph:
    'https://graph.dev.neby.exchange/subgraphs/name/liquidity-position-staker-sapphire-testnet',
  nativeCurrency: { name: 'Oasis Network', symbol: 'ROSE', decimals: 18 },
  addresses: {
    v3CoreFactory: '0x8Af3478A6DFb883D7A6279154BDb8eC5E0Cf2494',
    multicall: '0xd5cA9bb5F1be7cce1D1a6f72AA432792B9515e7b',
    quoter: '0x91b84FA56abACE837082d2B7240DCe31a7301430',
    v3Migrator: '0x518036Ff1328aD6aFe80505d2e7eFcD92C30a66F',
    nonfungiblePositionManager: '0x706Da1546396ae2d6c734933Daaf6EeF26e2DE4B',
    tickLens: '0x9de9Fd4a0DB124d5A80911E6F976d5e2A65CB826',
    swapRouter02: '0x03271d314c7B170Fe4078e9BaBC1E355a26e9Bc3',
    universalRouter: '0xae8FFaf2a835a4B4A799C18BbE65e45Fbae2e175',
    permit2: '0x51A0eeEd1ec83ab08f9B2bCaeb0771f0fCbD8b04',
    harvester: '0xaB58055496b8B2c5172F953D1ed55B6Eb48F110d',
    staker: '0x7a2Bc90944978Bc478408012a9C86A479307F942',
  },
  weth9: {
    address: '0xB759a0fbc1dA517aF257D5Cf039aB4D86dFB3b94',
    symbol: 'wROSE',
    decimals: 18,
    name: 'Wrapped ROSE',
  },
};
