import { roundAndPluralize } from './roundAndPluralize';
import ms from 'ms';

const SECOND = ms('1s');
const MINUTE = ms('1m');
const HOUR = ms('1h');
const DAY = ms('1d');
const WEEK = ms('7d');
const MONTH = ms('30d');

interface TimePeriod {
  milliseconds: number;
  pluralLabel: (i: number) => string;
}

const timePeriods: TimePeriod[] = [
  {
    milliseconds: MONTH,
    pluralLabel: (i: number) => (i === 1 ? 'month' : 'months')
  },
  {
    milliseconds: WEEK,
    pluralLabel: (i: number) => (i === 1 ? 'week' : 'weeks')
  },
  {
    milliseconds: DAY,
    pluralLabel: (i: number) => (i === 1 ? 'day' : 'days')
  },
  {
    milliseconds: HOUR,
    pluralLabel: (i: number) => (i === 1 ? 'hour' : 'hours')
  },
  {
    milliseconds: MINUTE,
    pluralLabel: (i: number) => (i === 1 ? 'minute' : 'minutes')
  },
  {
    milliseconds: SECOND,
    pluralLabel: (i: number) => (i === 1 ? 'second' : 'seconds')
  }
];

export function timeUntil(date: Date, originalDate?: Date): string | undefined {
  const referenceDate = originalDate ?? new Date();

  const milliseconds = date.getTime() - referenceDate.getTime();

  if (milliseconds < 0) return undefined;

  const monthInterval = milliseconds / MONTH;
  if (monthInterval >= 100) return '99+ months';

  for (const period of timePeriods) {
    const interval = milliseconds / period.milliseconds;

    if (interval >= 1) {
      return `${Math.floor(interval)} ${period.pluralLabel(interval)}`;
    }
  }
  return undefined;
}

export const timeLeft = (targetDate: Date): string => {
  const countDown = new Date(targetDate).getTime() - new Date().getTime();
  const days = Math.floor(countDown / DAY);
  const hours = Math.floor((countDown % DAY) / HOUR);
  const minutes = Math.floor((countDown % HOUR) / MINUTE);

  return `${days !== 0 ? roundAndPluralize(days, 'day') : ''} ${
    hours !== 0 ? roundAndPluralize(hours, 'hour') : ''
  } ${roundAndPluralize(minutes, 'minute')}`;
};
