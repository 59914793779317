import {
  ConfirmedIcon,
  CurrencyLoader,
  LoadingIndicatorOverlay,
  PaperIcon,
  SubmittedIcon
} from './Logos';
import { ConfirmModalState } from './constants';
import type { PendingConfirmModalState } from './types';
import { ChainId } from '@uniswap/sdk-core';
import { motion } from 'framer-motion';
import { useRef } from 'react';

import { cn } from 'utils/cn';

import { useStepContents } from './hooks';
import { TransactionStatus } from 'graphql/data/__generated__/types-and-hooks';
import type { SwapResult } from 'hooks/useSwapCallback';
import { useUnmountingAnimation } from 'hooks/useUnmountingAnimation';
import { useWeb3React } from 'hooks/useWeb3React';
import { UniswapXOrderStatus } from 'lib/hooks/orders/types';
import { useOrder } from 'state/signatures/hooks';
import {
  useIsTransactionConfirmed,
  useSwapTransactionStatus
} from 'state/transactions/hooks';

import { OrderContent } from 'components/AccountDrawer/MiniPortfolio/Activity/OffchainActivityModal';
import { AnimationType } from 'theme/components/FadePresence';

import type { InterfaceTrade } from 'state/routing/types';

const StepTitleAnimationContainer = ({
  children,
  disableEntranceAnimation = false,
  currentStepContainerRef,
  className
}: {
  children: React.ReactNode;
  disableEntranceAnimation?: boolean;
  currentStepContainerRef: React.RefObject<HTMLDivElement> | undefined;
  className?: string;
}) => {
  const variants = {
    hidden: { opacity: 0, x: 50 },
    visible: { opacity: 1, x: 0 },
    exit: { opacity: 0, x: -50 }
  };

  return (
    <motion.div
      className={className}
      ref={currentStepContainerRef}
      initial={disableEntranceAnimation ? '' : 'hidden'}
      animate='visible'
      exit='exit'
      variants={variants}
      transition={{ duration: 0.3, ease: 'easeInOut' }}
    >
      {children}
    </motion.div>
  );
};

interface PendingModalContentProps {
  steps: PendingConfirmModalState[];
  currentStep: PendingConfirmModalState;
  trade?: InterfaceTrade;
  swapResult?: SwapResult;
  wrapTxHash?: string;
  hideStepIndicators?: boolean;
  tokenApprovalPending?: boolean;
  revocationPending?: boolean;
  swapError?: Error | string;
  onRetryUniswapXSignature?: () => void;
}

export const PendingModalContent = ({
  steps,
  currentStep,
  trade,
  swapResult,
  wrapTxHash,
  hideStepIndicators,
  tokenApprovalPending = false,
  revocationPending = false,
  swapError,
  onRetryUniswapXSignature
}: PendingModalContentProps) => {
  const { chainId } = useWeb3React();

  const swapStatus = useSwapTransactionStatus(swapResult);
  const order = useOrder('');

  const swapConfirmed =
    swapStatus === TransactionStatus.Confirmed ||
    order?.status === UniswapXOrderStatus.FILLED;
  const wrapConfirmed = useIsTransactionConfirmed(wrapTxHash);

  const swapPending = swapResult !== undefined && !swapConfirmed;
  const wrapPending = wrapTxHash != undefined && !wrapConfirmed;

  const stepContents = useStepContents({
    approvalCurrency: trade?.inputAmount.currency,
    swapConfirmed,
    swapPending,
    wrapPending,
    tokenApprovalPending,
    revocationPending,
    swapResult,
    trade,
    chainId,
    swapError,
    onRetryUniswapXSignature
  });

  const currentStepContainerRef = useRef<HTMLDivElement>(null);
  useUnmountingAnimation(currentStepContainerRef, () => AnimationType.EXITING);

  if (steps.length === 0) {
    return null;
  }

  // Return finalized-order-specifc content if available
  if (order && order.status !== UniswapXOrderStatus.OPEN) {
    return <OrderContent order={order} />;
  }

  // On mainnet, we show a different icon when the transaction is submitted but pending confirmation.
  const showSubmitted =
    swapPending && !swapConfirmed && chainId === ChainId.MAINNET;
  const showSuccess =
    swapConfirmed || (chainId !== ChainId.MAINNET && swapPending);

  const transactionPending =
    revocationPending || tokenApprovalPending || wrapPending || swapPending;

  return (
    <div className='mb-2 mt-1.5 flex w-full flex-col items-center justify-start gap-8'>
      <div className='relative flex size-16 justify-center rounded-full'>
        {/* Shown during the setup approval step, and fades out afterwards. */}
        {currentStep === ConfirmModalState.APPROVING_TOKEN && <PaperIcon />}
        {/* Shown during the setup approval step as a small badge. */}
        {/* Scales up once we transition from setup approval to permit signature. */}
        {/* Fades out after the permit signature. */}
        {currentStep !== ConfirmModalState.PENDING_CONFIRMATION && (
          <CurrencyLoader
            currency={trade?.inputAmount.currency}
            asBadge={currentStep === ConfirmModalState.APPROVING_TOKEN}
          />
        )}
        {/* Shown only during the final step under "success" conditions, and scales in. */}
        {currentStep === ConfirmModalState.PENDING_CONFIRMATION &&
          showSuccess && <ConfirmedIcon />}
        {/* Shown only during the final step on mainnet, when the transaction is sent but pending confirmation. */}
        {currentStep === ConfirmModalState.PENDING_CONFIRMATION &&
          showSubmitted && <SubmittedIcon />}
        {/* Scales in for any step that waits for an onchain transaction, while the transaction is pending. */}
        {/* On the last step, appears while waiting for the transaction to be signed too. */}
        {((currentStep !== ConfirmModalState.PENDING_CONFIRMATION &&
          transactionPending) ||
          (currentStep === ConfirmModalState.PENDING_CONFIRMATION &&
            !showSuccess &&
            !showSubmitted)) && <LoadingIndicatorOverlay />}
      </div>
      <div className='flex w-full flex-col items-center justify-start gap-6'>
        <div className='relative flex w-full grow'>
          {steps.map(
            (step) =>
              Boolean(step === currentStep) && (
                <StepTitleAnimationContainer
                  className='flex h-full w-full flex-col items-center justify-start gap-6'
                  disableEntranceAnimation={steps[0] === currentStep}
                  key={step}
                  currentStepContainerRef={
                    step === currentStep ? currentStepContainerRef : undefined
                  }
                >
                  <span className='text-base font-medium text-white'>
                    {stepContents[step].title}
                  </span>

                  <span className='w-full text-sm font-normal text-white'>
                    {stepContents[step].subtitle}
                  </span>
                </StepTitleAnimationContainer>
              )
          )}
        </div>

        {stepContents[currentStep].bottomLabel && (
          <div className='mt-4 flex min-h-6 flex-row items-center justify-center text-xs font-medium text-blue-200'>
            <span>{stepContents[currentStep].bottomLabel}</span>
          </div>
        )}
      </div>

      {stepContents[currentStep].button && (
        <div className='mt-4 flex min-h-6 flex-row items-center justify-center'>
          {stepContents[currentStep].button}
        </div>
      )}
      {!hideStepIndicators && !showSuccess && (
        <div className='mt-4 flex min-h-6 flex-row items-center justify-center'>
          {steps.map((_, i) => (
            <div
              className={cn(
                'size-2.5 rounded-full transition-colors duration-300',
                {
                  'bg-gray-100': steps.indexOf(currentStep) === i,
                  'bg-gray-600': steps.indexOf(currentStep) !== i
                }
              )}
              key={i}
            />
          ))}
        </div>
      )}
    </div>
  );
};
