import type { LineItemData, SwapLineItemProps } from './types';
import type { PropsWithChildren } from 'react';
import React from 'react';
import { animated } from 'react-spring';

import { cn } from 'utils/cn';

import { useLineItem } from './hooks';
import useHoverProps from 'hooks/useHoverProps';
import { useIsMobile } from 'nft/hooks';

import { LoadingRow } from 'components/Loader/styled';
import { MouseoverTooltip } from 'components/Tooltip';
import { ExternalLink } from 'components/composed/ExternalLink';

export const FOTTooltipContent = () => (
  <>
    Some tokens take a fee when they are bought or sold, which is set by the
    token issuer. Uniswap does not receive any of these fees.{' '}
    <ExternalLink href='https://support.uniswap.org/hc/en-us/articles/18673568523789-What-is-a-token-fee-'>
      Learn more
    </ExternalLink>
  </>
);

const Loading = ({ width = 50 }: { width?: number }) => (
  <LoadingRow data-testid='loading-row' height={15} width={width} />
);

type ValueWrapperProps = PropsWithChildren<{
  lineItem: LineItemData;
  labelHovered: boolean;
  syncing: boolean;
}>;

const ValueWrapper = ({
  children,
  lineItem,
  labelHovered,
  syncing
}: ValueWrapperProps) => {
  const { TooltipBody, tooltipSize, loaderWidth } = lineItem;
  const isMobile = useIsMobile();

  if (syncing) return <Loading width={loaderWidth} />;

  if (!TooltipBody)
    return <span className='break-words text-right'>{children}</span>;

  return (
    <MouseoverTooltip
      placement={isMobile ? 'auto' : 'right'}
      forceShow={labelHovered} // displays tooltip when hovering either both label or value
      size={tooltipSize}
      text={<TooltipBody />}
    >
      <span className='break-words text-right text-xs font-medium text-gray-100'>
        {children}
      </span>
    </MouseoverTooltip>
  );
};

const SwapLineItem = (props: SwapLineItemProps) => {
  const [labelHovered, hoverProps] = useHoverProps();

  const LineItem = useLineItem(props);
  if (!LineItem) {
    return null;
  }

  return (
    <animated.div style={{ opacity: props.animatedOpacity }}>
      <div className='flex flex-row items-center justify-between gap-2'>
        <span
          className={cn({
            'cursor-help text-gray-600': !!LineItem.TooltipBody
          })}
          {...hoverProps}
        >
          <LineItem.Label />
        </span>

        <ValueWrapper
          lineItem={LineItem}
          labelHovered={labelHovered}
          syncing={props.syncing}
        >
          <LineItem.Value />
        </ValueWrapper>
      </div>
    </animated.div>
  );
};

export default React.memo(SwapLineItem);
