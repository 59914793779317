import { createColumnHelper } from '@tanstack/react-table';
import { ChainId } from '@uniswap/sdk-core';
import { useMemo } from 'react';
import { ExternalLink as ExternalLinkIcon } from 'react-feather';
import { useParams } from 'react-router-dom';

import { getLocaleTimeString } from 'components/Table/utils';
import { shortenAddress } from 'utils/addresses';
import { useFormatter } from 'utils/formatNumbers';
import { ExplorerDataType, getExplorerLink } from 'utils/getExplorerLink';

import { useActiveLocalCurrency } from 'hooks/useActiveLocalCurrency';

import { Table } from 'components/Table';
import { Cell } from 'components/Table/Cell';
import {
  StyledExternalLink,
  StyledInternalLink
} from 'components/Table/styled';
import { useTheme } from 'styled-components';
import { ThemedText } from 'theme/components';

import {
  supportedChainIdFromGQLChain,
  validateUrlChainParam
} from 'graphql/data/util';
import {
  type Transaction,
  TransactionType,
  useRecentTransactions
} from 'graphql/thegraph/Transactions';

export default function RecentTransactions() {
  const theme = useTheme();
  const activeLocalCurrency = useActiveLocalCurrency();
  const { formatNumber, formatFiatPrice } = useFormatter();

  const chainName = validateUrlChainParam(
    useParams<{ chainName?: string }>().chainName
  );
  const chainId = supportedChainIdFromGQLChain(chainName);
  const { transactions, loading, loadMore } = useRecentTransactions(
    chainId ?? ChainId.MAINNET
  );

  const columns = useMemo(() => {
    const columnHelper = createColumnHelper<Transaction>();
    return [
      columnHelper.accessor((transaction) => transaction.timestamp, {
        id: 'timestamp',
        header: () => (
          <Cell minWidth={185} justifyContent='flex-start' grow>
            <ThemedText.BodySecondary>Time</ThemedText.BodySecondary>
          </Cell>
        ),
        cell: (timestamp) => (
          <Cell
            loading={loading}
            minWidth={185}
            justifyContent='flex-start'
            grow
          >
            <ThemedText.BodySecondary>
              {getLocaleTimeString(Number(timestamp.getValue?.()) * 1000)}
            </ThemedText.BodySecondary>
          </Cell>
        )
      }),
      columnHelper.accessor((transaction) => transaction, {
        id: 'swap-type',
        header: () => (
          <Cell minWidth={185} justifyContent='flex-start' grow>
            <ThemedText.BodySecondary>Type</ThemedText.BodySecondary>
          </Cell>
        ),
        cell: (transaction) => (
          <Cell
            loading={loading}
            minWidth={185}
            justifyContent='flex-start'
            grow
          >
            <ThemedText.BodyPrimary>
              {transaction.getValue?.().type}{' '}
              <StyledInternalLink
                to={`/explore/tokens/${chainName.toLowerCase()}/${transaction.getValue?.().token0Address}`}
              >
                {transaction.getValue?.().token0Symbol}
              </StyledInternalLink>{' '}
              {transaction.getValue?.().type === TransactionType.SWAP
                ? 'for'
                : 'and'}{' '}
              <StyledInternalLink
                to={`/explore/tokens/${chainName.toLowerCase()}/${transaction.getValue?.().token1Address}`}
              >
                {transaction.getValue?.().token1Symbol}
              </StyledInternalLink>{' '}
            </ThemedText.BodyPrimary>
          </Cell>
        )
      }),
      columnHelper.accessor((transaction) => transaction.amountUSD, {
        id: 'fiat-value',
        header: () => (
          <Cell minWidth={125}>
            <ThemedText.BodySecondary>
              {activeLocalCurrency}
            </ThemedText.BodySecondary>
          </Cell>
        ),
        cell: (fiat) => (
          <Cell loading={loading} minWidth={125}>
            <ThemedText.BodyPrimary>
              {formatFiatPrice({ price: fiat.getValue?.() })}
            </ThemedText.BodyPrimary>
          </Cell>
        )
      }),
      columnHelper.accessor((transaction) => transaction, {
        id: 'token-amount-0',
        header: () => (
          <Cell minWidth={200}>
            <ThemedText.BodySecondary>Token amount</ThemedText.BodySecondary>
          </Cell>
        ),
        cell: (transaction) => (
          <Cell loading={loading} minWidth={200}>
            <ThemedText.BodyPrimary>
              {formatNumber({
                input: Math.abs(transaction.getValue?.().amountToken0) || 0
              })}{' '}
              <StyledInternalLink
                to={`/explore/tokens/${chainName.toLowerCase()}/${transaction.getValue?.().token0Address}`}
              >
                {transaction.getValue?.().token0Symbol}
              </StyledInternalLink>
            </ThemedText.BodyPrimary>
          </Cell>
        )
      }),
      columnHelper.accessor((transaction) => transaction, {
        id: 'token-amount-1',
        header: () => (
          <Cell minWidth={200}>
            <ThemedText.BodySecondary>Token amount</ThemedText.BodySecondary>
          </Cell>
        ),
        cell: (transaction) => (
          <Cell loading={loading} minWidth={200}>
            <ThemedText.BodyPrimary>
              {formatNumber({
                input: Math.abs(transaction.getValue?.().amountToken1) || 0
              })}{' '}
              <StyledInternalLink
                to={`/explore/tokens/${chainName.toLowerCase()}/${transaction.getValue?.().token1Address}`}
              >
                {transaction.getValue?.().token1Symbol}
              </StyledInternalLink>
            </ThemedText.BodyPrimary>
          </Cell>
        )
      }),
      columnHelper.accessor((transaction) => transaction.sender, {
        id: 'maker-address',
        header: () => (
          <Cell minWidth={125}>
            <ThemedText.BodySecondary>Maker</ThemedText.BodySecondary>
          </Cell>
        ),
        cell: (makerAddress) => (
          <Cell loading={loading} minWidth={125}>
            <StyledExternalLink
              href={getExplorerLink({
                chainId,
                data: makerAddress.getValue?.(),
                type: ExplorerDataType.ADDRESS
              })}
            >
              {shortenAddress(makerAddress.getValue?.(), 0)}
            </StyledExternalLink>
          </Cell>
        )
      }),
      columnHelper.accessor(
        (transaction) =>
          getExplorerLink({
            chainId: chainId ?? ChainId.MAINNET,
            data: transaction.hash,
            type: ExplorerDataType.TRANSACTION
          }),
        {
          id: 'explorer-link',
          header: () => (
            <Cell minWidth={60}>
              <ThemedText.BodySecondary>Tx</ThemedText.BodySecondary>
            </Cell>
          ),
          cell: (explorerLink) => (
            <Cell loading={loading} minWidth={60}>
              <StyledExternalLink href={explorerLink.getValue?.()}>
                <ExternalLinkIcon size='16px' stroke={theme.neutral2} />
              </StyledExternalLink>
            </Cell>
          )
        }
      )
    ];
  }, [
    activeLocalCurrency,
    chainId,
    chainName,
    formatFiatPrice,
    formatNumber,
    loading,
    theme.neutral2
  ]);

  return (
    <Table
      columns={columns}
      data={transactions}
      loading={loading}
      loadMore={loadMore}
    />
  );
}
