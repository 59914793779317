import type { ComponentProps } from 'react';

export const SateliteIcon = (props: ComponentProps<'svg'>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='17'
    height='16'
    viewBox='0 0 17 16'
    fill='none'
    {...props}
  >
    <path
      d='M13.044 9.05332C14.572 10.4787 15.408 11.72 15.1053 12.32C14.6086 13.2933 11.25 12.1473 7.60196 9.76132C3.95529 7.37532 1.39929 4.65198 1.89529 3.67932C2.20463 3.07265 3.84596 3.38332 5.76396 4.21598M3.83329 7.99998C3.83329 8.61282 3.954 9.21965 4.18852 9.78584C4.42304 10.352 4.76679 10.8665 5.20013 11.2998C5.63347 11.7332 6.14792 12.0769 6.7141 12.3114C7.28029 12.5459 7.88712 12.6666 8.49996 12.6666C9.11279 12.6666 9.71963 12.5459 10.2858 12.3114C10.852 12.0769 11.3665 11.7332 11.7998 11.2998C12.2331 10.8665 12.5769 10.352 12.8114 9.78584C13.0459 9.21965 13.1666 8.61282 13.1666 7.99998C13.1666 7.38714 13.0459 6.78031 12.8114 6.21412C12.5769 5.64794 12.2331 5.13349 11.7998 4.70015C11.3665 4.26681 10.852 3.92306 10.2858 3.68854C9.71963 3.45402 9.11279 3.33331 8.49996 3.33331C7.88712 3.33331 7.28029 3.45402 6.7141 3.68854C6.14792 3.92306 5.63347 4.26681 5.20013 4.70015C4.76679 5.13349 4.42304 5.64794 4.18852 6.21412C3.954 6.78031 3.83329 7.38714 3.83329 7.99998Z'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);
