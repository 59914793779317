import { defaultAbiCoder } from '@ethersproject/abi';
import type { Web3Provider } from '@ethersproject/providers';
import { getBridgeContractAddress } from '@neby/chains';
import type { Currency, Token } from '@uniswap/sdk-core';
import { ChainId, MaxUint256, NativeCurrency } from '@uniswap/sdk-core';
import type { Percent } from '@uniswap/sdk-core';
import ERC20_ABI from 'abis/erc20.json';
import NEBY_CROSS_CHAIN_ABI from 'abis/neby-cross-chain-swap.json';
import { ethers } from 'ethers';

import { useAtomValue } from 'jotai/utils';
import { isChainIdTestnet } from 'utils/chains';

import { showTestnetsAtom } from 'components/AccountDrawer/TestnetsToggle';

export const BRIDGE_SUPPORTED_CHAINS = [
  ChainId.MAINNET,
  ChainId.OASIS_SAPPHIRE_MAINNET,
  ChainId.OASIS_SAPPHIRE_TESTNET,
  ChainId.BSC_TESTNET
];

export const isBridgeSupportedOnChain = (chainId: ChainId) =>
  BRIDGE_SUPPORTED_CHAINS.includes(chainId);

export const useBridgeSupportedChains = () => {
  const showTestnets = useAtomValue(showTestnetsAtom);

  return BRIDGE_SUPPORTED_CHAINS.filter(
    (chainId) => showTestnets || !isChainIdTestnet(chainId)
  );
};

export const MAX_ALLOWANCE = MaxUint256.toString();

class Bridge {
  public swapLoading: boolean = false;

  public readonly sourceToken: Currency;
  public readonly destinationToken: Currency;
  public readonly amountIn: string;
  public readonly amountOut: string;
  public readonly slippage: Percent;
  public readonly address: string;
  public readonly bridgeAddress: string;

  private readonly _provider: Web3Provider;
  private readonly _erc20Contract: ethers.Contract;
  private readonly _bridgeContract: ethers.Contract;

  constructor(
    sourceToken: Token,
    destinationToken: Token,
    amountIn: string,
    amountOut: string,
    address: string,
    slippage: Percent,
    provider: Web3Provider
  ) {
    this.sourceToken = sourceToken;
    this.destinationToken = destinationToken;
    this.amountIn = amountIn;
    this.amountOut = amountOut;
    this.address = address;
    this.slippage = slippage;
    this._provider = provider;
    this._erc20Contract = new ethers.Contract(
      this.sourceToken.address,
      ERC20_ABI,
      this._provider.getSigner()
    );

    const bridgeAddress = getBridgeContractAddress(
      this.sourceToken.chainId,
      this.destinationToken.chainId
    );

    if (!bridgeAddress) {
      throw new Error('Bridge contract address not found');
    }

    this.bridgeAddress = bridgeAddress;
    this._bridgeContract = new ethers.Contract(
      bridgeAddress,
      NEBY_CROSS_CHAIN_ABI,
      this._provider.getSigner()
    );
  }

  async swap() {
    // Native currency doesn't have address, I assume we will need wrap/unwrap logic
    if (
      this.sourceToken instanceof NativeCurrency ||
      this.destinationToken instanceof NativeCurrency
    ) {
      return;
    }

    this.swapLoading = true;

    const nonce = await this.getNonce();
    const message = defaultAbiCoder.encode(
      [
        '((address, address , uint24, uint256, uint256), address, uint64, bool)'
      ],
      [
        [
          [
            this.destinationToken.address,
            this.destinationToken.address,
            0,
            0,
            0
          ],
          this.address,
          nonce,
          this.destinationToken.isNative
        ]
      ]
    );

    /*
    Logic calculates max slippage based on the slippage percentage.
    But we always get an error "value out-of-bounds" when trying to send the transaction.
    const _maxBridgeSlippage =
      (BigInt(this.amount) *
        BigInt(Math.round(Number(this.slippage.toSignificant(4)) * 100))) /
      BigInt(10000);

     */

    const msgBusInfo = {
      maxBridgeSlippage: 50000,
      nonce,
      // This needs to be updated in the future!
      bridgeSendType:
        this.sourceToken.chainId === ChainId.OASIS_SAPPHIRE_TESTNET ? 4 : 5
    };

    const platformFee = await this._bridgeContract?.calculateFee(message);

    const tx = await this._bridgeContract.transferWithSwap(
      // Source payload
      {
        tokenIn: this.sourceToken.address,
        tokenOut: this.sourceToken.address,
        fee: BigInt('0'),
        amountIn: this.amountIn,
        minimumOut: BigInt('0')
      },
      // Destination payload
      {
        tokenIn: this.destinationToken.address,
        tokenOut: this.destinationToken.address,
        fee: BigInt('0'),
        amountIn: BigInt('0'),
        minimumOut: BigInt('0')
      },
      this.destinationToken.isNative,
      msgBusInfo,
      {
        value: platformFee
      }
    );

    const bridgeHistory = JSON.parse(
      localStorage.getItem('bridgeHistory') || '[]'
    );
    bridgeHistory.push({
      txHash: tx.hash,
      status: 0,
      amountIn: this.amountIn,
      sourceToken: {
        address: this.sourceToken.address,
        chainId: this.sourceToken.chainId,
        symbol: this.sourceToken.symbol,
        decimals: this.sourceToken.decimals
      },
      destinationToken: {
        address: this.destinationToken.address,
        chainId: this.destinationToken.chainId,
        symbol: this.destinationToken.symbol,
        decimals: this.destinationToken.decimals
      }
    });
    localStorage.setItem('bridgeHistory', JSON.stringify(bridgeHistory));

    this.swapLoading = false;
    return tx;
  }

  async getNonce() {
    return await this._provider.getTransactionCount(this.address);
  }
}

export default Bridge;
