import type { HeaderProps } from './Header.types';
import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import { cn } from 'utils/cn';

import Logo from 'components/ui/Logo';

import { useAccountDrawer } from 'components/AccountDrawer';
import ChainSelector from 'components/composed/ChainSelector';
import Web3Status from 'components/composed/Web3Status';
import { NavBar } from 'components/composed/navigation';

const Header = ({ transparent, bannerIsVisible }: HeaderProps) => {
  const navigate = useNavigate();

  const [accountDrawerOpen, toggleAccountDrawer] = useAccountDrawer();

  const handleLogoClick = useCallback(() => {
    if (accountDrawerOpen) {
      toggleAccountDrawer();
    }
    navigate({
      pathname: '/',
      search: '?intro=true'
    });
  }, [accountDrawerOpen, navigate, toggleAccountDrawer]);

  return (
    <div
      className={cn(
        'fixed z-100 flex w-full flex-row flex-nowrap items-center justify-between p-5 transition-colors duration-300',
        {
          'bg-transparent': transparent,
          'bg-gradient-to-b from-blue-900 to-transparent backdrop-blur':
            !transparent,
          'top-0': !bannerIsVisible,
          'top-34.25 sm:top-28.25 md:top-16.25': bannerIsVisible
        }
      )}
    >
      <Logo className='z-101 cursor-pointer' onClick={handleLogoClick} />

      <NavBar />

      <div className='z-101 flex flex-row gap-4'>
        <ChainSelector />
        <Web3Status />
      </div>
    </div>
  );
};

export default Header;
