import { updateChainId } from './reducer';
import { asSupportedChain } from 'constants/chains';
import { useEffect, useState } from 'react';

import useDebounce from 'hooks/useDebounce';
import useIsWindowVisible from 'hooks/useIsWindowVisible';
import { useWeb3React } from 'hooks/useWeb3React';
import { useAppDispatch } from 'state/hooks';

export default function Updater(): null {
  const { chainId, provider } = useWeb3React();
  const dispatch = useAppDispatch();
  const windowVisible = useIsWindowVisible();

  const [activeChainId, setActiveChainId] = useState(chainId);

  useEffect(() => {
    if (provider && chainId && windowVisible) {
      setActiveChainId(chainId);
    }
  }, [dispatch, chainId, provider, windowVisible]);

  const debouncedChainId = useDebounce(activeChainId, 100);

  useEffect(() => {
    const chainId = debouncedChainId
      ? asSupportedChain(debouncedChainId)
      : null;
    dispatch(updateChainId({ chainId }));
  }, [dispatch, debouncedChainId]);

  return null;
}
