import type { AnchorHTMLAttributes, FC } from 'react';
import { useLocation } from 'react-router-dom';

import { cn } from 'utils/cn';
import { routes } from 'utils/routes';

import { EdgeShape } from 'components/ui/shapes';

const navigationItems = [
  {
    label: 'Swap',
    component: () => (
      <MobileStartButton href={routes.swap}>Swap</MobileStartButton>
    )
  },
  {
    label: 'Pools',
    component: () => <NavigationLink href={routes.pools}>Pools</NavigationLink>
  },
  {
    label: 'Launchpad',
    component: () => (
      <NavigationLink href={routes.launchpad}>Launchpad</NavigationLink>
    )
  },
  {
    label: 'Bridge',
    component: () => (
      <NavigationLink href={routes.bridge}>Bridge</NavigationLink>
    )
  },
  {
    label: '...',
    component: () => <MobileEndButton href='#'>...</MobileEndButton>
  }
];

const MobileBottomNavigation = () => (
  <div className='fixed inset-x-0 bottom-0 z-sticky md:hidden'>
    <div className='relative flex flex-col'>
      <div className='flex'>
        <div
          className='h-6 w-6 flex-shrink-0 bg-blue-900'
          style={{
            clipPath: 'polygon(100% 0, 0% 100%, 100% 100%)'
          }}
        />
        <div className='w-full bg-blue-900' />
        <div
          className='h-6 w-6 flex-shrink-0 bg-blue-900'
          style={{
            clipPath: 'polygon(0 0, 100% 100%, 0 100%)'
          }}
        />
      </div>
      <div className='h-10 w-full bg-blue-900' />
      <div className='absolute inset-x-6 top-2 z-1 flex flex-grow items-center justify-between px-1.5'>
        {navigationItems.map(({ label, component: Component }) => (
          <Component key={label} />
        ))}
      </div>
    </div>
  </div>
);

export default MobileBottomNavigation;

type LinkButtonProps = AnchorHTMLAttributes<HTMLAnchorElement>;
const MobileStartButton: FC<LinkButtonProps> = ({
  children,
  href,
  ...props
}) => {
  const { pathname } = useLocation();

  return (
    <NavigationLink
      className='relative rounded-l-none pl-1.5'
      href={href}
      {...props}
    >
      {children}
      <EdgeShape
        className={cn('absolute inset-y-0 left-0 h-9 -translate-x-9/10', {
          'fill-blue-200': pathname.startsWith(href || '')
        })}
      />
    </NavigationLink>
  );
};

const MobileEndButton: FC<LinkButtonProps> = ({ children, href, ...props }) => {
  const { pathname } = useLocation();

  return (
    <NavigationLink
      className='relative rounded-r-none pr-1.5'
      href={href}
      {...props}
    >
      {children}
      <EdgeShape
        className={cn(
          'absolute inset-y-0 right-0 h-9 translate-x-9/10 rotate-180',
          {
            'fill-blue-200': pathname.startsWith(href || '')
          }
        )}
      />
    </NavigationLink>
  );
};

const NavigationLink: FC<LinkButtonProps> = ({ href, className, ...props }) => {
  const { pathname } = useLocation();

  return (
    <a
      href={href}
      className={cn(
        'rounded-md p-3 text-xs uppercase text-gray-800 transition-colors hover:text-blue-200',
        {
          'bg-blue-200 hover:text-gray-800': pathname.startsWith(href || '')
        },
        className
      )}
      {...props}
    />
  );
};
