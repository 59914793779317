import { ReactComponent as UniswapAppLogo } from '../../assets/svg/uniswap_app_logo.svg';
import * as styles from './MenuDropdown.css';
import { NavDropdown } from './NavDropdown';
import { NavIcon } from './NavIcon';
import { InterfaceElementName } from '@uniswap/analytics-events';
import { type ReactNode, useReducer, useRef } from 'react';
import { NavLink, type NavLinkProps } from 'react-router-dom';

import { cn } from 'utils/cn';
import { isDevelopmentEnv, isStagingEnv } from 'utils/env';
import { openDownloadApp } from 'utils/openDownloadApp';

import { useOnClickOutside } from 'hooks/useOnClickOutside';
import { useToggleModal } from 'state/application/hooks';

import FeatureFlagModal from 'components/FeatureFlagModal/FeatureFlagModal';
import { PrivacyPolicyModal } from 'components/PrivacyPolicy';
import { Box } from 'nft/components/Box';
import { Column, Row } from 'nft/components/Flex';
import {
  BarChartIcon,
  DiscordIconMenu,
  EllipsisIcon,
  GithubIconMenu,
  GovernanceIcon,
  PoolIcon,
  TwitterIconMenu
} from 'nft/components/icons';
import { useTheme } from 'styled-components';
import { ThemedText } from 'theme/components';

import { ApplicationModal } from '../../state/application/reducer';

const PrimaryMenuRow = ({
  to,
  href,
  close,
  children
}: {
  to?: NavLinkProps['to'];
  href?: string;
  close?: () => void;
  children: ReactNode;
}) => (
  <>
    {to ? (
      <NavLink to={to} className={styles.MenuRow}>
        <Row onClick={close}>{children}</Row>
      </NavLink>
    ) : (
      <Row
        cursor='pointer'
        as='a'
        href={href}
        target='_blank'
        rel='noopener noreferrer'
        className={styles.MenuRow}
      >
        {children}
      </Row>
    )}
  </>
);

const Text = ({ children }: { children: ReactNode }) => (
  <span className='flex items-center justify-center text-base text-white'>
    {children}
  </span>
);

const SecondaryLinkedText = ({
  href,
  onClick,
  children
}: {
  href?: string;
  onClick?: () => void;
  children: ReactNode;
}) => (
  <Box
    as={href ? 'a' : 'div'}
    href={href ?? undefined}
    target={href ? '_blank' : undefined}
    rel={href ? 'noopener noreferrer' : undefined}
    className='w-full rounded-xl p-2 text-sm text-white transition-colors duration-300 hover:bg-blue-700'
    onClick={onClick}
    cursor='pointer'
  >
    {children}
  </Box>
);

const Separator = () => (
  <Box className={cn(styles.Separator, 'border-white')} />
);

const IconRow = ({ children }: { children: ReactNode }) => (
  <Row className={styles.IconRow}>{children}</Row>
);

const Icon = ({ href, children }: { href?: string; children: ReactNode }) => (
  <>
    <Box
      as={href ? 'a' : 'div'}
      href={href ?? undefined}
      target={href ? '_blank' : undefined}
      rel={href ? 'noopener noreferrer' : undefined}
      display='flex'
      flexDirection='column'
      color='neutral1'
      background='none'
      border='none'
      justifyContent='center'
      textAlign='center'
      marginRight='12'
    >
      {children}
    </Box>
  </>
);

export const MenuDropdown = () => {
  const theme = useTheme();
  const [isOpen, toggleOpen] = useReducer((s) => !s, false);
  const togglePrivacyPolicy = useToggleModal(ApplicationModal.PRIVACY_POLICY);
  const openFeatureFlagsModal = useToggleModal(ApplicationModal.FEATURE_FLAGS);
  const ref = useRef<HTMLDivElement>(null);
  useOnClickOutside(ref, isOpen ? toggleOpen : undefined);

  return (
    <>
      <Box position='relative' ref={ref} marginRight='4'>
        <NavIcon
          isActive={isOpen}
          onClick={toggleOpen}
          label={isOpen ? 'Show resources' : 'Hide resources'}
        >
          <EllipsisIcon viewBox='0 0 20 20' width={24} height={24} />
        </NavIcon>

        {isOpen && (
          <NavDropdown
            top={{ sm: 'unset', lg: '56' }}
            bottom={{ sm: '50', lg: 'unset' }}
            right='0'
          >
            <Column gap='8'>
              <Column paddingX='8' gap='4'>
                <Box display={{ sm: 'none', lg: 'flex', xxl: 'none' }}>
                  <PrimaryMenuRow to='/pool' close={toggleOpen}>
                    <Icon>
                      <PoolIcon width={24} height={24} fill={theme.neutral1} />
                    </Icon>
                    <Text>Pool</Text>
                  </PrimaryMenuRow>
                </Box>
                <PrimaryMenuRow to='/vote' close={toggleOpen}>
                  <Icon>
                    <GovernanceIcon
                      width={24}
                      height={24}
                      color={theme.neutral1}
                    />
                  </Icon>
                  <Text>Vote in governance</Text>
                </PrimaryMenuRow>
                <PrimaryMenuRow href='https://info.uniswap.org/#/'>
                  <Icon>
                    <BarChartIcon
                      width={24}
                      height={24}
                      color={theme.neutral1}
                    />
                  </Icon>
                  <Text>View more analytics</Text>
                </PrimaryMenuRow>
                <Box
                  onClick={() =>
                    openDownloadApp({
                      element:
                        InterfaceElementName.UNISWAP_WALLET_NAVBAR_MENU_DOWNLOAD_BUTTON
                    })
                  }
                >
                  <PrimaryMenuRow close={toggleOpen}>
                    <>
                      <Icon>
                        <UniswapAppLogo width='24px' height='24px' />
                      </Icon>
                      <div>
                        <ThemedText.BodyPrimary>
                          Download Uniswap
                        </ThemedText.BodyPrimary>
                        <ThemedText.LabelSmall>
                          Available on iOS and Android
                        </ThemedText.LabelSmall>
                      </div>
                    </>
                  </PrimaryMenuRow>
                </Box>
              </Column>
              <Separator />
              <Box
                display='flex'
                flexDirection={{ sm: 'row', md: 'column' }}
                flexWrap='wrap'
                alignItems={{ sm: 'center', md: 'flex-start' }}
                paddingX='8'
              >
                <SecondaryLinkedText href='https://help.uniswap.org/en/'>
                  Help center ↗
                </SecondaryLinkedText>
                <SecondaryLinkedText href='https://docs.uniswap.org/'>
                  Documentation ↗
                </SecondaryLinkedText>
                <SecondaryLinkedText href='https://uniswap.canny.io/feature-requests'>
                  Feedback ↗
                </SecondaryLinkedText>
                <SecondaryLinkedText
                  onClick={() => {
                    toggleOpen();
                    togglePrivacyPolicy();
                  }}
                >
                  Legal & Privacy ↗
                </SecondaryLinkedText>
                {(isDevelopmentEnv() || isStagingEnv()) && (
                  <SecondaryLinkedText
                    onClick={() => {
                      toggleOpen();
                      openFeatureFlagsModal();
                    }}
                  >
                    Feature Flags
                  </SecondaryLinkedText>
                )}
              </Box>
              <IconRow>
                <Icon href='https://discord.com/invite/FCfyBSbCU5'>
                  <DiscordIconMenu
                    className={cn(styles.hover, 'size-6 text-white')}
                  />
                </Icon>
                <Icon href='https://twitter.com/Uniswap'>
                  <TwitterIconMenu
                    className={cn(styles.hover, 'size-6 text-white')}
                  />
                </Icon>
                <Icon href='https://github.com/Uniswap'>
                  <GithubIconMenu
                    className={cn(styles.hover, 'size-6 text-white')}
                  />
                </Icon>
              </IconRow>
            </Column>
          </NavDropdown>
        )}
      </Box>
      <PrivacyPolicyModal />
      <FeatureFlagModal />
    </>
  );
};
