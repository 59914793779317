import { ReactComponent as arbitrum } from './ChainSymbols/arbitrum.svg';
import { ReactComponent as avax } from './ChainSymbols/avax.svg';
import { ReactComponent as base } from './ChainSymbols/base.svg';
import { ReactComponent as bnb } from './ChainSymbols/bnb.svg';
import { ReactComponent as celo } from './ChainSymbols/celo.svg';
import { ReactComponent as ethereum } from './ChainSymbols/ethereum.svg';
import { ReactComponent as oasis } from './ChainSymbols/oasis.svg';
import { ReactComponent as optimism } from './ChainSymbols/optimism.svg';
import { ReactComponent as polygon } from './ChainSymbols/polygon.svg';
import { ChainId } from '@uniswap/sdk-core';
import { getChainInfo } from 'constants/chainInfo';
import {
  type SupportedInterfaceChain,
  isSupportedChain
} from 'constants/chains';
import type { CSSProperties, FunctionComponent } from 'react';

type SVG = FunctionComponent<React.SVGProps<SVGSVGElement>>;
type ChainUI = { Symbol: SVG; bgColor: string; textColor: string };

export function getChainUI(chainId: SupportedInterfaceChain): ChainUI;
export function getChainUI(chainId: ChainId): ChainUI | undefined {
  switch (chainId) {
    case ChainId.MAINNET:
    case ChainId.GOERLI:
    case ChainId.SEPOLIA:
      return {
        Symbol: ethereum,
        bgColor: '#6B8AFF33',
        textColor: '#6B8AFF'
      };
    case ChainId.OASIS_EMERALD_MAINNET:
    case ChainId.OASIS_EMERALD_TESTNET:
    case ChainId.OASIS_SAPPHIRE_MAINNET:
    case ChainId.OASIS_SAPPHIRE_TESTNET:
      return {
        Symbol: oasis,
        bgColor: '#FFFFFF33',
        textColor: '#6B8AFF'
      };
    case ChainId.BASE_SEPOLIA_TESTNET:
      return {
        Symbol: base,
        bgColor: '#0052FF33',
        textColor: '#0052FF'
      };
    case ChainId.POLYGON:
    case ChainId.POLYGON_MUMBAI:
      return {
        Symbol: polygon,
        bgColor: '#9558FF33',
        textColor: '#9558FF'
      };
    case ChainId.ARBITRUM_ONE:
    case ChainId.ARBITRUM_GOERLI:
      return {
        Symbol: arbitrum,
        bgColor: '#00A3FF33',
        textColor: '#00A3FF'
      };
    case ChainId.OPTIMISM:
    case ChainId.OPTIMISM_GOERLI:
      return {
        Symbol: optimism,
        bgColor: '#FF042033',
        textColor: '#FF0420'
      };
    case ChainId.CELO:
    case ChainId.CELO_ALFAJORES:
      return {
        Symbol: celo,
        bgColor: '#FCFF5233',
        textColor: '#FCFF52'
      };
    case ChainId.AVALANCHE:
      return {
        Symbol: avax,
        bgColor: '#E8414233',
        textColor: '#E84142'
      };
    case ChainId.BNB:
    case ChainId.BSC_TESTNET:
      return {
        Symbol: bnb,
        bgColor: '#EAB20033',
        textColor: '#EAB200'
      };
    case ChainId.BASE:
      return {
        Symbol: base,
        bgColor: '#0052FF33',
        textColor: '#0052FF'
      };
    default:
      return undefined;
  }
}

export const getDefaultBorderRadius = (size: number) => size / 2 - 4;

type ChainLogoProps = {
  chainId: ChainId;
  className?: string;
  size?: number;
  borderRadius?: number;
  style?: CSSProperties;
  testId?: string;
  ignoreUnsupported?: boolean;
};
export function ChainLogo({
  chainId,
  className,
  style,
  size = 16,
  testId,
  ignoreUnsupported = false
}: ChainLogoProps) {
  if (!ignoreUnsupported && !isSupportedChain(chainId)) return null;
  const { label } = getChainInfo(chainId);

  const { Symbol } = getChainUI(chainId as SupportedInterfaceChain);
  return (
    <svg
      width={size}
      height={size}
      className={className}
      style={style}
      aria-labelledby='titleID'
      data-testid={testId}
    >
      <title id='titleID'>{`${label} logo`}</title>
      <Symbol width={size} height={size} />
    </svg>
  );
}
