import Toggle from './Toggle';

import Column from 'components/Column';
import Row from 'components/Row';
import { ThemedText } from 'theme/components';

interface SettingsToggleProps {
  title: string;
  description?: string;
  dataID?: string;
  isActive: boolean;
  toggle: () => void;
}

const SettingsToggle = ({
  title,
  description,
  dataID,
  isActive,
  toggle
}: SettingsToggleProps) => (
  <Row align='center'>
    <Column className='mr-2.5 w-full'>
      <Row>
        <ThemedText.SubHeaderSmall color='neutral1'>
          {title}
        </ThemedText.SubHeaderSmall>
      </Row>

      {description && (
        <Row>
          <ThemedText.BodySmall color='neutral2' lineHeight='16px'>
            {description}
          </ThemedText.BodySmall>
        </Row>
      )}
    </Column>

    <Toggle id={dataID} isActive={isActive} toggle={toggle} />
  </Row>
);

export default SettingsToggle;
