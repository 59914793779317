import { SlideOutMenu } from './SlideOutMenu';
import { MenuColumn, MenuItem } from './shared';
import {
  SUPPORTED_LOCAL_CURRENCIES,
  type SupportedLocalCurrency,
  getLocalCurrencyIcon
} from 'constants/localCurrencies';
import { useMemo } from 'react';

import { useActiveLocalCurrency } from 'hooks/useActiveLocalCurrency';
import { useLocalCurrencyLinkProps } from 'hooks/useLocalCurrencyLinkProps';

import styled from 'styled-components';

const StyledLocalCurrencyIcon = styled.div`
  width: 20px;
  height: 20px;
  border-radius: 100%;
  overflow: hidden;
`;

function LocalCurrencyMenuItem({
  localCurrency,
  isActive
}: {
  localCurrency: SupportedLocalCurrency;
  isActive: boolean;
}) {
  const { to, onClick } = useLocalCurrencyLinkProps(localCurrency);

  const LocalCurrencyIcon = useMemo(
    () => (
      <StyledLocalCurrencyIcon>
        {getLocalCurrencyIcon(localCurrency)}
      </StyledLocalCurrencyIcon>
    ),
    [localCurrency]
  );

  if (!to) return null;

  return (
    <MenuItem
      label={localCurrency}
      logo={LocalCurrencyIcon}
      isActive={isActive}
      to={to}
      onClick={onClick}
      testId='wallet-local-currency-item'
    />
  );
}

export default function LocalCurrencyMenu({
  onClose
}: {
  onClose: () => void;
}) {
  const activeLocalCurrency = useActiveLocalCurrency();

  return (
    <SlideOutMenu title='Currency' onClose={onClose}>
      <MenuColumn>
        {SUPPORTED_LOCAL_CURRENCIES.map((localCurrency) => (
          <LocalCurrencyMenuItem
            localCurrency={localCurrency}
            isActive={activeLocalCurrency === localCurrency}
            key={localCurrency}
          />
        ))}
      </MenuColumn>
    </SlideOutMenu>
  );
}
