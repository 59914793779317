import type { VariantProps } from 'class-variance-authority';
import { cva } from 'class-variance-authority';
import type { ButtonHTMLAttributes, FC, HTMLAttributes } from 'react';
import { forwardRef } from 'react';

import { cn } from 'utils/cn';

import { EdgeShape } from 'components/ui/shapes';

type TabProps = ButtonHTMLAttributes<HTMLButtonElement> &
  TabVariants & {
    active?: boolean;
    asStart?: boolean;
    asEnd?: boolean;
  };

const tabVariants = cva(
  'relative inline-flex items-center font-medium uppercase focus-visible:outline-none disabled:cursor-not-allowed',
  {
    variants: {
      variant: {
        primary:
          'text-gray-600 data-[state=active]:text-blue-200 data-[state=inactive]:hover:text-blue-200 group-hover:text-blue-200 data-[state=active]:bg-blue-800'
      },
      size: {
        sm: 'rounded-md px-2 py-3.25 leading-4 text-xs data-[state=active]:leading-4',
        md: 'rounded-md px-4 py-2 text-sm'
      }
    },
    defaultVariants: {
      variant: 'primary',
      size: 'md'
    }
  }
);

const Tab = forwardRef<HTMLButtonElement, TabProps>(
  (
    { className, active, variant, size, children, asStart, asEnd, ...props },
    ref
  ) => (
    <button
      data-state={active ? 'active' : 'inactive'}
      ref={ref}
      className={cn(className, tabVariants({ variant, size }), 'group', {
        'rounded-l-none pl-0': asStart,
        'rounded-r-none pr-0': asEnd
      })}
      {...props}
    >
      {asStart && (
        <EdgeShape
          className={cn('absolute -left-5.5 z-1', {
            'h-10.5 fill-blue-800': active
          })}
        />
      )}

      {children}

      {asEnd && (
        <EdgeShape
          className={cn('absolute -right-5.5 z-1 -ml-px rotate-180', {
            'h-10.5 fill-blue-800': active
          })}
        />
      )}
    </button>
  )
);

Tab.displayName = 'Tab';

const tabInnerContainerVariants = cva('flex items-center justify-center', {
  variants: {
    variant: {
      primary: 'bg-blue-900'
    },
    size: {
      sm: 'h-11.5',
      md: 'w-full h-11.5'
    }
  },
  defaultVariants: {
    variant: 'primary',
    size: 'md'
  }
});

const tabEdgeShapeVariant = cva('flex-shrink-0', {
  variants: {
    variant: {
      primary: 'fill-blue-900'
    },
    size: {
      sm: 'w-auto h-11.5',
      md: 'w-auto h-11.5'
    }
  },
  defaultVariants: {
    variant: 'primary'
  }
});

export type TabVariants = VariantProps<typeof tabEdgeShapeVariant>;

export type TabContainerProps = HTMLAttributes<HTMLDivElement> & TabVariants;

const TabContainer: FC<TabContainerProps> = ({
  children,
  variant,
  size,
  className,
  ...props
}) => (
  <div
    className={cn('relative flex items-center justify-center', className)}
    {...props}
  >
    <EdgeShape
      className={cn(tabEdgeShapeVariant({ size, variant }), '-mr-px')}
    />

    <div className={cn('flex-1', tabInnerContainerVariants({ size, variant }))}>
      {children}
    </div>

    <EdgeShape
      className={cn(
        tabEdgeShapeVariant({ size, variant }),
        '-ml-px rotate-180'
      )}
    />
  </div>
);

export { Tab, TabContainer };
