import type { AssetLogoBaseProps } from './AssetLogo';
import { ChainId } from '@uniswap/sdk-core';
import { useMemo } from 'react';

import { PortfolioLogo } from 'components/AccountDrawer/MiniPortfolio/PortfolioLogo';

import type { SearchToken } from 'graphql/data/SearchTokens';
import type { TokenQueryData } from 'graphql/data/Token';
import type { TopToken } from 'graphql/data/TopTokens';
import { gqlToCurrency, supportedChainIdFromGQLChain } from 'graphql/data/util';

export default function QueryTokenLogo(
  props: AssetLogoBaseProps & {
    token?: TopToken | TokenQueryData | SearchToken;
  }
) {
  const chainId =
    (props.token?.chain
      ? supportedChainIdFromGQLChain(props.token?.chain)
      : ChainId.MAINNET) ?? ChainId.MAINNET;
  const currency = props.token ? gqlToCurrency(props.token) : undefined;

  return (
    <PortfolioLogo
      currencies={useMemo(() => [currency], [currency])}
      chainId={chainId}
      {...props}
    />
  );
}
