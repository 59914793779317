import { allowAnalyticsAtom } from 'analytics';
import { useAtom } from 'jotai';

import SettingsToggle from 'components/composed/SettingsToggle';

export function AnalyticsToggle() {
  const [allowAnalytics, updateAllowAnalytics] = useAtom(allowAnalyticsAtom);

  return (
    <SettingsToggle
      title='Allow analytics'
      description='We use anonymized data to enhance your experience with Uniswap Labs products.'
      isActive={allowAnalytics}
      toggle={() => void updateAllowAnalytics((value) => !value)}
    />
  );
}
