import type { FC, ReactNode } from 'react';

import type { TabVariants } from 'components/ui/tabs/Tabs.components';
import { Tab, TabContainer } from 'components/ui/tabs/Tabs.components';

type Tab = {
  id: string;
  children: ReactNode;
  className?: string;
};

type TabsProps = TabVariants & {
  tabs: Tab[];
  activeTabId?: string;
  onTabChange?: (id: string) => void;
  className?: string;
};

/*
  Example usage:
   const [activeTabId, setActiveTabId] = useState('regular');

   <Tabs
   activeTabId={activeTabId}
   onTabChange={setActiveTabId}
   tabs={[
     {
       id: 'regular',
       children: 'Regular'
     },
     {
       id: 'private',
       children: 'Private'
     }
   ]}
   />
*/

const Tabs: FC<TabsProps> = ({
  tabs,
  activeTabId,
  onTabChange,
  size,
  variant,
  className
}) => (
  <TabContainer variant={variant} size={size} className={className}>
    {tabs.map((tab: Tab, index) => (
      <Tab
        key={tab.id}
        id={tab.id}
        asStart={index === 0}
        asEnd={index === tabs.length - 1}
        variant={variant}
        size={size}
        active={activeTabId === tab.id}
        className={tab.className}
        onClick={() => {
          onTabChange?.(tab.id);
        }}
      >
        {tab.children}
      </Tab>
    ))}
  </TabContainer>
);

export default Tabs;
