import {
  type RouteDefinition,
  findRouteByPath,
  routes,
  useRouterConfig
} from './RouteDefinitions';
import { CustomUserProperties, getBrowser } from '@uniswap/analytics-events';
import { getDeviceId, user } from 'analytics';
import { useFeatureFlagsIsLoaded } from 'featureFlags';
import {
  type ReactNode,
  Suspense,
  lazy,
  useEffect,
  useLayoutEffect,
  useMemo,
  useState
} from 'react';
import { Helmet } from 'react-helmet';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import { StatsigProvider, type StatsigUser } from 'statsig-react';
import { STATSIG_DUMMY_KEY } from 'tracing';

import { isPathBlocked } from 'utils/blockedPaths';
import { cn } from 'utils/cn';
import { getEnvName } from 'utils/env';
import { MICROSITE_LINK } from 'utils/openDownloadApp';

import { useWeb3React } from 'hooks/useWeb3React';
import { useBag } from 'nft/hooks';
import { useAppSelector } from 'state/hooks';
import { useRouterPreference } from 'state/user/hooks';

import Background from 'components/ui/Background';

import ErrorBoundary from 'components/ErrorBoundary';
import Loader from 'components/Icons/LoadingSpinner';
import { UkBanner } from 'components/NavBar/UkBanner';
import { Header } from 'components/composed/Header';
import { MobileBottomNavigation } from 'components/composed/navigation';

import type { AppState } from 'state/reducer';

const AppChrome = lazy(() => import('./AppChrome'));

const BodyWrapper = ({ children }: { children: ReactNode }) => (
  <div
    className={cn(
      'relative z-10 flex w-full flex-1 flex-col items-center',
      'min-h-screen px-0 py-20'
    )}
  >
    {children}
  </div>
);

export default function App() {
  const isLoaded = useFeatureFlagsIsLoaded();

  const [scrollY, setScrollY] = useState(0);
  const scrolledState = scrollY > 0;

  const location = useLocation();
  const { pathname } = location;

  const routerConfig = useRouterConfig();

  const originCountry = useAppSelector(
    (state: AppState) => state.user.originCountry
  );
  const renderUkBannner = Boolean(originCountry) && originCountry === 'GB';

  useEffect(() => {
    window.scrollTo(0, 0);
    setScrollY(0);
  }, [pathname]);

  useEffect(() => {
    const scrollListener = () => {
      setScrollY(window.scrollY);
    };

    window.addEventListener('scroll', scrollListener);
    return () => window.removeEventListener('scroll', scrollListener);
  }, []);

  const isBagExpanded = useBag((state) => state.bagExpanded);
  const isHeaderTransparent = !scrolledState && !isBagExpanded;

  const { account } = useWeb3React();
  const statsigUser: StatsigUser = useMemo(
    () => ({
      userID: getDeviceId(),
      customIDs: { address: account ?? '' }
    }),
    [account]
  );

  // redirect address to landing pages until implemented
  const shouldRedirectToAppInstall = pathname?.startsWith('/address/');
  useLayoutEffect(() => {
    if (shouldRedirectToAppInstall) {
      window.location.href = MICROSITE_LINK;
    }
  }, [shouldRedirectToAppInstall]);

  if (shouldRedirectToAppInstall) {
    return null;
  }

  const shouldBlockPath = isPathBlocked(pathname);
  if (shouldBlockPath && pathname !== '/swap') {
    return <Navigate to='/swap' replace />;
  }
  return (
    <ErrorBoundary>
      {/*
          This is where *static* page titles are injected into the <head> tag. If you
          want to set a page title based on data that's dynamic or not available on first render,
          you can set it later in the page component itself, since react-helmet prefers the most recently rendered title.
        */}
      <Helmet>
        <title>
          {findRouteByPath(pathname)?.getTitle(pathname) ?? 'Uniswap Interface'}
        </title>
      </Helmet>
      <StatsigProvider
        user={statsigUser}
        // TODO: replace with proxy and cycle key
        sdkKey={STATSIG_DUMMY_KEY}
        waitForInitialization={false}
        options={{
          environment: { tier: getEnvName() },
          api: process.env.REACT_APP_STATSIG_PROXY_URL
        }}
      >
        <UserPropertyUpdater />

        {renderUkBannner && <UkBanner />}

        <Header
          transparent={isHeaderTransparent}
          bannerIsVisible={renderUkBannner}
        />

        <Background />

        <BodyWrapper>
          <Suspense>
            <AppChrome />
          </Suspense>
          <Suspense fallback={<Loader />}>
            {isLoaded ? (
              <Routes>
                {routes.map((route: RouteDefinition) =>
                  route.enabled(routerConfig) ? (
                    <Route
                      key={route.path}
                      path={route.path}
                      element={route.getElement(routerConfig)}
                    >
                      {route.nestedPaths.map((nestedPath) => (
                        <Route
                          path={nestedPath}
                          key={`${route.path}/${nestedPath}`}
                        />
                      ))}
                    </Route>
                  ) : null
                )}
              </Routes>
            ) : (
              <Loader />
            )}
          </Suspense>
        </BodyWrapper>

        <MobileBottomNavigation />
      </StatsigProvider>
    </ErrorBoundary>
  );
}

function UserPropertyUpdater() {
  const [routerPreference] = useRouterPreference();
  const rehydrated = useAppSelector((state) => state._persist.rehydrated);

  useEffect(() => {
    // User properties *must* be set before sending corresponding event properties,
    // so that the event contains the correct and up-to-date user properties.
    user.set(CustomUserProperties.USER_AGENT, navigator.userAgent);
    user.set(CustomUserProperties.BROWSER, getBrowser());
    user.set(
      CustomUserProperties.SCREEN_RESOLUTION_HEIGHT,
      window.screen.height
    );
    user.set(CustomUserProperties.SCREEN_RESOLUTION_WIDTH, window.screen.width);
    user.set(
      CustomUserProperties.GIT_COMMIT_HASH,
      process.env.REACT_APP_GIT_COMMIT_HASH ?? 'unknown'
    );
  }, []);

  useEffect(() => {
    if (!rehydrated) return;
    user.set(CustomUserProperties.ROUTER_PREFERENCE, routerPreference);
  }, [routerPreference, rehydrated]);
  return null;
}
