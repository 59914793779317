import type { DefaultTheme } from 'styled-components';
import { ThemedText } from 'theme/components';

import type { SubmittableTrade } from 'state/routing/types';
import { QuoteMethod } from 'state/routing/types';

export default function RouterLabel({
  trade,
  color
}: {
  trade: SubmittableTrade;
  color?: keyof DefaultTheme;
}) {
  // @ts-expect-error No need to check for all possible values
  if (trade.quoteMethod === QuoteMethod.CLIENT_SIDE_FALLBACK) {
    return (
      <ThemedText.BodySmall color={color}>Uniswap Client</ThemedText.BodySmall>
    );
  }

  return <ThemedText.BodySmall color={color}>Uniswap API</ThemedText.BodySmall>;
}
