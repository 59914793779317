import { TOKEN_SHORTHANDS } from '../../constants/tokens';
import type {
  CurrencyState,
  SerializedCurrencyState,
  SwapState
} from './SwapContext';
import { useSwapAndLimitContext, useSwapContext } from './SwapContext';
import type { ChainId, Currency, Percent, Token } from '@uniswap/sdk-core';
import { CurrencyAmount, TradeType } from '@uniswap/sdk-core';
import { useConnectionReady } from 'connection/eagerlyConnect';
import { asSupportedChain } from 'constants/chains';
import type { ParsedQs } from 'qs';
import { useCallback, useMemo } from 'react';

import { isAddress } from '../../utils';
import tryParseCurrencyAmount from 'lib/utils/tryParseCurrencyAmount';
import { isClassicTrade, isSubmittableTrade } from 'state/routing/utils';

import { useCurrencyBalances } from '../connection/hooks';
import { useCurrency, useDefaultActiveTokens } from 'hooks/Tokens';
import useAutoSlippageTolerance from 'hooks/useAutoSlippageTolerance';
import { useDebouncedTrade } from 'hooks/useDebouncedTrade';
import useParsedQueryString from 'hooks/useParsedQueryString';
import { useSwapTaxes } from 'hooks/useSwapTaxes';
import { useUSDPrice } from 'hooks/useUSDPrice';
import { useWeb3React } from 'hooks/useWeb3React';
import { useUserSlippageToleranceWithDefault } from 'state/user/hooks';

import { Field } from 'components/composed/Swap/constants';

import type { InterfaceTrade, TradeState } from 'state/routing/types';

export function useSwapActionHandlers(): {
  onCurrencySelection: (field: Field, currency: Currency) => void;
  onSwitchTokens: (
    newOutputHasTax: boolean,
    previouslyEstimatedOutput: string
  ) => void;
  onUserInput: (field: Field, typedValue: string) => void;
} {
  const { swapState, setSwapState } = useSwapContext();
  const { currencyState, setCurrencyState } = useSwapAndLimitContext();

  const onCurrencySelection = useCallback(
    (field: Field, currency: Currency) => {
      const [currentCurrencyKey, otherCurrencyKey]: (keyof CurrencyState)[] =
        field === Field.INPUT
          ? ['inputCurrency', 'outputCurrency']
          : ['outputCurrency', 'inputCurrency'];
      // the case where we have to swap the order
      if (currency === currencyState[otherCurrencyKey]) {
        setCurrencyState({
          [currentCurrencyKey]: currency,
          [otherCurrencyKey]: currencyState[currentCurrencyKey]
        });
        setSwapState((swapState) => ({
          ...swapState,
          independentField:
            swapState.independentField === Field.INPUT
              ? Field.OUTPUT
              : Field.INPUT
        }));
      } else {
        setCurrencyState((state) => ({
          ...state,
          [currentCurrencyKey]: currency
        }));
      }
    },
    [currencyState, setCurrencyState, setSwapState]
  );

  const onSwitchTokens = useCallback(
    (newOutputHasTax: boolean, previouslyEstimatedOutput: string) => {
      // To prevent swaps with FOT tokens as exact-outputs, we leave it as an exact-in swap and use the previously estimated output amount as the new exact-in amount.
      if (newOutputHasTax && swapState.independentField === Field.INPUT) {
        setSwapState((swapState) => ({
          ...swapState,
          typedValue: previouslyEstimatedOutput
        }));
      } else {
        setSwapState((prev) => ({
          ...prev,
          independentField:
            prev.independentField === Field.INPUT ? Field.OUTPUT : Field.INPUT
        }));
      }

      setCurrencyState((prev) => ({
        inputCurrency: prev.outputCurrency,
        outputCurrency: prev.inputCurrency
      }));
    },
    [setCurrencyState, setSwapState, swapState.independentField]
  );

  const onUserInput = useCallback(
    (field: Field, typedValue: string) => {
      setSwapState((state) => ({
        ...state,
        independentField: field,
        typedValue
      }));
    },
    [setSwapState]
  );

  return {
    onSwitchTokens,
    onCurrencySelection,
    onUserInput
  };
}

export type SwapInfoInputError = {
  message: string;
  type: 'error' | 'info';
};

export type SwapInfo = {
  currencies: { [field in Field]?: Currency };
  currencyBalances: { [field in Field]?: CurrencyAmount<Currency> };
  inputTax: Percent;
  outputTax: Percent;
  outputFeeFiatValue?: number;
  parsedAmount?: CurrencyAmount<Currency>;
  inputError?: SwapInfoInputError;
  trade: {
    trade?: InterfaceTrade;
    state: TradeState;
    swapQuoteLatency?: number;
  };
  allowedSlippage: Percent;
  autoSlippage: Percent;
};

// from the current swap inputs, compute the best trade and return it.
export function useDerivedSwapInfo(state: SwapState): SwapInfo {
  const { account } = useWeb3React();

  const {
    currencyState: { inputCurrency, outputCurrency }
  } = useSwapAndLimitContext();
  const { independentField, typedValue } = state;

  const { inputTax, outputTax } = useSwapTaxes(
    inputCurrency?.isToken ? inputCurrency.address : undefined,
    outputCurrency?.isToken ? outputCurrency.address : undefined
  );

  const relevantTokenBalances = useCurrencyBalances(
    account ?? undefined,
    useMemo(
      () => [inputCurrency ?? undefined, outputCurrency ?? undefined],
      [inputCurrency, outputCurrency]
    )
  );

  const isExactIn: boolean = independentField === Field.INPUT;
  const parsedAmount = useMemo(
    () =>
      tryParseCurrencyAmount(
        typedValue,
        (isExactIn ? inputCurrency : outputCurrency) ?? undefined
      ),
    [inputCurrency, isExactIn, outputCurrency, typedValue]
  );

  const trade = useDebouncedTrade(
    isExactIn ? TradeType.EXACT_INPUT : TradeType.EXACT_OUTPUT,
    parsedAmount,
    (isExactIn ? outputCurrency : inputCurrency) ?? undefined,
    undefined,
    account
  );

  const { data: outputFeeFiatValue } = useUSDPrice(
    isSubmittableTrade(trade.trade) && trade.trade.swapFee
      ? CurrencyAmount.fromRawAmount(
          trade.trade.outputAmount.currency,
          trade.trade.swapFee.amount
        )
      : undefined,
    trade.trade?.outputAmount.currency
  );

  const currencyBalances = useMemo(
    () => ({
      [Field.INPUT]: relevantTokenBalances[0],
      [Field.OUTPUT]: relevantTokenBalances[1]
    }),
    [relevantTokenBalances]
  );

  const currencies: { [field in Field]?: Currency } = useMemo(
    () => ({
      [Field.INPUT]: inputCurrency,
      [Field.OUTPUT]: outputCurrency
    }),
    [inputCurrency, outputCurrency]
  );

  // allowed slippage for classic trades is either auto slippage, or custom user defined slippage if auto slippage disabled
  const classicAutoSlippage = useAutoSlippageTolerance(
    isClassicTrade(trade.trade) ? trade.trade : undefined
  );

  // Uniswap interface recommended slippage amount
  const autoSlippage = classicAutoSlippage;
  const classicAllowedSlippage =
    useUserSlippageToleranceWithDefault(autoSlippage);

  // slippage amount used to submit the trade
  const allowedSlippage = classicAllowedSlippage;

  const connectionReady = useConnectionReady();
  const inputError: SwapInfoInputError | undefined = useMemo(() => {
    if (!account) {
      return {
        message: connectionReady ? 'Connect wallet' : 'Connecting wallet...',
        type: 'info'
      };
    }

    if (!currencies[Field.INPUT] || !currencies[Field.OUTPUT]) {
      return {
        message: 'Select a token',
        type: 'info'
      };
    }

    if (!parsedAmount) {
      return {
        message: 'Enter an amount',
        type: 'info'
      };
    }

    // compare input balance to max input based on version
    const [balanceIn, maxAmountIn] = [
      currencyBalances[Field.INPUT],
      trade?.trade?.maximumAmountIn(allowedSlippage)
    ];

    if (balanceIn && maxAmountIn && balanceIn.lessThan(maxAmountIn)) {
      return {
        message: `Insufficient ${balanceIn.currency.symbol} balance`,
        type: 'error'
      };
    }

    return undefined;
  }, [
    account,
    currencies,
    parsedAmount,
    currencyBalances,
    trade?.trade,
    allowedSlippage,
    connectionReady
  ]);

  return useMemo(
    () => ({
      currencies,
      currencyBalances,
      parsedAmount,
      inputError,
      trade,
      autoSlippage,
      allowedSlippage,
      outputFeeFiatValue,
      inputTax,
      outputTax
    }),
    [
      allowedSlippage,
      autoSlippage,
      currencies,
      currencyBalances,
      inputError,
      outputFeeFiatValue,
      parsedAmount,
      trade,
      inputTax,
      outputTax
    ]
  );
}

function parseCurrencyFromURLParameter(urlParam: ParsedQs[string]): string {
  if (typeof urlParam === 'string') {
    const valid = isAddress(urlParam);
    if (valid) return valid;
    const upper = urlParam.toUpperCase();
    if (upper === 'ETH') return 'ETH';
    if (upper in TOKEN_SHORTHANDS) return upper;
  }
  return '';
}

function parseTokenAmountURLParameter(
  urlParam: string | string[] | ParsedQs | ParsedQs[]
): string {
  return typeof urlParam === 'string' && !isNaN(parseFloat(urlParam))
    ? urlParam
    : '';
}

function parseIndependentFieldURLParameter(
  urlParam: string | string[] | ParsedQs | ParsedQs[]
): Field {
  // Despite properly defining and importing the 'Field' enum, accessing Field.OUTPUT or Field.INPUT
  // directly threw unresolved errors in the browser. Casting string literals to the 'Field' type
  // circumvents this issue.
  return typeof urlParam === 'string' && urlParam.toLowerCase() === 'output'
    ? ('OUTPUT' as Field)
    : ('INPUT' as Field);
}

export function queryParametersToCurrencyState(
  parsedQs: ParsedQs
): SerializedCurrencyState {
  let inputCurrency = parseCurrencyFromURLParameter(parsedQs.inputCurrency);
  let outputCurrency = parseCurrencyFromURLParameter(parsedQs.outputCurrency);
  const independentField = parseIndependentFieldURLParameter(
    parsedQs.exactField
  );

  if (
    inputCurrency === '' &&
    outputCurrency === '' &&
    independentField === Field.INPUT
  ) {
    // Defaults to having the native currency selected
    inputCurrency = 'ETH';
  } else if (inputCurrency === outputCurrency) {
    // clear output if identical
    outputCurrency = '';
  }

  return {
    inputCurrencyId: inputCurrency === '' ? null : inputCurrency ?? null,
    outputCurrencyId: outputCurrency === '' ? null : outputCurrency ?? null
  };
}

export function queryParametersToSwapState(parsedQs: ParsedQs): SwapState {
  const typedValue = parseTokenAmountURLParameter(parsedQs.exactAmount);
  const independentField = parseIndependentFieldURLParameter(
    parsedQs.exactField
  );

  return {
    typedValue,
    independentField
  };
}

export const useURLLoadedTokens = (chainId: ChainId | undefined) => {
  const parsedQueryString = useParsedQueryString();

  const { inputCurrencyId, outputCurrencyId } = useMemo(
    () => queryParametersToCurrencyState(parsedQueryString),
    [parsedQueryString]
  );

  const prefilledInputCurrency = useCurrency(inputCurrencyId, chainId);
  const prefilledOutputCurrency = useCurrency(outputCurrencyId, chainId);

  const urlLoadedTokens: Token[] = useMemo(() => {
    const tokens = [prefilledInputCurrency, prefilledOutputCurrency].filter(
      (c): c is Token => c?.isToken ?? false
    );
    return tokens;
  }, [prefilledInputCurrency, prefilledOutputCurrency]);

  const defaultTokens = useDefaultActiveTokens(chainId);

  const loadedTokens = useMemo(
    () =>
      urlLoadedTokens.filter((token: Token) => {
        if (token.address in defaultTokens) {
          return false;
        }

        const supported = asSupportedChain(chainId);
        if (!supported) {
          return true;
        }

        return !Object.values(TOKEN_SHORTHANDS[supported] || {}).includes(
          token.address
        );
      }),
    [chainId, defaultTokens, urlLoadedTokens]
  );

  return loadedTokens;
};
