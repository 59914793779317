import type { Currency } from '@uniswap/sdk-core';
import { ReactComponent as PapersIcon } from 'assets/svg/papers-text.svg';

import {
  TransactionFailedIcon,
  TransactionSubmittedIcon
} from 'components/Icons';
import Spinner from 'components/Icons/Spinner';
import { AssetLogoSize } from 'components/Logo/AssetLogo';
import CurrencyLogo from 'components/Logo/CurrencyLogo';
import styled from 'styled-components';
import { FadePresence } from 'theme/components/FadePresence';

const CurrencyLoaderContainer = styled(FadePresence)<{ asBadge: boolean }>`
  z-index: 2;
  border-radius: 50%;
  transition: all
    ${({ theme }) =>
      `${theme.transition.duration.medium} ${theme.transition.timing.inOut}`};
  position: absolute;
  height: ${({ asBadge }) => (asBadge ? '20px' : '100%')};
  width: ${({ asBadge }) => (asBadge ? '20px' : '100%')};
  bottom: ${({ asBadge }) => (asBadge ? '-4px' : 0)};
  right: ${({ asBadge }) => (asBadge ? '-4px' : 0)};
  outline: ${({ theme, asBadge }) =>
    asBadge ? `2px solid ${theme.background}` : ''};
`;

export const PaperIcon = () => (
  <FadePresence className='absolute z-1 flex h-full w-full items-center justify-center rounded-full bg-blue-200'>
    <PapersIcon />
  </FadePresence>
);

export const CurrencyLoader = ({
  currency,
  asBadge = false
}: {
  currency?: Currency;
  asBadge?: boolean;
}) => (
  <CurrencyLoaderContainer
    asBadge={asBadge}
    data-testid={`pending-modal-currency-logo-${currency?.symbol}`}
  >
    <CurrencyLogo
      size={asBadge ? AssetLogoSize.md : AssetLogoSize.xxxl}
      currency={currency}
      className='z-1'
    />
  </CurrencyLoaderContainer>
);

export const ConfirmedIcon = () => (
  <FadePresence $scale>
    <TransactionSubmittedIcon className='size-16' />
  </FadePresence>
);

export const SubmittedIcon = () => (
  <FadePresence $scale>
    <TransactionSubmittedIcon className='size-16' />
  </FadePresence>
);

export const ErrorIcon = () => (
  <FadePresence $scale>
    <TransactionFailedIcon className='size-16' />
  </FadePresence>
);

export const LoadingIndicatorOverlay = () => (
  <FadePresence>
    <Spinner className='size-16 stroke-blue-200' />
  </FadePresence>
);
