import type { InputHTMLAttributes } from 'react';
import { forwardRef } from 'react';

import { cn } from 'utils/cn';

import { Search } from 'components/Icons/Search';

const SearchInput = forwardRef<
  HTMLInputElement,
  InputHTMLAttributes<HTMLInputElement>
>(({ className, ...props }, ref) => (
  <div className='relative w-full'>
    <div className='absolute left-2 flex h-full items-center'>
      <Search className='h-5 w-5 fill-blue-200' />
    </div>

    <input
      ref={ref}
      className={cn(
        'flex h-10 w-full items-center rounded-md border border-blue-200/50 bg-blue-900 bg-no-repeat py-2.5 pl-10 pr-4 text-white outline-none placeholder:text-blue-200',
        className
      )}
      {...props}
    />
  </div>
));

SearchInput.displayName = 'SearchInput';

export default SearchInput;
