import type { ComponentProps } from 'react';

const Logo = (props: ComponentProps<'svg'>) => (
  <svg
    width='72'
    height='16'
    viewBox='0 0 72 16'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <g id='Layer_1' clipPath='url(#clip0_483_2)'>
      <path
        id='Vector'
        d='M3.49017 6.25819H0V15.8784H3.49017V6.25819Z'
        fill='#050B43'
      />
      <path
        id='Vector_2'
        d='M72.0004 0H67.4443L63.2365 5.01439L59.0287 0H54.4727L61.4907 8.36284H61.4922V15.8784H64.9824V8.36284L72.0004 0Z'
        fill='#050B43'
      />
      <path
        id='Vector_3'
        d='M12.3611 0H0V3.49017H11.758C12.0912 3.49017 12.3611 3.76004 12.3611 4.09322V15.8769H15.8512V3.49017C15.8528 1.56341 14.2893 0 12.3611 0Z'
        fill='#050B43'
      />
      <path
        id='Vector_4'
        d='M32.9008 0.000137329H19.2628V3.49031H32.9008V0.000137329Z'
        fill='#050B43'
      />
      <path
        id='Vector_5'
        d='M19.2628 6.25833V12.3884C19.2628 14.3166 20.8262 15.8785 22.7529 15.8785H32.9008V12.3884H23.356C23.0228 12.3884 22.7529 12.1185 22.7529 11.7853V9.68518H32.9008V6.19501H19.2628V6.25833Z'
        fill='#050B43'
      />
      <path
        id='Vector_6'
        d='M49.6986 0H36.0605V3.49017H47.8955C48.2286 3.49017 48.4985 3.76004 48.4985 4.09322V6.19335H36.0605V15.8784H49.6986C50.3303 15.8784 50.9032 15.6221 51.3178 15.2075C51.7324 14.7929 51.9887 14.2215 51.9887 13.5883V9.59307C51.9887 8.69904 51.2635 7.97387 50.3695 7.97387C51.2635 7.97387 51.9887 7.2487 51.9887 6.35467V2.29009C51.9887 1.02519 50.9635 0 49.6986 0ZM48.4985 11.7837C48.4985 12.1168 48.2286 12.3867 47.8955 12.3867H39.5507V9.68503H47.8955C48.2286 9.68503 48.4985 9.9549 48.4985 10.2881V11.7837Z'
        fill='#050B43'
      />
    </g>
    <defs>
      <clipPath id='clip0_483_2'>
        <rect width='72' height='15.8784' fill='white' />
      </clipPath>
    </defs>
  </svg>
);

export default Logo;
