import {
  EmptyActivityIcon,
  EmptyNftsIcon,
  EmptyPoolsIcon,
  EmptyTokensIcon
} from './icons';
import { headlineMedium } from 'nft/css/common.css';
import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import Button from 'components/ui/Button';

import styled from 'styled-components';
import { ThemedText } from 'theme/components';

const EmptyWalletContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  height: 100%;
  width: 100%;
`;

const EmptyWalletText = styled(ThemedText.SubHeader)`
  white-space: normal;
  margin-top: 12px;
  text-align: center;
`;

const EmptyWalletSubtitle = styled(ThemedText.BodySmall)`
  white-space: normal;
  text-align: center;
  margin-top: 8px;
`;

type EmptyWalletContent = {
  title: React.ReactNode;
  subtitle: React.ReactNode;
  actionText?: React.ReactNode;
  urlPath?: string;
  icon: React.ReactNode;
};
type EmptyWalletContentType = 'nft' | 'token' | 'activity' | 'pool';
const EMPTY_WALLET_CONTENT: {
  [key in EmptyWalletContentType]: EmptyWalletContent;
} = {
  nft: {
    title: 'No NFTs yet',
    subtitle: 'Buy or transfer NFTs to this wallet to get started.',
    actionText: 'Explore NFTs',
    urlPath: '/nfts',
    icon: <EmptyNftsIcon />
  },
  token: {
    title: 'No tokens yet',
    subtitle: 'Buy or transfer tokens to this wallet to get started.',
    actionText: 'Explore tokens',
    urlPath: '/tokens',
    icon: <EmptyTokensIcon />
  },
  activity: {
    title: 'No activity yet',
    subtitle:
      'Your onchain transactions and crypto purchases will appear here.',
    icon: <EmptyActivityIcon />
  },
  pool: {
    title: 'No pools yet',
    subtitle: 'Open a new position or create a pool to get started.',
    actionText: '+ New position',
    urlPath: '/pool',
    icon: <EmptyPoolsIcon />
  }
};

interface EmptyWalletContentProps {
  type?: EmptyWalletContentType;
  onNavigateClick?: () => void;
}

const EmptyWalletContent = ({
  type = 'nft',
  onNavigateClick
}: EmptyWalletContentProps) => {
  const navigate = useNavigate();

  const content = EMPTY_WALLET_CONTENT[type];

  const actionButtonClick = useCallback(() => {
    if (content.urlPath) {
      onNavigateClick?.();
      navigate(content.urlPath);
    }
  }, [content.urlPath, navigate, onNavigateClick]);

  return (
    <>
      {content.icon}
      <EmptyWalletText className={headlineMedium}>
        {content.title}
      </EmptyWalletText>
      <EmptyWalletSubtitle color='neutral2'>
        {content.subtitle}
      </EmptyWalletSubtitle>
      {content.actionText && (
        <Button
          data-testid='nft-explore-nfts-button'
          onClick={actionButtonClick}
          variant='action'
        >
          {content.actionText}
        </Button>
      )}
    </>
  );
};

export const EmptyWalletModule = (props?: EmptyWalletContentProps) => (
  <EmptyWalletContainer>
    <EmptyWalletContent {...props} />
  </EmptyWalletContainer>
);
