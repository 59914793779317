import CurrencySearchModal from '../SearchModal/CurrencySearchModal';
import { forwardRef, useCallback, useState } from 'react';

import type { InputPanelProps } from 'components/composed/CurrencyInputPanel';
import CurrencyInputPanel from 'components/composed/CurrencyInputPanel';

const SwapCurrencyInputPanel = forwardRef<HTMLInputElement, InputPanelProps>(
  (
    { onCurrencySelect, currency, otherCurrency, showCommonBases, ...props },
    ref
  ) => {
    const [modalOpen, setModalOpen] = useState(false);

    const handleDismissSearch = useCallback(() => {
      setModalOpen(false);
    }, [setModalOpen]);

    return (
      <>
        <CurrencyInputPanel
          {...props}
          ref={ref}
          modalOpen={modalOpen}
          setModalOpen={setModalOpen}
          currency={currency}
          onCurrencySelect={onCurrencySelect}
        />
        {onCurrencySelect && (
          <CurrencySearchModal
            isOpen={modalOpen}
            onDismiss={handleDismissSearch}
            onCurrencySelect={onCurrencySelect}
            selectedCurrency={currency}
            otherSelectedCurrency={otherCurrency}
            showCommonBases={showCommonBases}
          />
        )}
      </>
    );
  }
);
SwapCurrencyInputPanel.displayName = 'SwapCurrencyInputPanel';

export default SwapCurrencyInputPanel;
