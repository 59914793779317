import { SUPPORTED_GAS_ESTIMATE_CHAIN_IDS } from 'constants/chains';

import { NumberType, useFormatter } from 'utils/formatNumbers';
import getRoutingDiagramEntries from 'utils/getRoutingDiagramEntries';

import useAutoRouterSupported from 'hooks/useAutoRouterSupported';

import RoutingDiagram from 'components/RoutingDiagram/RoutingDiagram';

import type { ClassicTrade } from 'state/routing/types';

// TODO(WEB-2022)
// Can `trade.gasUseEstimateUSD` be defined when `chainId` is not in `SUPPORTED_GAS_ESTIMATE_CHAIN_IDS`?
const useGasPrice = ({ gasUseEstimateUSD, inputAmount }: ClassicTrade) => {
  const { formatNumber } = useFormatter();
  if (
    !gasUseEstimateUSD ||
    !SUPPORTED_GAS_ESTIMATE_CHAIN_IDS.includes(inputAmount.currency.chainId)
  )
    return undefined;

  return gasUseEstimateUSD === 0
    ? '<$0.01'
    : formatNumber({ input: gasUseEstimateUSD, type: NumberType.FiatGasPrice });
};

export const SwapRoute = ({ trade }: { trade: ClassicTrade }) => {
  const { inputAmount, outputAmount } = trade;
  const routes = getRoutingDiagramEntries(trade);
  const gasPrice = useGasPrice(trade);

  return useAutoRouterSupported() ? (
    <div className='flex flex-col justify-start gap-2'>
      <RoutingDiagram
        routes={routes}
        currencyIn={inputAmount.currency}
        currencyOut={outputAmount.currency}
      />
      <caption className='text-gray-600'>
        {Boolean(gasPrice) && `Best price route costs ~${gasPrice} in gas. `}
        {Boolean(gasPrice) && ' '}
        This route optimizes your total output by considering split routes,
        multiple hops, and the gas cost of each step.
      </caption>
    </div>
  ) : (
    <RoutingDiagram
      routes={routes}
      currencyIn={inputAmount.currency}
      currencyOut={outputAmount.currency}
    />
  );
};
