import { ChainId, type Currency, Percent } from '@uniswap/sdk-core';
import {
  type Dispatch,
  type PropsWithChildren,
  type SetStateAction,
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react';

import {
  type SwapInfo,
  queryParametersToSwapState,
  useDerivedSwapInfo
} from './hooks';
import { parsedQueryString } from 'hooks/useParsedQueryString';
import usePrevious from 'hooks/usePrevious';
import { useWeb3React } from 'hooks/useWeb3React';

import { type Field } from 'components/composed/Swap/constants';

import { TradeState } from 'state/routing/types';

export interface SerializedCurrencyState {
  inputCurrencyId?: string | null;
  outputCurrencyId?: string | null;
}

// shared state between Swap and Limit
export interface CurrencyState {
  inputCurrency?: Currency;
  outputCurrency?: Currency;
}

export interface SwapState {
  readonly independentField: Field;
  readonly typedValue: string;
}

const initialSwapState: SwapState =
  queryParametersToSwapState(parsedQueryString());

type SwapAndLimitContextType = {
  currencyState: CurrencyState;
  prefilledState: {
    inputCurrency?: Currency;
    outputCurrency?: Currency;
  };
  setCurrencyState: Dispatch<SetStateAction<CurrencyState>>;
  // The chainId of the page/context - can be different from the connected Chain ID if the
  // page is displaying content for a different chain
  chainId?: ChainId;
};

type SwapContextType = {
  swapState: SwapState;
  derivedSwapInfo: SwapInfo;
  setSwapState: Dispatch<SetStateAction<SwapState>>;
};

export const SwapAndLimitContext = createContext<SwapAndLimitContextType>({
  currencyState: {
    inputCurrency: undefined,
    outputCurrency: undefined
  },
  setCurrencyState: () => undefined,
  prefilledState: {
    inputCurrency: undefined,
    outputCurrency: undefined
  },
  chainId: ChainId.MAINNET
});

export const EMPTY_DERIVED_SWAP_INFO: SwapInfo = Object.freeze({
  currencies: {},
  currencyBalances: {},
  inputTax: new Percent(0),
  outputTax: new Percent(0),
  autoSlippage: new Percent(0),
  allowedSlippage: new Percent(0),
  trade: {
    state: TradeState.LOADING
  }
});

export const SwapContext = createContext<SwapContextType>({
  swapState: initialSwapState,
  derivedSwapInfo: EMPTY_DERIVED_SWAP_INFO,
  setSwapState: () => undefined
});

export function useSwapContext() {
  return useContext(SwapContext);
}

export function useSwapAndLimitContext() {
  return useContext(SwapAndLimitContext);
}

export function SwapAndLimitContextProvider({
  children,
  chainId,
  initialInputCurrency,
  initialOutputCurrency
}: PropsWithChildren<{
  chainId?: ChainId;
  initialInputCurrency?: Currency;
  initialOutputCurrency?: Currency;
}>) {
  const { chainId: connectedChainId } = useWeb3React();
  const [currencyState, setCurrencyState] = useState<CurrencyState>({
    inputCurrency: initialInputCurrency,
    outputCurrency: initialOutputCurrency
  });

  const prefilledState = useMemo(
    () => ({
      inputCurrency: initialInputCurrency,
      outputCurrency: initialOutputCurrency
    }),
    [initialInputCurrency, initialOutputCurrency]
  );

  const previousConnectedChainId = usePrevious(connectedChainId);
  const previousPrefilledState = usePrevious(prefilledState);

  useEffect(() => {
    const combinedCurrencyState = { ...currencyState, ...prefilledState };
    const chainChanged =
      previousConnectedChainId && previousConnectedChainId !== connectedChainId;
    const prefilledInputChanged =
      previousPrefilledState?.inputCurrency &&
      !prefilledState.inputCurrency?.equals(
        previousPrefilledState.inputCurrency
      );
    const prefilledOutputChanged =
      previousPrefilledState?.outputCurrency &&
      !prefilledState?.outputCurrency?.equals(
        previousPrefilledState.outputCurrency
      );

    if (chainChanged || prefilledInputChanged || prefilledOutputChanged) {
      setCurrencyState({
        inputCurrency: combinedCurrencyState.inputCurrency ?? undefined,
        outputCurrency: combinedCurrencyState.outputCurrency ?? undefined
      });
    }
  }, [
    connectedChainId,
    currencyState,
    prefilledState,
    previousConnectedChainId,
    previousPrefilledState
  ]);

  return (
    <SwapAndLimitContext.Provider
      value={{
        currencyState,
        setCurrencyState,
        prefilledState,
        chainId
      }}
    >
      {children}
    </SwapAndLimitContext.Provider>
  );
}

export function SwapContextProvider({
  children
}: {
  children: React.ReactNode;
}) {
  const [swapState, setSwapState] = useState<SwapState>({
    ...initialSwapState
  });
  const derivedSwapInfo = useDerivedSwapInfo(swapState);
  return (
    <SwapContext.Provider value={{ swapState, setSwapState, derivedSwapInfo }}>
      {children}
    </SwapContext.Provider>
  );
}
