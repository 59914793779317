import { AutoColumn } from '../Column';
import Modal from '../Modal';
import { useRef } from 'react';
import { ArrowDown, Info, X } from 'react-feather';

import { isMobile } from 'utils/wallet';

import {
  useModalIsOpen,
  useTogglePrivacyPolicy
} from '../../state/application/hooks';

import Card, { DarkGrayCard } from 'components/Card';
import Row, { AutoRow, RowBetween } from 'components/Row';
import styled from 'styled-components';
import { ExternalLink, ThemedText } from 'theme/components';

import { ApplicationModal } from '../../state/application/reducer';

const Wrapper = styled.div`
  max-height: 70vh;
  overflow: auto;
  padding: 0 1rem;
`;

const StyledExternalCard = styled(Card)`
  background-color: ${({ theme }) => theme.accent2};
  padding: 0.5rem;
  width: 100%;

  :hover,
  :focus,
  :active {
    background-color: ${({ theme }) => theme.neutral3};
  }
`;

const HoverText = styled.div`
  text-decoration: none;
  color: ${({ theme }) => theme.neutral1};
  display: flex;
  align-items: center;

  :hover {
    cursor: pointer;
  }
`;

const StyledLinkOut = styled(ArrowDown)`
  transform: rotate(230deg);
`;

const EXTERNAL_APIS = [
  {
    name: 'Auto Router',
    description:
      'The app fetches the optimal trade route from a Uniswap Labs server.'
  },
  {
    name: 'Infura',
    description:
      'The app fetches on-chain data and constructs contract calls with an Infura API.'
  },
  {
    name: 'TRM Labs',
    description: (
      <>
        The app securely collects your wallet address and shares it with TRM
        Labs Inc. for risk and compliance reasons.{' '}
        <ExternalLink href='https://support.uniswap.org/hc/en-us/articles/8671777747597-Address-Screening-Guide'>
          Learn more
        </ExternalLink>
      </>
    )
  },
  {
    name: 'Google Analytics & Amplitude',
    description:
      'The app logs anonymized usage statistics in order to improve over time.'
  },
  {
    name: 'The Graph',
    description:
      'The app fetches blockchain data from The Graph’s hosted service.'
  }
];

export function PrivacyPolicyModal() {
  const node = useRef<HTMLDivElement>();
  const open = useModalIsOpen(ApplicationModal.PRIVACY_POLICY);
  const toggle = useTogglePrivacyPolicy();

  return (
    <Modal isOpen={open} onDismiss={() => toggle()}>
      <AutoColumn gap='md' ref={node as any}>
        <RowBetween padding='1rem 1rem 0.5rem 1rem'>
          <ThemedText.DeprecatedMediumHeader>
            Legal & Privacy
          </ThemedText.DeprecatedMediumHeader>
          <HoverText onClick={() => toggle()}>
            <X size={24} />
          </HoverText>
        </RowBetween>
        <PrivacyPolicy />
      </AutoColumn>
    </Modal>
  );
}

function PrivacyPolicy() {
  return (
    <Wrapper
      draggable='true'
      onTouchMove={(e) => {
        // prevent modal gesture handler from dismissing modal when content is scrolling
        if (isMobile) {
          e.stopPropagation();
        }
      }}
    >
      <AutoColumn gap='16px'>
        <AutoColumn gap='sm' style={{ width: '100%' }}>
          <StyledExternalCard>
            <ExternalLink href='https://uniswap.org/terms-of-service'>
              <RowBetween>
                <AutoRow gap='4px'>
                  <Info size={20} />
                  <ThemedText.DeprecatedMain fontSize={14} color='accent1'>
                    Uniswap Labs&apos; Terms of Service
                  </ThemedText.DeprecatedMain>
                </AutoRow>
                <StyledLinkOut size={20} />
              </RowBetween>
            </ExternalLink>
          </StyledExternalCard>
          <StyledExternalCard>
            <ExternalLink href='https://uniswap.org/privacy-policy/'>
              <RowBetween>
                <AutoRow gap='4px'>
                  <Info size={20} />
                  <ThemedText.DeprecatedMain fontSize={14} color='accent1'>
                    Privacy Policy
                  </ThemedText.DeprecatedMain>
                </AutoRow>
                <StyledLinkOut size={20} />
              </RowBetween>
            </ExternalLink>
          </StyledExternalCard>
        </AutoColumn>
        <ThemedText.DeprecatedMain fontSize={14}>
          This app uses the following third-party APIs:
        </ThemedText.DeprecatedMain>
        <AutoColumn gap='md'>
          {EXTERNAL_APIS.map(({ name, description }, i) => (
            <DarkGrayCard key={i}>
              <AutoColumn gap='sm'>
                <AutoRow gap='4px'>
                  <Info size={18} />
                  <ThemedText.DeprecatedMain fontSize={14} color='neutral1'>
                    {name}
                  </ThemedText.DeprecatedMain>
                </AutoRow>
                <ThemedText.DeprecatedMain fontSize={14}>
                  {description}
                </ThemedText.DeprecatedMain>
              </AutoColumn>
            </DarkGrayCard>
          ))}
          <ThemedText.DeprecatedBody fontSize={12}>
            <Row justify='center' marginBottom='1rem'>
              <ExternalLink href='https://help.uniswap.org/en/articles/5675203-terms-of-service-faq'>
                Learn more
              </ExternalLink>
            </Row>
          </ThemedText.DeprecatedBody>
        </AutoColumn>
      </AutoColumn>
    </Wrapper>
  );
}
