import type { BaseProvider, Web3Provider } from '@ethersproject/providers';
import { OasisSapphireTestnet } from '@neby/chains';
import * as sapphire from '@oasisprotocol/sapphire-paratime';
import type { Web3ContextType } from '@web3-react/core';
import { useWeb3React as useWeb3Core } from '@web3-react/core';
import { useMemo } from 'react';

export const useWeb3React = <
  T extends BaseProvider = Web3Provider
>(): Web3ContextType<T> => {
  const { provider, chainId, ...web3Core } = useWeb3Core<T>();

  const wrappedProvider = useMemo<T | undefined>(() => {
    if (!provider) {
      return provider;
    }

    // Needed only for Oasis encrypted transactions
    if (chainId === OasisSapphireTestnet.chainId) {
      return sapphire.wrap<T>(provider);
    }

    return provider;
  }, [chainId, provider]);

  return useMemo<Web3ContextType<T>>(
    () => ({
      ...web3Core,
      provider: wrappedProvider,
      chainId
    }),
    [web3Core, wrappedProvider, chainId]
  );
};
