import type { PersistState } from 'redux-persist';

import type { UserState } from 'state/user/reducer';

export type PersistAppStateV4 = {
  _persist: PersistState;
} & { user?: UserState };

/**
 * Migration to set german locale to default locale, after
 * the german locale was removed from supported locales.
 */
export const migration4 = (state: PersistAppStateV4 | undefined) => {
  if (state?.user) {
    return {
      ...state,
      _persist: {
        ...state._persist,
        version: 4
      }
    };
  }
  return state;
};
