import type { NormalizedCacheObject } from '@apollo/client';
import {
  ApolloClient,
  ApolloLink,
  HttpLink,
  InMemoryCache,
  concat
} from '@apollo/client';
import { BaseSepoliaTestnet, OasisSapphireTestnet } from '@neby/chains';
import { ChainId } from '@uniswap/sdk-core';

import store from '../../state/index';

const CHAIN_SUBGRAPH_URL: Record<number, string> = {
  [ChainId.MAINNET]:
    'https://api.thegraph.com/subgraphs/name/uniswap/uniswap-v3?source=uniswap',
  [ChainId.ARBITRUM_ONE]:
    'https://api.thegraph.com/subgraphs/name/ianlapham/uniswap-arbitrum-one?source=uniswap',
  [ChainId.OPTIMISM]:
    'https://api.thegraph.com/subgraphs/name/ianlapham/optimism-post-regenesis?source=uniswap',
  [ChainId.POLYGON]:
    'https://api.thegraph.com/subgraphs/name/ianlapham/uniswap-v3-polygon?source=uniswap',
  [ChainId.CELO]:
    'https://api.thegraph.com/subgraphs/name/jesse-sawa/uniswap-celo?source=uniswap',
  [ChainId.BNB]:
    'https://api.thegraph.com/subgraphs/name/ianlapham/uniswap-v3-bsc?source=uniswap',
  [ChainId.AVALANCHE]:
    'https://api.thegraph.com/subgraphs/name/lynnshaoyu/uniswap-v3-avax?source=uniswap',
  [ChainId.BASE]:
    'https://api.studio.thegraph.com/query/48211/uniswap-v3-base/version/latest',
  // NEBY_OASIS_CHANGES
  [ChainId.OASIS_SAPPHIRE_TESTNET]: OasisSapphireTestnet.subgraph,
  [ChainId.BASE_SEPOLIA_TESTNET]: BaseSepoliaTestnet.subgraph
};

const CHAIN_BLOCK_SUBGRAPH_URL: Record<number, string> = {
  [ChainId.MAINNET]:
    'https://api.thegraph.com/subgraphs/name/blocklytics/ethereum-blocks?source=uniswap',
  [ChainId.ARBITRUM_ONE]:
    'https://api.thegraph.com/subgraphs/name/ianlapham/arbitrum-one-blocks?source=uniswap',
  [ChainId.OPTIMISM]:
    'https://api.thegraph.com/subgraphs/name/ianlapham/uni-testing-subgraph?source=uniswap',
  [ChainId.POLYGON]:
    'https://api.thegraph.com/subgraphs/name/ianlapham/polygon-blocks?source=uniswap',
  [ChainId.CELO]:
    'https://api.thegraph.com/subgraphs/name/jesse-sawa/celo-blocks?source=uniswap',
  [ChainId.BNB]:
    'https://api.thegraph.com/subgraphs/name/wombat-exchange/bnb-chain-block?source=uniswap',
  [ChainId.AVALANCHE]:
    'https://api.thegraph.com/subgraphs/name/lynnshaoyu/avalanche-blocks?source=uniswap',
  [ChainId.BASE]:
    'https://api.studio.thegraph.com/query/48211/base-blocks/version/latest?source=uniswap',
  [ChainId.OASIS_SAPPHIRE_TESTNET]: OasisSapphireTestnet.blockSubgraph,
  [ChainId.BASE_SEPOLIA_TESTNET]: BaseSepoliaTestnet.blockSubgraph
};

const CHAIN_HARVESTER_SUBGRAPH_URL: Record<number, string> = {
  [ChainId.OASIS_SAPPHIRE_TESTNET]: OasisSapphireTestnet.harvesterSubgraph,
  [ChainId.BASE_SEPOLIA_TESTNET]: BaseSepoliaTestnet.harvesterSubgraph
};

const CHAIN_STAKER_SUBGRAPH_URL: Record<number, string> = {
  [ChainId.OASIS_SAPPHIRE_TESTNET]: OasisSapphireTestnet.stakerSubgraph,
  [ChainId.BASE_SEPOLIA_TESTNET]: BaseSepoliaTestnet.stakerSubgraph
};

const httpLink = new HttpLink({ uri: CHAIN_SUBGRAPH_URL[ChainId.MAINNET] });

// This middleware will allow us to dynamically update the uri for the requests based off chainId
// For more information: https://www.apollographql.com/docs/react/networking/advanced-http-networking/
const authMiddleware = new ApolloLink((operation, forward) => {
  // add the authorization to the headers
  const chainId = store.getState().application.chainId;

  operation.setContext(() => ({
    uri:
      chainId && CHAIN_SUBGRAPH_URL[chainId]
        ? CHAIN_SUBGRAPH_URL[chainId]
        : CHAIN_SUBGRAPH_URL[ChainId.MAINNET]
  }));

  return forward(operation);
});

export const apolloClient = new ApolloClient({
  cache: new InMemoryCache(),
  link: concat(authMiddleware, httpLink)
});

export const chainToApolloClient: Record<
  number,
  ApolloClient<NormalizedCacheObject>
> = {
  [ChainId.MAINNET]: new ApolloClient({
    cache: new InMemoryCache(),
    uri: CHAIN_SUBGRAPH_URL[ChainId.MAINNET]
  }),
  [ChainId.ARBITRUM_ONE]: new ApolloClient({
    cache: new InMemoryCache(),
    uri: CHAIN_SUBGRAPH_URL[ChainId.ARBITRUM_ONE]
  }),
  [ChainId.OPTIMISM]: new ApolloClient({
    cache: new InMemoryCache(),
    uri: CHAIN_SUBGRAPH_URL[ChainId.OPTIMISM]
  }),
  [ChainId.POLYGON]: new ApolloClient({
    cache: new InMemoryCache(),
    uri: CHAIN_SUBGRAPH_URL[ChainId.POLYGON]
  }),
  [ChainId.CELO]: new ApolloClient({
    cache: new InMemoryCache(),
    uri: CHAIN_SUBGRAPH_URL[ChainId.CELO]
  }),
  [ChainId.BNB]: new ApolloClient({
    cache: new InMemoryCache(),
    uri: CHAIN_SUBGRAPH_URL[ChainId.BNB]
  }),
  [ChainId.AVALANCHE]: new ApolloClient({
    cache: new InMemoryCache(),
    uri: CHAIN_SUBGRAPH_URL[ChainId.AVALANCHE]
  }),
  // NEBY_OASIS_CHANGES
  [ChainId.OASIS_SAPPHIRE_TESTNET]: new ApolloClient({
    cache: new InMemoryCache(),
    uri: CHAIN_SUBGRAPH_URL[ChainId.OASIS_SAPPHIRE_TESTNET]
  }),
  [ChainId.BASE_SEPOLIA_TESTNET]: new ApolloClient({
    cache: new InMemoryCache(),
    uri: CHAIN_SUBGRAPH_URL[ChainId.BASE_SEPOLIA_TESTNET]
  })
};

export const chainToApolloBlockClient: Record<
  number,
  ApolloClient<NormalizedCacheObject>
> = {
  [ChainId.MAINNET]: new ApolloClient({
    uri: CHAIN_BLOCK_SUBGRAPH_URL[ChainId.MAINNET],
    cache: new InMemoryCache()
  }),
  [ChainId.ARBITRUM_ONE]: new ApolloClient({
    uri: CHAIN_BLOCK_SUBGRAPH_URL[ChainId.ARBITRUM_ONE],
    cache: new InMemoryCache()
  }),
  [ChainId.OPTIMISM]: new ApolloClient({
    uri: CHAIN_BLOCK_SUBGRAPH_URL[ChainId.OPTIMISM],
    cache: new InMemoryCache()
  }),
  [ChainId.POLYGON]: new ApolloClient({
    uri: CHAIN_BLOCK_SUBGRAPH_URL[ChainId.POLYGON],
    cache: new InMemoryCache()
  }),
  [ChainId.CELO]: new ApolloClient({
    uri: CHAIN_BLOCK_SUBGRAPH_URL[ChainId.CELO],
    cache: new InMemoryCache()
  }),
  [ChainId.BNB]: new ApolloClient({
    uri: CHAIN_BLOCK_SUBGRAPH_URL[ChainId.BNB],
    cache: new InMemoryCache()
  }),
  [ChainId.AVALANCHE]: new ApolloClient({
    uri: CHAIN_BLOCK_SUBGRAPH_URL[ChainId.AVALANCHE],
    cache: new InMemoryCache()
  }),
  // NEBY_OASIS_CHANGES
  [ChainId.OASIS_SAPPHIRE_TESTNET]: new ApolloClient({
    uri: CHAIN_BLOCK_SUBGRAPH_URL[ChainId.OASIS_SAPPHIRE_TESTNET],
    cache: new InMemoryCache()
  }),
  [ChainId.BASE_SEPOLIA_TESTNET]: new ApolloClient({
    uri: CHAIN_BLOCK_SUBGRAPH_URL[ChainId.BASE_SEPOLIA_TESTNET],
    cache: new InMemoryCache()
  })
};

export const chainToApolloHarvesterClient: Record<
  number,
  ApolloClient<NormalizedCacheObject>
> = {
  [ChainId.OASIS_SAPPHIRE_TESTNET]: new ApolloClient({
    uri: CHAIN_HARVESTER_SUBGRAPH_URL[ChainId.OASIS_SAPPHIRE_TESTNET],
    cache: new InMemoryCache()
  }),
  [ChainId.BASE_SEPOLIA_TESTNET]: new ApolloClient({
    uri: CHAIN_HARVESTER_SUBGRAPH_URL[ChainId.BASE_SEPOLIA_TESTNET],
    cache: new InMemoryCache()
  })
};

export const chainToApolloStakerClient: Record<
  number,
  ApolloClient<NormalizedCacheObject>
> = {
  [ChainId.OASIS_SAPPHIRE_TESTNET]: new ApolloClient({
    uri: CHAIN_STAKER_SUBGRAPH_URL[ChainId.OASIS_SAPPHIRE_TESTNET],
    cache: new InMemoryCache()
  }),
  [ChainId.BASE_SEPOLIA_TESTNET]: new ApolloClient({
    uri: CHAIN_STAKER_SUBGRAPH_URL[ChainId.BASE_SEPOLIA_TESTNET],
    cache: new InMemoryCache()
  })
};
