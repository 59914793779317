import { DialogContent, DialogOverlay } from '@reach/dialog';
import React from 'react';
import { animated, easings, useSpring, useTransition } from 'react-spring';
import { useGesture } from 'react-use-gesture';
import { Z_INDEX } from 'theme/zIndex';

import { cn } from 'utils/cn';
import { isMobile } from 'utils/wallet';

import Button from 'components/ui/Button';

import {
  InnerInwardDesign,
  OuterInwardDesign
} from 'components/composed/ContainerShapes';
import { ContainerSpaceWidth } from 'components/composed/ContainerShapes/constants';
import styled, { css } from 'styled-components';

export const MODAL_TRANSITION_DURATION = 200;

const AnimatedDialogOverlay = animated(DialogOverlay);

const StyledDialogOverlay = styled(AnimatedDialogOverlay)<{
  $scrollOverlay?: boolean;
}>`
  will-change: transform, opacity;
  &[data-reach-dialog-overlay] {
    z-index: ${Z_INDEX.modalBackdrop};
    background-color: transparent;
    overflow: hidden;

    display: flex;
    align-items: center;
    @media screen and (max-width: ${({ theme }) => theme.breakpoint.sm}px) {
      align-items: flex-end;
    }
    overflow-y: ${({ $scrollOverlay }) => $scrollOverlay && 'scroll'};
    justify-content: center;

    background-color: ${({ theme }) => theme.scrim};
  }
`;

type StyledDialogProps = {
  $minHeight?: number | false;
  $maxHeight?: number;
  $scrollOverlay?: boolean;
  $hideBorder?: boolean;
  $maxWidth: number;
};

const AnimatedDialogContent = animated(DialogContent);
const StyledDialogContent = styled(AnimatedDialogContent)<StyledDialogProps>`
  overflow-y: auto;

  &[data-reach-dialog-content] {
    margin: auto;
    display: flex;
    flex-direction: column;
    background: transparent;
    padding: 0px;
    width: 50vw;
    overflow-y: auto;
    overflow-x: hidden;
    max-width: ${({ $maxWidth }) => $maxWidth}px;
    ${({ $maxHeight }) =>
      $maxHeight &&
      css`
        max-height: ${$maxHeight}vh;
      `}
    ${({ $minHeight }) =>
      $minHeight &&
      css`
        min-height: ${$minHeight}vh;
      `}
    display: ${({ $scrollOverlay }) =>
      $scrollOverlay ? 'inline-table' : 'flex'};
    // border only bottom
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;

    @media screen and (max-width: ${({ theme }) => theme.breakpoint.md}px) {
      width: 65vw;
    }
    @media screen and (max-width: ${({ theme }) => theme.breakpoint.sm}px) {
      margin: 0;
      width: 100vw;
      border-radius: 20px 20px 0 0;
    }
  }
`;

interface ModalProps {
  isOpen: boolean;
  onDismiss?: () => void;
  onSwipe?: () => void;
  height?: number; // takes precedence over minHeight and maxHeight
  minHeight?: number | false;
  maxHeight?: number;
  maxWidth?: number;
  initialFocusRef?: React.RefObject<any>;
  children?: React.ReactNode;
  $scrollOverlay?: boolean;
  hideBorder?: boolean;
  slideIn?: boolean;
  closeButton?: boolean;
  className?: string;
}

export default function Modal({
  isOpen,
  onDismiss,
  minHeight = false,
  maxHeight = 90,
  maxWidth = 420,
  height,
  initialFocusRef,
  children,
  onSwipe = onDismiss,
  $scrollOverlay,
  hideBorder = false,
  slideIn,
  closeButton = false,
  className
}: ModalProps) {
  const fadeTransition = useTransition(isOpen, {
    config: { duration: MODAL_TRANSITION_DURATION },
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 }
  });

  const slideTransition = useTransition(isOpen, {
    config: {
      duration: MODAL_TRANSITION_DURATION,
      easing: easings.easeInOutCubic
    },
    from: { transform: 'translateY(40px)' },
    enter: { transform: 'translateY(0px)' },
    leave: { transform: 'translateY(40px)' }
  });

  const [{ y }, set] = useSpring(() => ({
    y: 0,
    config: { mass: 1, tension: 210, friction: 20 }
  }));
  const bind = useGesture({
    onDrag: (state) => {
      set({
        y: state.down ? state.movement[1] : 0
      });
      if (
        state.movement[1] > 300 ||
        (state.velocity > 3 && state.direction[1] > 0)
      ) {
        onSwipe?.();
      }
    }
  });

  return (
    <>
      {fadeTransition(
        ({ opacity }, item) =>
          item && (
            <StyledDialogOverlay
              className='bg-blue-900'
              style={{
                opacity: opacity.to({ range: [0.0, 1.0], output: [0, 1] })
              }}
              onDismiss={onDismiss}
              initialFocusRef={initialFocusRef}
              unstable_lockFocusAcrossFrames={false}
              $scrollOverlay={$scrollOverlay}
            >
              {slideTransition(
                (styles, item) =>
                  item && (
                    <StyledDialogContent
                      className={className}
                      {...(isMobile
                        ? {
                            ...bind(),
                            style: {
                              transform: y.interpolate(
                                (y) =>
                                  `translateY(${(y as number) > 0 ? y : 0}px)`
                              )
                            }
                          }
                        : slideIn
                          ? { style: styles }
                          : {})}
                      aria-label='dialog'
                      $minHeight={height ?? minHeight}
                      $maxHeight={height ?? maxHeight}
                      $scrollOverlay={$scrollOverlay}
                      $hideBorder={hideBorder}
                      $maxWidth={maxWidth}
                    >
                      <div className='relative flex flex-col'>
                        <OuterInwardDesign
                          size={ContainerSpaceWidth.OUTER_MEDIUM}
                        />

                        <InnerInwardDesign
                          size={ContainerSpaceWidth.INNER_MEDIUM}
                          className='absolute left-0 top-0 w-full p-1.5'
                        />
                      </div>

                      {/* prevents the automatic focusing of inputs on mobile by the reach dialog */}
                      {!initialFocusRef && isMobile ? (
                        <div tabIndex={1} />
                      ) : null}

                      <div className='flex flex-grow flex-col bg-blue-800'>
                        <div
                          className={cn(
                            'm-1.5 flex flex-grow flex-col bg-blue-900',
                            {
                              'rounded-b-2xl': !closeButton
                            }
                          )}
                        >
                          {children}
                        </div>
                      </div>

                      {closeButton && (
                        <div className='relative flex flex-col'>
                          <OuterInwardDesign
                            size={ContainerSpaceWidth.OUTER_MEDIUM}
                            className='rotate-180'
                          />

                          <InnerInwardDesign
                            size={ContainerSpaceWidth.INNER_MEDIUM}
                            className='absolute bottom-0 left-0 w-full rotate-180 p-1.5'
                          />

                          <Button
                            onClick={onDismiss}
                            className='w-41.25 absolute -bottom-5.75 left-1/2 z-11 -translate-x-1/2'
                          >
                            Close
                          </Button>
                        </div>
                      )}
                    </StyledDialogContent>
                  )
              )}
            </StyledDialogOverlay>
          )
      )}
    </>
  );
}
