import useParsedQueryString from './useParsedQueryString';
import {
  DEFAULT_LOCAL_CURRENCY,
  SUPPORTED_LOCAL_CURRENCIES,
  type SupportedLocalCurrency
} from 'constants/localCurrencies';
import { useMemo } from 'react';

import { atomWithStorage, useAtomValue } from 'jotai/utils';

export const activeLocalCurrencyAtom = atomWithStorage<SupportedLocalCurrency>(
  'activeLocalCurrency',
  DEFAULT_LOCAL_CURRENCY
);

function useUrlLocalCurrency() {
  const parsed = useParsedQueryString();
  const parsedLocalCurrency = parsed.cur;

  if (typeof parsedLocalCurrency !== 'string') return undefined;

  const lowerCaseSupportedLocalCurrency = parsedLocalCurrency.toLowerCase();
  return SUPPORTED_LOCAL_CURRENCIES.find(
    (localCurrency) =>
      localCurrency.toLowerCase() === lowerCaseSupportedLocalCurrency
  );
}

export function useActiveLocalCurrency(): SupportedLocalCurrency {
  const activeLocalCurrency = useAtomValue(activeLocalCurrencyAtom);
  const urlLocalCurrency = useUrlLocalCurrency();

  return useMemo(
    () => urlLocalCurrency ?? activeLocalCurrency,
    [activeLocalCurrency, urlLocalCurrency]
  );
}
