import { ErrorModalContent } from './ErrorModalContent';
import { PendingModalContent } from './PendingModalContent';
import { SwapModalFooter } from './SwapModalFooter';
import { SwapModalHeader } from './SwapModalHeader';
import { ConfirmModalState, PendingModalError } from './constants';
import type { Currency, Percent } from '@uniswap/sdk-core';
import { getChainInfo } from 'constants/chainInfo';
import { useCallback, useEffect, useState } from 'react';

import { isPreviewTrade } from 'state/routing/utils';
import { isL2ChainId } from 'utils/chains';
import { SignatureExpiredError } from 'utils/errors';
import { didUserReject } from 'utils/swapErrorToUserReadableMessage';
import { tradeMeaningfullyDiffers } from 'utils/tradeMeaningFullyDiffer';

import { useConfirmModalState } from './hooks';
import { TransactionStatus } from 'graphql/data/__generated__/types-and-hooks';
import type { Allowance } from 'hooks/usePermit2Allowance';
import { AllowanceState } from 'hooks/usePermit2Allowance';
import type { SwapResult } from 'hooks/useSwapCallback';
import { useWeb3React } from 'hooks/useWeb3React';
import { useSwapTransactionStatus } from 'state/transactions/hooks';

import { ChainLogo } from 'components/Logo/ChainLogo';
import Modal, { MODAL_TRANSITION_DURATION } from 'components/Modal';
import { ConfirmationModalContent } from 'components/composed/Modal';
import type { Field } from 'components/composed/Swap/constants';

import type { InterfaceTrade } from 'state/routing/types';

export const ConfirmSwapModal = ({
  trade,
  inputCurrency,
  originalTrade,
  onAcceptChanges,
  allowedSlippage,
  allowance,
  clearSwapState,
  onConfirm,
  onDismiss,
  onCurrencySelection,
  swapError,
  swapResult
}: {
  trade: InterfaceTrade;
  inputCurrency?: Currency;
  originalTrade?: InterfaceTrade;
  swapResult?: SwapResult;
  allowedSlippage: Percent;
  allowance: Allowance;
  onAcceptChanges: () => void;
  clearSwapState: () => void;
  onConfirm: () => void;
  swapError?: Error;
  onDismiss: () => void;
  onCurrencySelection: (field: Field, currency: Currency) => void;
}) => {
  const { chainId } = useWeb3React();
  const doesTradeDiffer =
    originalTrade &&
    tradeMeaningfullyDiffers(originalTrade, trade, allowedSlippage);

  const {
    startSwapFlow,
    onCancel,
    confirmModalState,
    approvalError,
    pendingModalSteps,
    wrapTxHash
  } = useConfirmModalState({
    trade,
    onSwap: () => {
      clearSwapState();
      onConfirm();
    },
    onCurrencySelection,
    allowance,
    doesTradeDiffer: Boolean(doesTradeDiffer)
  });

  const swapStatus = useSwapTransactionStatus(swapResult);

  // Swap was reverted onchain.
  const swapReverted = swapStatus === TransactionStatus.Failed;
  // Swap failed locally and was not broadcast to the blockchain.
  const localSwapFailure = Boolean(swapError) && !didUserReject(swapError);
  const swapFailed = localSwapFailure || swapReverted;

  useEffect(() => {
    // Reset the modal state if the user rejected the swap.
    if (swapError && !swapFailed) {
      onCancel();
    }
  }, [onCancel, swapError, swapFailed]);

  const showAcceptChanges = Boolean(
    trade &&
      doesTradeDiffer &&
      confirmModalState !== ConfirmModalState.PENDING_CONFIRMATION
  );

  const [lastExecutionPrice, setLastExecutionPrice] = useState(
    trade?.executionPrice
  );

  useEffect(() => {
    if (
      lastExecutionPrice &&
      !trade.executionPrice.equalTo(lastExecutionPrice)
    ) {
      setLastExecutionPrice(trade.executionPrice);
    }
  }, [lastExecutionPrice, setLastExecutionPrice, trade]);

  const onModalDismiss = useCallback(() => {
    onDismiss();
    setTimeout(() => {
      // Reset local state after the modal dismiss animation finishes, to avoid UI flicker as it dismisses
      onCancel();
    }, MODAL_TRANSITION_DURATION);
  }, [onCancel, onDismiss]);

  const modalHeader = useCallback(() => {
    if (
      confirmModalState !== ConfirmModalState.REVIEWING &&
      !showAcceptChanges
    ) {
      return null;
    }
    return (
      <SwapModalHeader
        inputCurrency={inputCurrency}
        trade={trade}
        allowedSlippage={allowedSlippage}
      />
    );
  }, [
    allowedSlippage,
    confirmModalState,
    showAcceptChanges,
    trade,
    inputCurrency
  ]);

  const modalBottom = useCallback(() => {
    if (
      confirmModalState === ConfirmModalState.REVIEWING ||
      showAcceptChanges
    ) {
      return (
        <SwapModalFooter
          onConfirm={startSwapFlow}
          trade={trade}
          allowedSlippage={allowedSlippage}
          isLoading={isPreviewTrade(trade)}
          disabledConfirm={
            showAcceptChanges ||
            isPreviewTrade(trade) ||
            allowance.state === AllowanceState.LOADING
          }
          showAcceptChanges={showAcceptChanges}
          onAcceptChanges={onAcceptChanges}
          swapErrorMessage={swapFailed ? swapError?.message : undefined}
        />
      );
    }
    return (
      <PendingModalContent
        hideStepIndicators={pendingModalSteps.length === 1}
        steps={pendingModalSteps}
        currentStep={confirmModalState}
        trade={trade}
        swapResult={swapResult}
        wrapTxHash={wrapTxHash}
        tokenApprovalPending={
          allowance.state === AllowanceState.REQUIRED &&
          allowance.isApprovalPending
        }
        revocationPending={
          allowance.state === AllowanceState.REQUIRED &&
          allowance.isRevocationPending
        }
        swapError={swapError}
        onRetryUniswapXSignature={onConfirm}
      />
    );
  }, [
    confirmModalState,
    showAcceptChanges,
    pendingModalSteps,
    trade,
    swapResult,
    wrapTxHash,
    allowance,
    swapError,
    startSwapFlow,
    allowedSlippage,
    onAcceptChanges,
    swapFailed,
    onConfirm
  ]);

  const l2Badge = () => {
    if (
      isL2ChainId(chainId) &&
      confirmModalState !== ConfirmModalState.REVIEWING
    ) {
      const info = getChainInfo(chainId);
      return (
        <div className='flex items-center justify-center rounded-lg border bg-blue-800 p-2 font-medium text-gray-100'>
          <div className='flex flex-row items-center gap-1'>
            <ChainLogo chainId={chainId} size={16} />

            <span>{info.label}</span>
          </div>
        </div>
      );
    }
    return undefined;
  };

  const getErrorType = () => {
    if (approvalError) return approvalError;
    // SignatureExpiredError is a special case. The UI is shown in the PendingModalContent component.
    if (swapError instanceof SignatureExpiredError) return;
    if (swapError && !didUserReject(swapError))
      return PendingModalError.CONFIRMATION_ERROR;
    return;
  };
  const errorType = getErrorType();

  return (
    <Modal
      isOpen
      $scrollOverlay
      onDismiss={onModalDismiss}
      maxHeight={90}
      closeButton={confirmModalState !== ConfirmModalState.REVIEWING}
    >
      {errorType ? (
        <ErrorModalContent errorType={errorType} onRetry={startSwapFlow} />
      ) : (
        <ConfirmationModalContent
          title={
            confirmModalState === ConfirmModalState.REVIEWING
              ? 'Review swap'
              : undefined
          }
          onDismiss={onModalDismiss}
          topContent={modalHeader}
          bottomContent={modalBottom}
          headerContent={l2Badge}
          closeIcon={confirmModalState === ConfirmModalState.REVIEWING}
        />
      )}
    </Modal>
  );
};
