import type { SupportedInterfaceChain } from './chains';
import { RPC_URLS } from './networks';
import { ChainId } from '@uniswap/sdk-core';
import AppRpcProvider from 'rpc/AppRpcProvider';
import AppStaticJsonRpcProvider from 'rpc/StaticJsonRpcProvider';
import type StaticJsonRpcProvider from 'rpc/StaticJsonRpcProvider';

const providerFactory = (chainId: SupportedInterfaceChain, i = 0) =>
  new AppStaticJsonRpcProvider(chainId, RPC_URLS[chainId][i]);

/**
 * These are the only JsonRpcProviders used directly by the interface.
 */
export const RPC_PROVIDERS: Record<number, StaticJsonRpcProvider> = {
  [ChainId.MAINNET]: new AppRpcProvider(ChainId.MAINNET, [
    providerFactory(ChainId.MAINNET),
    providerFactory(ChainId.MAINNET, 1)
  ]),
  [ChainId.GOERLI]: providerFactory(ChainId.GOERLI),
  [ChainId.SEPOLIA]: providerFactory(ChainId.SEPOLIA),
  [ChainId.OPTIMISM]: providerFactory(ChainId.OPTIMISM),
  [ChainId.OPTIMISM_GOERLI]: providerFactory(ChainId.OPTIMISM_GOERLI),
  [ChainId.ARBITRUM_ONE]: providerFactory(ChainId.ARBITRUM_ONE),
  [ChainId.ARBITRUM_GOERLI]: providerFactory(ChainId.ARBITRUM_GOERLI),
  [ChainId.POLYGON]: providerFactory(ChainId.POLYGON),
  [ChainId.POLYGON_MUMBAI]: providerFactory(ChainId.POLYGON_MUMBAI),
  [ChainId.CELO]: providerFactory(ChainId.CELO),
  [ChainId.CELO_ALFAJORES]: providerFactory(ChainId.CELO_ALFAJORES),
  [ChainId.BNB]: providerFactory(ChainId.BNB),
  [ChainId.AVALANCHE]: providerFactory(ChainId.AVALANCHE),
  [ChainId.BASE]: providerFactory(ChainId.BASE),
  // NEBY_OASIS_CHANGES
  [ChainId.OASIS_EMERALD_MAINNET]: providerFactory(
    ChainId.OASIS_EMERALD_MAINNET
  ),
  [ChainId.OASIS_EMERALD_TESTNET]: providerFactory(
    ChainId.OASIS_EMERALD_TESTNET
  ),
  [ChainId.OASIS_SAPPHIRE_MAINNET]: providerFactory(
    ChainId.OASIS_SAPPHIRE_MAINNET
  ),
  [ChainId.OASIS_SAPPHIRE_TESTNET]: providerFactory(
    ChainId.OASIS_SAPPHIRE_TESTNET
  ),
  [ChainId.BASE_SEPOLIA_TESTNET]: providerFactory(ChainId.BASE_SEPOLIA_TESTNET)
};

export const DEPRECATED_RPC_PROVIDERS: Record<number, StaticJsonRpcProvider> = {
  [ChainId.MAINNET]: providerFactory(ChainId.MAINNET),
  [ChainId.GOERLI]: providerFactory(ChainId.GOERLI),
  [ChainId.SEPOLIA]: providerFactory(ChainId.SEPOLIA),
  [ChainId.OPTIMISM]: providerFactory(ChainId.OPTIMISM),
  [ChainId.OPTIMISM_GOERLI]: providerFactory(ChainId.OPTIMISM_GOERLI),
  [ChainId.ARBITRUM_ONE]: providerFactory(ChainId.ARBITRUM_ONE),
  [ChainId.ARBITRUM_GOERLI]: providerFactory(ChainId.ARBITRUM_GOERLI),
  [ChainId.POLYGON]: providerFactory(ChainId.POLYGON),
  [ChainId.POLYGON_MUMBAI]: providerFactory(ChainId.POLYGON_MUMBAI),
  [ChainId.CELO]: providerFactory(ChainId.CELO),
  [ChainId.CELO_ALFAJORES]: providerFactory(ChainId.CELO_ALFAJORES),
  [ChainId.BNB]: providerFactory(ChainId.BNB),
  [ChainId.AVALANCHE]: providerFactory(ChainId.AVALANCHE),
  [ChainId.BASE]: providerFactory(ChainId.BASE),
  [ChainId.OASIS_EMERALD_MAINNET]: providerFactory(
    ChainId.OASIS_EMERALD_MAINNET
  ),
  [ChainId.OASIS_EMERALD_TESTNET]: providerFactory(
    ChainId.OASIS_EMERALD_TESTNET
  ),
  [ChainId.OASIS_SAPPHIRE_MAINNET]: providerFactory(
    ChainId.OASIS_SAPPHIRE_MAINNET
  ),
  [ChainId.OASIS_SAPPHIRE_TESTNET]: providerFactory(
    ChainId.OASIS_SAPPHIRE_TESTNET
  ),
  [ChainId.BASE_SEPOLIA_TESTNET]: providerFactory(ChainId.BASE_SEPOLIA_TESTNET)
};
