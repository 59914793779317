import StatusIcon from '../Identicon/StatusIcon';
import { useCachedPortfolioBalancesQuery } from '../PrefetchBalancesWrapper/PrefetchBalancesWrapper';
import IconButton, {
  IconHoverText,
  IconWithConfirmTextButton
} from './IconButton';
import MiniPortfolio from './MiniPortfolio';
import { portfolioFadeInAnimation } from './MiniPortfolio/PortfolioRow';
import type { CurrencyAmount, Token } from '@uniswap/sdk-core';
import { getConnection } from 'connection';
import { useCallback, useState } from 'react';

import { shortenAddress } from 'utils';
import { NumberType, useFormatter } from 'utils/formatNumbers';

import { useToggleModal } from '../../state/application/hooks';
import {
  useUserHasAvailableClaim,
  useUserUnclaimedAmount
} from '../../state/claim/hooks';
import useENSName from 'hooks/useENSName';
import { useWeb3React } from 'hooks/useWeb3React';
import { useIsNftClaimAvailable } from 'nft/hooks/useIsNftClaimAvailable';
import { useAppDispatch } from 'state/hooks';

import { ButtonEmphasis, ButtonSize, ThemeButton } from 'components/Button';
import Column from 'components/Column';
import { Power } from 'components/Icons/Power';
import { SettingsIcon } from 'components/Icons/SettingsIcon';
import { AutoRow } from 'components/Row';
import { DeltaArrow } from 'components/Tokens/TokenDetails/Delta';
import { LoadingBubble } from 'components/Tokens/loading';
import styled from 'styled-components';
import { CopyHelper, ThemedText } from 'theme/components';

import { ApplicationModal } from '../../state/application/reducer';
import { setRecentConnectionDisconnected } from 'state/user/reducer';

const AuthenticatedHeaderWrapper = styled.div`
  padding: 20px 16px;
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const WalletButton = styled(ThemeButton)`
  border-radius: 12px;
  padding-top: 10px;
  padding-bottom: 10px;
  margin-top: 4px;
  color: white;
  border: none;
`;

const UNIButton = styled(WalletButton)`
  border-radius: 12px;
  padding-top: 10px;
  padding-bottom: 10px;
  margin-top: 4px;
  color: white;
  border: none;
  background: linear-gradient(to right, #9139b0 0%, #4261d6 100%);
`;

const IconContainer = styled.div`
  display: flex;
  align-items: center;
  & > a,
  & > button {
    margin-right: 8px;
  }

  & > button:last-child {
    margin-right: 0px;
    ${IconHoverText}:last-child {
      left: 0px;
    }
  }
`;

const StatusWrapper = styled.div`
  display: inline-block;
  width: 70%;
  max-width: 70%;
  padding-right: 8px;
  display: inline-flex;
`;

const AccountNamesWrapper = styled.div`
  overflow: hidden;
  white-space: nowrap;
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  margin-left: 8px;
`;

const HeaderWrapper = styled.div`
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
`;

const CopyText = styled(CopyHelper).attrs({
  iconSize: 14,
  iconPosition: 'right'
})``;

const FadeInColumn = styled(Column)`
  ${portfolioFadeInAnimation}
`;

const PortfolioDrawerContainer = styled(Column)`
  flex: 1;
`;

export default function AuthenticatedHeader({
  account,
  openSettings
}: {
  account: string;
  openSettings: () => void;
}) {
  const { connector } = useWeb3React();
  const { ENSName } = useENSName(account);
  const dispatch = useAppDispatch();
  const isClaimAvailable = useIsNftClaimAvailable(
    (state) => state.isClaimAvailable
  );
  const { formatNumber, formatDelta } = useFormatter();

  const unclaimedAmount: CurrencyAmount<Token> | undefined =
    useUserUnclaimedAmount(account);
  const isUnclaimed = useUserHasAvailableClaim(account);
  const connection = getConnection(connector);
  const openClaimModal = useToggleModal(ApplicationModal.ADDRESS_CLAIM);
  const openNftModal = useToggleModal(
    ApplicationModal.UNISWAP_NFT_AIRDROP_CLAIM
  );
  const disconnect = useCallback(() => {
    connector.deactivate?.();
    connector.resetState();
    dispatch(setRecentConnectionDisconnected());
  }, [connector, dispatch]);

  const { data: portfolioBalances } = useCachedPortfolioBalancesQuery({
    account
  });
  const portfolio = portfolioBalances?.portfolios?.[0];
  const totalBalance = portfolio?.tokensTotalDenominatedValue?.value;
  const absoluteChange =
    portfolio?.tokensTotalDenominatedValueChange?.absolute?.value;
  const percentChange =
    portfolio?.tokensTotalDenominatedValueChange?.percentage?.value;
  const [showDisconnectConfirm, setShowDisconnectConfirm] = useState(false);

  return (
    <AuthenticatedHeaderWrapper>
      <HeaderWrapper>
        <StatusWrapper>
          <StatusIcon account={account} connection={connection} size={40} />
          {account && (
            <AccountNamesWrapper>
              <ThemedText.SubHeader>
                <CopyText toCopy={ENSName ?? account}>
                  {ENSName ?? shortenAddress(account)}
                </CopyText>
              </ThemedText.SubHeader>
              {/* Displays smaller view of account if ENS name was rendered above */}
              {ENSName && (
                <ThemedText.BodySmall color='neutral2'>
                  <CopyText toCopy={account}>
                    {shortenAddress(account)}
                  </CopyText>
                </ThemedText.BodySmall>
              )}
            </AccountNamesWrapper>
          )}
        </StatusWrapper>
        <IconContainer>
          <IconButton
            hideHorizontal={showDisconnectConfirm}
            data-testid='wallet-settings'
            onClick={openSettings}
            Icon={SettingsIcon}
            iconClassName='size-6 stroke-gray-600'
          />
          <IconWithConfirmTextButton
            data-testid='wallet-disconnect'
            onConfirm={disconnect}
            onShowConfirm={setShowDisconnectConfirm}
            Icon={Power}
            text='Disconnect'
            dismissOnHoverOut
          />
        </IconContainer>
      </HeaderWrapper>
      <PortfolioDrawerContainer>
        {totalBalance !== undefined ? (
          <FadeInColumn gap='xs'>
            <ThemedText.HeadlineLarge
              fontWeight={535}
              data-testid='portfolio-total-balance'
            >
              {formatNumber({
                input: totalBalance,
                type: NumberType.PortfolioBalance
              })}
            </ThemedText.HeadlineLarge>
            <AutoRow marginBottom='20px'>
              {absoluteChange !== 0 && percentChange && (
                <>
                  <DeltaArrow delta={absoluteChange} />
                  <ThemedText.BodySecondary>
                    {`${formatNumber({
                      input: Math.abs(absoluteChange as number),
                      type: NumberType.PortfolioBalance
                    })} (${formatDelta(percentChange)})`}
                  </ThemedText.BodySecondary>
                </>
              )}
            </AutoRow>
          </FadeInColumn>
        ) : (
          <Column gap='xs'>
            <LoadingBubble height='44px' width='170px' />
            <LoadingBubble height='16px' width='100px' margin='4px 0 20px 0' />
          </Column>
        )}
        <MiniPortfolio account={account} />
        {isUnclaimed && (
          <UNIButton
            onClick={openClaimModal}
            size={ButtonSize.medium}
            emphasis={ButtonEmphasis.medium}
          >
            {`Claim ${unclaimedAmount?.toFixed(0, { groupSeparator: ',' } ?? '-')} reward`}
          </UNIButton>
        )}
        {isClaimAvailable && (
          <UNIButton
            size={ButtonSize.medium}
            emphasis={ButtonEmphasis.medium}
            onClick={openNftModal}
          >
            Claim Uniswap NFT Airdrop
          </UNIButton>
        )}
      </PortfolioDrawerContainer>
    </AuthenticatedHeaderWrapper>
  );
}
