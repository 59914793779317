// High-traffic pages (index and /swap) should not be lazy-loaded.
import Landing from './Landing';
import Swap from './Swap';
import { getDefaultTokensTitle, getExploreTitle } from './paths';
import { useInfoExplorePageEnabled } from 'featureFlags/flags/infoExplore';
import { useInfoPoolPageEnabled } from 'featureFlags/flags/infoPoolPage';
import type { ReactNode } from 'react';
import { Suspense, lazy, useMemo } from 'react';
import { Navigate, matchPath, useLocation } from 'react-router-dom';

import { isBrowserRouterEnabled } from 'utils/env';
import { routes as routeList } from 'utils/routes';

import { SpinnerSVG } from 'theme/components';

const Explore = lazy(() => import('pages/Explore'));
const AddLiquidityWithTokenRedirects = lazy(
  () => import('pages/AddLiquidity/redirects')
);
const AddLiquidityV2WithTokenRedirects = lazy(
  () => import('pages/AddLiquidityV2/redirects')
);

const Bridge = lazy(() => import('pages/Bridge'));
const RedirectExplore = lazy(() => import('pages/Explore/redirects'));
const MigrateV2 = lazy(() => import('pages/MigrateV2'));
const MigrateV2Pair = lazy(() => import('pages/MigrateV2/MigrateV2Pair'));
const NotFound = lazy(() => import('pages/NotFound'));
const Pool = lazy(() => import('pages/Pool'));
const PoolV2 = lazy(() => import('pages/Pool/v2'));
const PoolDetails = lazy(() => import('pages/PoolDetails'));
const MyPosition = lazy(() => import('pages/MyPosition'));
const PoolFinder = lazy(() => import('pages/PoolFinder'));
const RemoveLiquidity = lazy(() => import('pages/RemoveLiquidity'));
const Stake = lazy(() => import('pages/Stake'));
const TokenDetails = lazy(() => import('pages/TokenDetails'));
const Vote = lazy(() => import('pages/Vote'));

// this is the same svg defined in assets/images/blue-loader.svg
// it is defined here because the remote asset may not have had time to load when this file is executing
const LazyLoadSpinner = () => (
  <SpinnerSVG
    width='94'
    height='94'
    viewBox='0 0 94 94'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M92 47C92 22.1472 71.8528 2 47 2C22.1472 2 2 22.1472 2 47C2 71.8528 22.1472 92 47 92'
      stroke='#2172E5'
      strokeWidth='3'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </SpinnerSVG>
);

interface RouterConfig {
  browserRouterEnabled?: boolean;
  hash?: string;
  infoExplorePageEnabled?: boolean;
  infoPoolPageEnabled?: boolean;
}

/**
 * Convenience hook which organizes the router configuration into a single object.
 */
export function useRouterConfig(): RouterConfig {
  const browserRouterEnabled = isBrowserRouterEnabled();
  const { hash } = useLocation();
  const infoPoolPageEnabled = useInfoPoolPageEnabled();
  const infoExplorePageEnabled = useInfoExplorePageEnabled();

  return useMemo(
    () => ({
      browserRouterEnabled,
      hash,
      infoExplorePageEnabled,
      infoPoolPageEnabled
    }),
    [browserRouterEnabled, hash, infoExplorePageEnabled, infoPoolPageEnabled]
  );
}

export interface RouteDefinition {
  path: string;
  nestedPaths: string[];
  getTitle: (path?: string) => string;
  enabled: (args: RouterConfig) => boolean;
  getElement: (args: RouterConfig) => ReactNode;
}

// Assigns the defaults to the route definition.
function createRouteDefinition(
  route: Partial<RouteDefinition>
): RouteDefinition {
  return {
    getElement: () => null,
    getTitle: () => 'Uniswap Interface',
    enabled: () => true,
    path: '/',
    nestedPaths: [],
    // overwrite the defaults
    ...route
  };
}

export const routes: RouteDefinition[] = [
  createRouteDefinition({
    path: routeList.home,
    getTitle: () =>
      'Uniswap | Trade crypto & NFTs safely on the top DeFi exchange',
    getElement: (args) =>
      args.browserRouterEnabled && args.hash ? (
        <Navigate to={args.hash.replace('#', '')} replace />
      ) : (
        <Landing />
      )
  }),
  createRouteDefinition({
    path: routeList.bridge,
    getTitle: () => 'Bridge Assets to Oasis on Neby',
    getElement: () => (
      <Suspense fallback={null}>
        <Bridge />
      </Suspense>
    )
  }),
  createRouteDefinition({
    path: '/explore',
    getTitle: getExploreTitle,
    nestedPaths: [':tab', ':chainName'],
    getElement: () => <RedirectExplore />,
    enabled: (args) => Boolean(args.infoExplorePageEnabled)
  }),
  createRouteDefinition({
    path: '/explore',
    getTitle: getExploreTitle,
    nestedPaths: [':tab/:chainName'],
    getElement: () => <Explore />,
    enabled: (args) => Boolean(args.infoExplorePageEnabled)
  }),
  createRouteDefinition({
    path: '/explore/tokens/:chainName/:tokenAddress',
    getTitle: () => 'Buy & Sell on Uniswap',
    getElement: () => <TokenDetails />,
    enabled: (args) => Boolean(args.infoExplorePageEnabled)
  }),
  createRouteDefinition({
    path: '/tokens',
    getTitle: getDefaultTokensTitle,
    getElement: (args) =>
      args.infoExplorePageEnabled ? (
        <Navigate to='/explore/tokens' replace />
      ) : (
        <Explore />
      )
  }),
  createRouteDefinition({
    path: '/tokens/:chainName',
    getTitle: getDefaultTokensTitle,
    getElement: (args) =>
      args.infoExplorePageEnabled ? <RedirectExplore /> : <Explore />
  }),
  createRouteDefinition({
    path: '/tokens/:chainName/:tokenAddress',
    getTitle: getDefaultTokensTitle,
    getElement: (args) =>
      args.infoExplorePageEnabled ? <RedirectExplore /> : <TokenDetails />
  }),
  createRouteDefinition({
    path: '/explore/pools/:chainName/:poolAddress',
    getTitle: () => 'Explore Pools on Uniswap',
    getElement: () => (
      <Suspense fallback={null}>
        <PoolDetails />
      </Suspense>
    ),
    enabled: (args) =>
      Boolean(args.infoExplorePageEnabled && args.infoPoolPageEnabled)
  }),
  createRouteDefinition({
    path: '/vote/*',
    getTitle: () => 'Vote on Governance Proposals on Uniswap',
    getElement: () => (
      <Suspense fallback={<LazyLoadSpinner />}>
        <Vote />
      </Suspense>
    )
  }),
  createRouteDefinition({
    path: '/create-proposal',
    getTitle: () => 'Create a new Governance Proposal on Uniswap',
    getElement: () => <Navigate to='/vote/create-proposal' replace />
  }),
  createRouteDefinition({
    path: '/swap',
    getElement: () => <Swap />,
    getTitle: () => 'Buy, Sell & Trade Ethereum and Other Top Tokens on Uniswap'
  }),
  createRouteDefinition({
    path: '/pool/v2/find',
    getElement: () => <PoolFinder />,
    getTitle: () => 'Explore Top Liquidity Pools (v2) on Uniswap'
  }),
  createRouteDefinition({
    path: '/pool/v2',
    getElement: () => <PoolV2 />,
    getTitle: () => 'Provide Liquidity to Pools (v2) on Uniswap'
  }),
  createRouteDefinition({ path: '/pool', getElement: () => <Pool /> }),
  createRouteDefinition({
    path: '/pool/:address',
    getTitle: () => 'Explore Pools',
    getElement: () => (
      <Suspense fallback={null}>
        <PoolDetails />
      </Suspense>
    )
  }),
  createRouteDefinition({
    path: '/pools/v2/find',
    getElement: () => <PoolFinder />,
    getTitle: () => 'Explore Top Liquidity Pools (v2) on Uniswap'
  }),
  createRouteDefinition({
    path: '/pools/v2',
    getElement: () => <PoolV2 />,
    getTitle: () => 'Manage & Provide v2 Pool Liquidity on Uniswap'
  }),
  createRouteDefinition({
    path: routeList.pools,
    getElement: () => <Pool />,
    getTitle: () => 'Manage & Provide Pool Liquidity on Uniswap'
  }),
  createRouteDefinition({
    path: '/pools/:address',
    getTitle: () => 'Explore Pools',
    getElement: () => (
      <Suspense fallback={null}>
        <PoolDetails />
      </Suspense>
    )
  }),
  createRouteDefinition({
    path: '/add/v2',
    nestedPaths: [':currencyIdA', ':currencyIdA/:currencyIdB'],
    getElement: () => <AddLiquidityV2WithTokenRedirects />,
    getTitle: () => 'Provide Liquidity to Pools (v2) on Uniswap'
  }),
  createRouteDefinition({
    path: '/add-liquidity',
    nestedPaths: [
      ':currencyIdA',
      ':currencyIdA/:currencyIdB',
      ':currencyIdA/:currencyIdB/:feeAmount',
      ':currencyIdA/:currencyIdB/:feeAmount/:tokenId'
    ],
    getElement: () => <AddLiquidityWithTokenRedirects />,
    getTitle: () => 'Provide Liquidity to Pools'
  }),
  createRouteDefinition({
    path: '/my-position/:tokenId',
    getTitle: () => 'My position',
    getElement: () => (
      <Suspense fallback={null}>
        <MyPosition />
      </Suspense>
    )
  }),
  createRouteDefinition({
    path: '/remove-liquidity/:tokenId',
    getElement: () => <RemoveLiquidity />,
    getTitle: () => 'Manage Pool Liquidity'
  }),
  createRouteDefinition({
    path: '/stake',
    nestedPaths: [
      ':currencyIdA/:currencyIdB',
      ':currencyIdA/:currencyIdB/:tokenId'
    ],
    getElement: () => <Stake />,
    getTitle: () => 'Stake in Cosmic'
  }),
  createRouteDefinition({
    path: '/migrate/v2',
    getElement: () => <MigrateV2 />,
    getTitle: () => 'Migrate v2 Pool Liquidity to Uniswap v3'
  }),
  createRouteDefinition({
    path: '/migrate/v2/:address',
    getElement: () => <MigrateV2Pair />,
    getTitle: () => 'Migrate v2 Pool Liquidity to Uniswap v3'
  }),
  createRouteDefinition({
    path: '*',
    getElement: () => <Navigate to='/not-found' replace />
  }),
  createRouteDefinition({ path: '/not-found', getElement: () => <NotFound /> })
];

export const findRouteByPath = (pathname: string) => {
  for (const route of routes) {
    const match = matchPath(route.path, pathname);
    if (match) {
      return route;
    }
    const subPaths = route.nestedPaths.map(
      (nestedPath) => `${route.path}/${nestedPath}`
    );
    for (const subPath of subPaths) {
      const match = matchPath(subPath, pathname);
      if (match) {
        return route;
      }
    }
  }
  return undefined;
};
