import { atomWithStorage } from 'jotai/utils';

export {
  type ITraceContext,
  getDeviceId,
  initializeAnalytics,
  OriginApplication,
  user
} from '@uniswap/analytics';

const allowAnalyticsAtomKey = 'allow_analytics';
export const allowAnalyticsAtom = atomWithStorage<boolean>(
  allowAnalyticsAtomKey,
  true
);
