import { type ForwardedRef, forwardRef } from 'react';
import { Z_INDEX } from 'theme/zIndex';

import { cn } from 'utils/cn';

import { Box, type BoxProps } from 'nft/components/Box';

export const NavDropdown = forwardRef(
  (props: BoxProps, ref: ForwardedRef<HTMLElement>) => (
    <Box
      ref={ref}
      style={{ zIndex: Z_INDEX.modal }}
      className={cn(
        'border-none bg-blue-800 py-2 shadow-lg',
        'fixed bottom-10 left-0 right-0 w-full rounded-t-xl',
        'md:absolute md:h-fit md:w-fit md:rounded-xl'
      )}
      {...props}
    />
  )
);

NavDropdown.displayName = 'NavDropdown';
