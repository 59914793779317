import { GasBreakdownTooltip } from './GasBreakdownTooltip';
import { MaxSlippageTooltip } from './MaxSlippageTooltip';
import { RoutingTooltip } from './RoutingTooltip';
import { FOTTooltipContent } from './SwapLineItem';
import { SwapRoute } from './SwapRoute';
import { TradePrice } from './TradePrice';
import { TradeSummary } from './TradeSummary';
import { TransitionText } from './TransitionText';
import {
  ChainId,
  type Currency,
  CurrencyAmount,
  type Percent,
  TradeType
} from '@uniswap/sdk-core';
import { SUPPORTED_GAS_ESTIMATE_CHAIN_IDS } from 'constants/chains';
import { useFeesEnabled } from 'featureFlags/flags/useFees';
import { useCallback, useEffect, useMemo, useState } from 'react';
import invariant from 'tiny-invariant';

import { isPreviewTrade } from 'state/routing/utils';
import { cn } from 'utils/cn';
import { SignatureExpiredError } from 'utils/errors';
import { NumberType, useFormatter } from 'utils/formatNumbers';
import { ExplorerDataType, getExplorerLink } from 'utils/getExplorerLink';
import { getPriceImpactWarning } from 'utils/prices';
import { didUserReject } from 'utils/swapErrorToUserReadableMessage';

import { type Allowance, AllowanceState } from 'hooks/usePermit2Allowance';
import usePrevious from 'hooks/usePrevious';
import type { SwapResult } from 'hooks/useSwapCallback';
import { useUSDPrice } from 'hooks/useUSDPrice';
import { useWeb3React } from 'hooks/useWeb3React';
import useWrapCallback from 'hooks/useWrapCallback';
import useNativeCurrency from 'lib/hooks/useNativeCurrency';
import { useIsTransactionConfirmed } from 'state/transactions/hooks';
import { useUserSlippageTolerance } from 'state/user/hooks';

import { ChainLogo } from 'components/Logo/ChainLogo';
import RouterLabel from 'components/RouterLabel';
import { TooltipSize } from 'components/Tooltip';
import { ExternalLink } from 'components/composed/ExternalLink';
import {
  ConfirmModalState,
  PendingModalError,
  RESET_APPROVAL_TOKENS,
  SwapLineItemType
} from 'components/composed/Modal/Swap/constants';
import type {
  LineItemData,
  PendingConfirmModalState,
  PendingModalStep,
  SwapLineItemProps
} from 'components/composed/Modal/Swap/types';
import { Field } from 'components/composed/Swap/constants';

import {
  type InterfaceTrade,
  type SubmittableTrade,
  TradeFillType
} from 'state/routing/types';
import type { UniswapXOrderDetails } from 'state/signatures/types';
import { SlippageTolerance } from 'state/user/types';

interface ContentArgs {
  approvalCurrency?: Currency;
  trade?: InterfaceTrade;
  swapConfirmed: boolean;
  swapPending: boolean;
  wrapPending: boolean;
  tokenApprovalPending: boolean;
  revocationPending: boolean;
  swapResult?: SwapResult;
  chainId?: number;
  order?: UniswapXOrderDetails;
  swapError?: Error | string;
  onRetryUniswapXSignature?: () => void;
}

const getPendingConfirmationContent = ({
  swapConfirmed,
  swapPending,
  trade,
  chainId,
  swapResult,
  swapError,
  onRetryUniswapXSignature
}: Pick<
  ContentArgs,
  | 'swapConfirmed'
  | 'swapPending'
  | 'trade'
  | 'chainId'
  | 'swapResult'
  | 'swapError'
  | 'onRetryUniswapXSignature'
>): PendingModalStep => {
  const title = swapPending
    ? 'Swap submitted'
    : swapConfirmed
      ? 'Swap successful'
      : 'Confirm Swap';

  const tradeSummary = trade ? <TradeSummary trade={trade} /> : null;

  if (swapPending && trade?.fillType === TradeFillType.UniswapX) {
    return {
      title,
      subtitle: tradeSummary,
      bottomLabel: (
        <ExternalLink
          href='https://support.uniswap.org/hc/en-us/articles/17515415311501'
          className={cn(
            'text-blue-200 transition-colors duration-300',
            'hover:text-blue-200/80'
          )}
        >
          Learn more about swapping with UniswapX
        </ExternalLink>
      )
    };
  } else if (
    (swapPending || swapConfirmed) &&
    chainId &&
    swapResult?.type === TradeFillType.Classic
  ) {
    const explorerLink = (
      <ExternalLink
        href={getExplorerLink({
          chainId,
          data: swapResult.response.hash,
          type: ExplorerDataType.TRANSACTION
        })}
        className={cn(
          'text-blue-200 transition-colors duration-300',
          'hover:text-blue-200/80'
        )}
      >
        View on Explorer
      </ExternalLink>
    );
    if (swapPending) {
      // On Mainnet, we show a "submitted" state while the transaction is pending confirmation.
      return {
        title,
        subtitle: chainId === ChainId.MAINNET ? explorerLink : tradeSummary,
        bottomLabel:
          chainId === ChainId.MAINNET ? 'Transaction pending...' : explorerLink
      };
    } else {
      return {
        title,
        subtitle: tradeSummary,
        bottomLabel: explorerLink
      };
    }
  } else if (swapError instanceof SignatureExpiredError) {
    return {
      title: (
        <TransitionText
          key={swapError.id}
          initialText='Time expired'
          transitionText='Retry confirmation'
          onTransition={onRetryUniswapXSignature}
        />
      ),
      subtitle: tradeSummary,
      bottomLabel: 'Proceed in your wallet'
    };
  } else {
    return {
      title,
      subtitle: tradeSummary,
      bottomLabel: 'Proceed in your wallet'
    };
  }
};

export const useStepContents = (
  args: ContentArgs
): Record<PendingConfirmModalState, PendingModalStep> => {
  const {
    wrapPending,
    approvalCurrency,
    swapConfirmed,
    swapPending,
    tokenApprovalPending,
    revocationPending,
    trade,
    swapResult,
    chainId,
    swapError,
    onRetryUniswapXSignature
  } = args;

  const stepContents = useMemo(
    () => ({
      [ConfirmModalState.WRAPPING]: {
        title: 'Wrap ETH',
        subtitle: (
          <ExternalLink
            href='https://support.uniswap.org/hc/en-us/articles/16015852009997'
            className={cn(
              'text-blue-200 transition-colors duration-300',
              'hover:text-blue-200/80'
            )}
          >
            Why is this required?
          </ExternalLink>
        ),
        bottomLabel: wrapPending ? 'Pending...' : 'Proceed in your wallet'
      },
      [ConfirmModalState.RESETTING_TOKEN_ALLOWANCE]: {
        title: `Reset ${approvalCurrency?.symbol}`,
        subtitle: `${approvalCurrency?.symbol} requires resetting approval when spending limits are too low.`,
        bottomLabel: revocationPending ? 'Pending...' : 'Proceed in your wallet'
      },
      [ConfirmModalState.APPROVING_TOKEN]: {
        title: `Enable spending ${approvalCurrency?.symbol ?? 'this token'} on Uniswap`,
        subtitle: (
          <ExternalLink
            href='https://support.uniswap.org/hc/en-us/articles/8120520483085'
            className={cn(
              'text-blue-200 transition-colors duration-300',
              'hover:text-blue-200/80'
            )}
          >
            Why is this required?
          </ExternalLink>
        ),
        bottomLabel: tokenApprovalPending
          ? 'Pending...'
          : 'Proceed in your wallet'
      },
      [ConfirmModalState.PERMITTING]: {
        title: `Allow ${approvalCurrency?.symbol ?? 'this token'} to be used for swapping`,
        subtitle: (
          <ExternalLink
            href='https://support.uniswap.org/hc/en-us/articles/8120520483085'
            className={cn(
              'text-blue-200 transition-colors duration-300',
              'hover:text-blue-200/80'
            )}
          >
            Why is this required?
          </ExternalLink>
        ),
        bottomLabel: 'Proceed in your wallet'
      },
      [ConfirmModalState.PENDING_CONFIRMATION]: getPendingConfirmationContent({
        chainId,
        swapConfirmed,
        swapPending,
        swapResult,
        trade,
        swapError,
        onRetryUniswapXSignature
      })
    }),
    [
      approvalCurrency?.symbol,
      chainId,
      revocationPending,
      swapConfirmed,
      swapPending,
      swapResult,
      tokenApprovalPending,
      trade,
      wrapPending,
      swapError,
      onRetryUniswapXSignature
    ]
  );

  return stepContents;
};

const SwapFeeTooltipContent = ({ hasFee }: { hasFee: boolean }) => {
  const message = hasFee
    ? 'This fee is applied on select token pairs to ensure the best experience with Uniswap. It is paid in the output token and has already been factored into the quote.'
    : 'This fee is applied on select token pairs to ensure the best experience with Uniswap. There is no fee associated with this swap.';

  return (
    <>
      {message}{' '}
      <ExternalLink
        href='https://support.uniswap.org/hc/en-us/articles/20131678274957'
        className={cn(
          'text-blue-200 transition-colors duration-300',
          'hover:text-blue-200/80'
        )}
      >
        Learn more
      </ExternalLink>
    </>
  );
};

const getPriceImpactColor = (priceImpact: Percent) => {
  switch (getPriceImpactWarning(priceImpact)) {
    case 'error':
      return 'text-red';
    case 'warning':
      return 'text-yellow';
    default:
      return 'text-gray-600';
  }
};

const ColoredPercentRow = ({
  percent,
  estimate
}: {
  percent: Percent;
  estimate?: boolean;
}) => {
  const { formatPercent } = useFormatter();
  const formattedPercent = (estimate ? '~' : '') + formatPercent(percent);

  return (
    <span className={getPriceImpactColor(percent)}>{formattedPercent}</span>
  );
};

const CurrencyAmountRow = ({
  amount
}: {
  amount: CurrencyAmount<Currency>;
}) => {
  const { formatCurrencyAmount } = useFormatter();
  const formattedAmount = formatCurrencyAmount({
    amount,
    type: NumberType.SwapDetailsAmount
  });
  return <>{`${formattedAmount} ${amount.currency.symbol}`}</>;
};

const FeeRow = ({
  trade: { swapFee, outputAmount }
}: {
  trade: SubmittableTrade;
}) => {
  const { formatNumber } = useFormatter();

  const feeCurrencyAmount = CurrencyAmount.fromRawAmount(
    outputAmount.currency,
    swapFee?.amount ?? 0
  );
  const { data: outputFeeFiatValue } = useUSDPrice(
    feeCurrencyAmount,
    feeCurrencyAmount?.currency
  );

  // Fallback to displaying token amount if fiat value is not available
  if (outputFeeFiatValue === undefined)
    return <CurrencyAmountRow amount={feeCurrencyAmount} />;

  return (
    <>
      {formatNumber({
        input: outputFeeFiatValue,
        type: NumberType.FiatGasPrice
      })}
    </>
  );
};

const getFOTLineItem = ({
  type,
  trade
}: SwapLineItemProps): LineItemData | undefined => {
  const isInput = type === SwapLineItemType.INPUT_TOKEN_FEE_ON_TRANSFER;
  const currency = isInput
    ? trade.inputAmount.currency
    : trade.outputAmount.currency;
  const tax = isInput ? trade.inputTax : trade.outputTax;
  if (tax.equalTo(0)) return;

  return {
    Label: () => <>{`${currency.symbol ?? currency.name ?? 'Token'} fee`}</>,
    TooltipBody: FOTTooltipContent,
    Value: () => <ColoredPercentRow percent={tax} />
  };
};

const Loading = () => (
  <div className='h-4 animate-shimmer rounded-lg bg-gradient-to-l from-blue-800 via-blue-900 to-blue-800 bg-400% will-change-bg-position' />
);

export const useLineItem = (
  props: SwapLineItemProps
): LineItemData | undefined => {
  const { trade, syncing, allowedSlippage, type } = props;
  const { formatNumber, formatPercent } = useFormatter();
  const isAutoSlippage =
    useUserSlippageTolerance()[0] === SlippageTolerance.Auto;
  const feesEnabled = useFeesEnabled();

  const isPreview = isPreviewTrade(trade);
  const chainId = trade.inputAmount.currency.chainId;

  // Tracks the latest submittable trade's fill type, used to 'guess' whether or not to show price impact during preview
  const [lastSubmittableFillType, setLastSubmittableFillType] =
    useState<TradeFillType>();

  useEffect(() => {
    if (trade.fillType !== TradeFillType.None)
      setLastSubmittableFillType(trade.fillType);
  }, [trade.fillType]);

  switch (type) {
    case SwapLineItemType.EXCHANGE_RATE:
      return {
        Label: () => <>Rate</>,
        Value: () => <TradePrice price={trade.executionPrice} />,
        TooltipBody: !isPreview
          ? () => <RoutingTooltip trade={trade} />
          : undefined,
        tooltipSize: TooltipSize.Large
      };
    case SwapLineItemType.NETWORK_COST:
      if (!SUPPORTED_GAS_ESTIMATE_CHAIN_IDS.includes(chainId)) return;
      return {
        Label: () => <>Network cost</>,
        TooltipBody: () => <GasBreakdownTooltip trade={trade} />,
        Value: () => {
          if (isPreview) return <Loading />;
          return (
            <div className='flex flex-row gap-1'>
              <ChainLogo chainId={chainId} />

              {formatNumber({
                input: trade.totalGasUseEstimateUSD,
                type: NumberType.FiatGasPrice
              })}
            </div>
          );
        }
      };
    case SwapLineItemType.PRICE_IMPACT:
      // Hides price impact row if the current trade is UniswapX or we're expecting a preview trade to result in UniswapX
      if (isPreview && lastSubmittableFillType === TradeFillType.UniswapX)
        return;
      return {
        Label: () => <>Price impact</>,
        TooltipBody: () => (
          <>The impact your trade has on the market price of this pool.</>
        ),
        Value: () =>
          isPreview ? (
            <Loading />
          ) : (
            // @ts-expect-error priceImpact is not a number
            <ColoredPercentRow percent={trade.priceImpact} estimate />
          )
      };
    case SwapLineItemType.MAX_SLIPPAGE:
      return {
        Label: () => <>Max. slippage</>,
        TooltipBody: () => <MaxSlippageTooltip {...props} />,
        Value: () => (
          <div className='flex flex-row gap-2'>
            {isAutoSlippage && (
              <div className='h-5 rounded-lg bg-gray-600 py-1.5 text-gray-100' />
            )}{' '}
            {formatPercent(allowedSlippage)}
          </div>
        )
      };
    case SwapLineItemType.SWAP_FEE: {
      if (!feesEnabled) return;
      if (isPreview) return { Label: () => <>Fee</>, Value: () => <Loading /> };
      return {
        Label: () => (
          <>
            Fee {trade.swapFee && `(${formatPercent(trade.swapFee.percent)})`}
          </>
        ),
        TooltipBody: () => (
          <SwapFeeTooltipContent hasFee={Boolean(trade.swapFee)} />
        ),
        Value: () => <FeeRow trade={trade} />
      };
    }
    case SwapLineItemType.MAXIMUM_INPUT:
      if (trade.tradeType === TradeType.EXACT_INPUT) return;
      return {
        Label: () => <>Pay at most</>,
        TooltipBody: () => (
          <>
            The maximum amount you are guaranteed to spend. If the price slips
            any further, your transaction will revert.
          </>
        ),
        Value: () => (
          <CurrencyAmountRow amount={trade.maximumAmountIn(allowedSlippage)} />
        ),
        loaderWidth: 70
      };
    case SwapLineItemType.MINIMUM_OUTPUT:
      if (trade.tradeType === TradeType.EXACT_OUTPUT) return;
      return {
        Label: () => <>Receive at least</>,
        TooltipBody: () => (
          <>
            The minimum amount you are guaranteed to receive. If the price slips
            any further, your transaction will revert.
          </>
        ),
        Value: () => (
          <CurrencyAmountRow amount={trade.minimumAmountOut(allowedSlippage)} />
        ),
        loaderWidth: 70
      };
    case SwapLineItemType.ROUTING_INFO:
      if (isPreview || syncing)
        return {
          Label: () => <>Order routing</>,
          Value: () => <Loading />
        };
      return {
        Label: () => <>Order routing</>,
        TooltipBody: () => (
          // @ts-expect-error trade is not a submittable trade
          <SwapRoute data-testid='swap-route-info' trade={trade} />
        ),
        tooltipSize: TooltipSize.Large,
        Value: () => <RouterLabel trade={trade} />
      };
    case SwapLineItemType.INPUT_TOKEN_FEE_ON_TRANSFER:
    case SwapLineItemType.OUTPUT_TOKEN_FEE_ON_TRANSFER:
      return getFOTLineItem(props);
  }
};

const isInApprovalPhase = (confirmModalState: ConfirmModalState) =>
  confirmModalState === ConfirmModalState.RESETTING_TOKEN_ALLOWANCE ||
  confirmModalState === ConfirmModalState.APPROVING_TOKEN ||
  confirmModalState === ConfirmModalState.PERMITTING;

export const useConfirmModalState = ({
  trade,
  onSwap,
  allowance,
  doesTradeDiffer,
  onCurrencySelection
}: {
  trade: InterfaceTrade;
  onSwap: () => void;
  allowance: Allowance;
  doesTradeDiffer: boolean;
  onCurrencySelection: (field: Field, currency: Currency) => void;
}) => {
  const [confirmModalState, setConfirmModalState] = useState<ConfirmModalState>(
    ConfirmModalState.REVIEWING
  );
  const [approvalError, setApprovalError] = useState<PendingModalError>();
  const [pendingModalSteps, setPendingModalSteps] = useState<
    PendingConfirmModalState[]
  >([]);

  // This is a function instead of a memoized value because we do _not_ want it to update as the allowance changes.
  // For example, if the user needs to complete 3 steps initially, we should always show 3 step indicators
  // at the bottom of the modal, even after they complete steps 1 and 2.
  const generateRequiredSteps = useCallback(() => {
    const steps: PendingConfirmModalState[] = [];
    if (trade.fillType === TradeFillType.UniswapX && trade.wrapInfo.needsWrap) {
      steps.push(ConfirmModalState.WRAPPING);
    }
    if (
      allowance.state === AllowanceState.REQUIRED &&
      allowance.needsSetupApproval &&
      RESET_APPROVAL_TOKENS.some((token) => token.equals(allowance.token)) &&
      allowance.allowedAmount.greaterThan(0)
    ) {
      steps.push(ConfirmModalState.RESETTING_TOKEN_ALLOWANCE);
    }
    if (
      allowance.state === AllowanceState.REQUIRED &&
      allowance.needsSetupApproval
    ) {
      steps.push(ConfirmModalState.APPROVING_TOKEN);
    }
    if (
      allowance.state === AllowanceState.REQUIRED &&
      allowance.needsPermitSignature
    ) {
      steps.push(ConfirmModalState.PERMITTING);
    }
    steps.push(ConfirmModalState.PENDING_CONFIRMATION);
    return steps;
  }, [allowance, trade]);

  const { chainId } = useWeb3React();

  const nativeCurrency = useNativeCurrency(chainId);

  const [wrapTxHash, setWrapTxHash] = useState<string>();
  const { execute: onWrap } = useWrapCallback(
    nativeCurrency,
    trade.inputAmount.currency,
    trade.inputAmount.toExact()
  );
  const wrapConfirmed = useIsTransactionConfirmed(wrapTxHash);
  const prevWrapConfirmed = usePrevious(wrapConfirmed);
  /* eslint-disable-next-line  @typescript-eslint/no-explicit-any */
  const catchUserReject = async (e: any, errorType: PendingModalError) => {
    setConfirmModalState(ConfirmModalState.REVIEWING);
    if (didUserReject(e)) return;
    setApprovalError(errorType);
  };

  const performStep = useCallback(
    async (step: ConfirmModalState) => {
      switch (step) {
        case ConfirmModalState.WRAPPING:
          setConfirmModalState(ConfirmModalState.WRAPPING);
          onWrap?.()
            .then((wrapTxHash) => {
              setWrapTxHash(wrapTxHash);
            })
            .catch((e) => catchUserReject(e, PendingModalError.WRAP_ERROR));
          break;
        case ConfirmModalState.RESETTING_TOKEN_ALLOWANCE:
          setConfirmModalState(ConfirmModalState.RESETTING_TOKEN_ALLOWANCE);
          invariant(
            allowance.state === AllowanceState.REQUIRED,
            'Allowance should be required'
          );
          allowance
            .revoke()
            .catch((e) =>
              catchUserReject(e, PendingModalError.TOKEN_APPROVAL_ERROR)
            );
          break;
        case ConfirmModalState.APPROVING_TOKEN:
          setConfirmModalState(ConfirmModalState.APPROVING_TOKEN);
          invariant(
            allowance.state === AllowanceState.REQUIRED,
            'Allowance should be required'
          );
          allowance
            .approve()
            .catch((e) =>
              catchUserReject(e, PendingModalError.TOKEN_APPROVAL_ERROR)
            );
          break;
        case ConfirmModalState.PERMITTING:
          setConfirmModalState(ConfirmModalState.PERMITTING);
          invariant(
            allowance.state === AllowanceState.REQUIRED,
            'Allowance should be required'
          );
          allowance
            .permit()
            .catch((e) =>
              catchUserReject(e, PendingModalError.TOKEN_APPROVAL_ERROR)
            );
          break;
        case ConfirmModalState.PENDING_CONFIRMATION:
          setConfirmModalState(ConfirmModalState.PENDING_CONFIRMATION);
          try {
            onSwap();
          } catch (e) {
            catchUserReject(e, PendingModalError.CONFIRMATION_ERROR);
          }
          break;
        default:
          setConfirmModalState(ConfirmModalState.REVIEWING);
          break;
      }
    },
    [allowance, onSwap, onWrap]
  );

  const startSwapFlow = useCallback(() => {
    const steps = generateRequiredSteps();
    setPendingModalSteps(steps);
    performStep(steps[0]);
  }, [generateRequiredSteps, performStep]);

  const previousSetupApprovalNeeded = usePrevious(
    allowance.state === AllowanceState.REQUIRED
      ? allowance.needsSetupApproval
      : undefined
  );

  useEffect(() => {
    // If the wrapping step finished, trigger the next step (allowance or swap).
    if (wrapConfirmed && !prevWrapConfirmed) {
      // After the wrap has succeeded, reset the input currency to be WETH
      // because the trade will be on WETH -> token
      onCurrencySelection(Field.INPUT, trade.inputAmount.currency);
      // moves on to either approve WETH or to swap submission
      performStep(pendingModalSteps[1]);
    }
  }, [
    pendingModalSteps,
    performStep,
    prevWrapConfirmed,
    wrapConfirmed,
    onCurrencySelection,
    trade.inputAmount.currency
  ]);

  useEffect(() => {
    if (
      allowance.state === AllowanceState.REQUIRED &&
      allowance.needsPermitSignature &&
      // If the token approval switched from missing to fulfilled, trigger the next step (permit2 signature).
      !allowance.needsSetupApproval &&
      previousSetupApprovalNeeded
    ) {
      performStep(ConfirmModalState.PERMITTING);
    }
  }, [allowance, performStep, previousSetupApprovalNeeded]);

  const previousRevocationPending = usePrevious(
    allowance.state === AllowanceState.REQUIRED && allowance.isRevocationPending
  );
  useEffect(() => {
    if (
      allowance.state === AllowanceState.REQUIRED &&
      previousRevocationPending &&
      !allowance.isRevocationPending
    ) {
      performStep(ConfirmModalState.APPROVING_TOKEN);
    }
  }, [allowance, performStep, previousRevocationPending]);

  useEffect(() => {
    // Automatically triggers the next phase if the local modal state still thinks we're in the approval phase,
    // but the allowance has been set. This will automaticaly trigger the swap.
    if (
      isInApprovalPhase(confirmModalState) &&
      allowance.state === AllowanceState.ALLOWED
    ) {
      // Caveat: prevents swap if trade has updated mid approval flow.
      if (doesTradeDiffer) {
        setConfirmModalState(ConfirmModalState.REVIEWING);
        return;
      }
      performStep(ConfirmModalState.PENDING_CONFIRMATION);
    }
  }, [allowance, confirmModalState, doesTradeDiffer, performStep]);

  const onCancel = () => {
    setConfirmModalState(ConfirmModalState.REVIEWING);
    setApprovalError(undefined);
  };

  return {
    startSwapFlow,
    onCancel,
    confirmModalState,
    approvalError,
    pendingModalSteps,
    wrapTxHash
  };
};
