import { ChainId } from '@uniswap/sdk-core';
import React, { useState } from 'react';

import { cn } from 'utils/cn';

import useTokenLogoSource from 'hooks/useAssetLogoSource';

export enum AssetLogoSize {
  xs = 'size-3',
  sm = 'size-4',
  md = 'size-5',
  lg = 'size-6',
  xl = 'size-9',
  xxl = 'size-12',
  xxxl = 'size-16'
}

export enum AssetLogoVariant {
  Primary = 'Primary',
  Secondary = 'Secondary'
}

export type AssetLogoBaseProps = {
  symbol?: string | null;
  backupImg?: string | null;
  size?: AssetLogoSize | string;
  style?: React.CSSProperties;
  className?: string;
  variant?: AssetLogoVariant;
  showBorder?: boolean;
};
type AssetLogoProps = AssetLogoBaseProps & {
  isNative?: boolean;
  address?: string | null;
  chainId?: number;
};

const getSizeWithBorder = ({ size }: { size: AssetLogoSize | string }) => {
  switch (size) {
    case AssetLogoSize.xs:
      return 'size-3.5';

    case AssetLogoSize.sm:
      return 'size-4.5';

    case AssetLogoSize.md:
      return 'size-5.5';

    case AssetLogoSize.lg:
      return 'size-6.5';

    case AssetLogoSize.xl:
      return 'size-9.5';

    case AssetLogoSize.xxl:
      return 'size-12.5';

    case AssetLogoSize.xxxl:
      return 'size-16.5';

    default:
      return size;
  }
};

/**
 * Renders an image by prioritizing a list of sources, and then eventually a fallback triangle alert
 */
export default function AssetLogo({
  isNative,
  address,
  chainId = ChainId.MAINNET,
  symbol,
  backupImg,
  size = AssetLogoSize.lg,
  variant = AssetLogoVariant.Primary,
  showBorder = false,
  className
}: AssetLogoProps) {
  const [src, nextSrc] = useTokenLogoSource(
    address,
    chainId,
    isNative,
    backupImg
  );
  const [imgLoaded, setImgLoaded] = useState(() => {
    const img = document.createElement('img');
    img.src = src ?? '';
    return src ? img.complete : false;
  });

  const actualSize = showBorder ? getSizeWithBorder({ size }) : size;

  return (
    <div
      className={cn('relative flex rounded-full', actualSize, className, {
        'border border-blue-900':
          showBorder && variant === AssetLogoVariant.Primary,
        'border border-blue-800':
          showBorder && variant === AssetLogoVariant.Secondary
      })}
    >
      {src ? (
        <div
          className={cn('rounded-full shadow-sm transition-colors', size, {
            'bg-blue-800/20': !imgLoaded
          })}
        >
          <img
            src={src}
            alt={`${symbol ?? 'token'} logo`}
            onLoad={() => void setImgLoaded(true)}
            onError={nextSrc}
            loading='lazy'
            className={cn(
              size,
              'rounded-full transition-opacity duration-300',
              {
                'opacity-0': !imgLoaded
              }
            )}
          />
        </div>
      ) : (
        <div
          className={cn(
            'flex items-center justify-center rounded-full text-center font-medium text-gray-100',
            size,
            {
              'text-5xs leading-3': size === AssetLogoSize.xs,
              'text-4xs leading-4': size === AssetLogoSize.sm,
              'text-3xs leading-6': size === AssetLogoSize.lg,
              'bg-blue-800': variant === AssetLogoVariant.Primary,
              'bg-blue-900': variant === AssetLogoVariant.Secondary
            }
          )}
        >
          {/* use only first 3 characters of Symbol for design reasons */}
          {symbol
            ?.toUpperCase()
            .replace('$', '')
            .replace(/\s+/g, '')
            .slice(0, 3)}
        </div>
      )}
    </div>
  );
}
