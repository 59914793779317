import { useState } from 'react';

export function useSubscribeScrollState() {
  const [userCanScroll, setUserCanScroll] = useState(false);
  const [scrollProgress, setScrollProgress] = useState(0);
  const scrollRef = (node: HTMLDivElement) => {
    if (node !== null) {
      const canScroll = node.scrollHeight > node.clientHeight;
      canScroll !== userCanScroll && setUserCanScroll(canScroll);
    }
  };

  const scrollHandler = (event: React.UIEvent<HTMLDivElement>) => {
    const scrollTop = event.currentTarget.scrollTop;
    const containerHeight = event.currentTarget.clientHeight;
    const scrollHeight = event.currentTarget.scrollHeight;

    setScrollProgress(
      scrollTop ? ((scrollTop + containerHeight) / scrollHeight) * 100 : 0
    );
  };
  return { scrollRef, scrollHandler, scrollProgress, userCanScroll };
}
