export enum Container {
  INNER = 'INNER',
  OUTER = 'OUTER'
}

export enum ContainerSpaceWidth {
  OUTER_SMALL = 'w-26.75',
  OUTER_MEDIUM = 'w-30.25',
  OUTER_LARGE = 'w-32.25',
  INNER_SMALL = 'w-28.25',
  INNER_MEDIUM = 'w-31.75',
  INNER_LARGE = 'w-33.75',
  OUTER_ADD_LIQUIDITY = 'w-8.25',
  INNER_ADD_LIQUIDITY = 'w-6.75',
  OUTER_BRIDGE_TOP = 'w-4.25',
  INNER_BRIDGE_TOP = 'w-3.25',
  INNER_POOL_DETAILS_BOTTOM = 'w-34',
  OUTER_POOL_DETAILS_BOTTOM = 'w-32.5',
  OUTER_POOL_DETAILS_TOP = 'w-52.75',
  INNER_POOL_DETAILS_TOP = 'w-54.25',
  OUTER_MY_POSITION_TOP = 'w-45.75',
  INNER_MY_POSITION_TOP = 'w-47.25',
  INNER_POOL_TRANSACTIONS_TOP = 'w-28.5',
  OUTER_POOL_TRANSACTIONS_TOP = 'w-30.5',
  OUTER_POOLS = 'w-52.25',
  INNER_POOLS = 'w-53.75'
}
