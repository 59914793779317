import { useInterfaceMulticall } from './useContract';
import useCurrentBlockTimestamp from './useCurrentBlockTimestamp';
import type { BigNumber } from '@ethersproject/bignumber';
import { L2_CHAIN_IDS } from 'constants/chains';
import { L2_DEADLINE_FROM_NOW } from 'constants/misc';
import { useCallback, useMemo } from 'react';

import { useWeb3React } from 'hooks/useWeb3React';
import { useAppSelector } from 'state/hooks';

// combines the block timestamp with the user setting to give the deadline that should be used for any submitted transaction
export default function useTransactionDeadline(): BigNumber | undefined {
  const { chainId } = useWeb3React();
  const ttl = useAppSelector((state) => state.user.userDeadline);
  const blockTimestamp = useCurrentBlockTimestamp();
  return useMemo(
    () => timestampToDeadline(chainId, blockTimestamp, ttl),
    [blockTimestamp, chainId, ttl]
  );
}

export function useGetTransactionDeadline(): () => Promise<
  BigNumber | undefined
> {
  const { chainId } = useWeb3React();
  const ttl = useAppSelector((state) => state.user.userDeadline);
  const multicall = useInterfaceMulticall();
  return useCallback(async () => {
    const blockTimestamp = await multicall.getCurrentBlockTimestamp();
    return timestampToDeadline(chainId, blockTimestamp, ttl);
  }, [chainId, multicall, ttl]);
}

const timestampToDeadline = (
  chainId?: number,
  blockTimestamp?: BigNumber,
  ttl?: number
) => {
  if (blockTimestamp && chainId && L2_CHAIN_IDS.includes(chainId)) {
    return blockTimestamp.add(L2_DEADLINE_FROM_NOW);
  }

  if (blockTimestamp && ttl) {
    return blockTimestamp.add(ttl);
  }

  return undefined;
};
