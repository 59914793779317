import StatusIcon from '../../Identicon/StatusIcon';
import type {
  AddressAndChevronContainerProps,
  TextProps,
  Web3StatusConnectedProps
} from './Web3Status.types';
import { getConnection } from 'connection';
import { useConnectionReady } from 'connection/eagerlyConnect';
import { getRecentConnectionMeta } from 'connection/meta';
import { useCallback, useEffect, useRef } from 'react';

import { shortenAddress } from 'utils';
import { cn } from 'utils/cn';

import { usePendingActivity } from 'components/AccountDrawer/MiniPortfolio/Activity/hooks';
import useENSName from 'hooks/useENSName';
import { useWeb3React } from 'hooks/useWeb3React';
import { useAppDispatch } from 'state/hooks';

import Button from 'components/ui/Button';

import PortfolioDrawer, { useAccountDrawer } from 'components/AccountDrawer';
import Loader, { LoaderV3 } from 'components/Icons/LoadingSpinner';
import { IconWrapper } from 'components/Identicon/StatusIcon';
import PrefetchBalancesWrapper from 'components/PrefetchBalancesWrapper/PrefetchBalancesWrapper';
import { Portal } from 'nft/components/common/Portal';

import { updateRecentConnectionMeta } from 'state/user/reducer';

const Web3StatusConnection = ({
  children,
  pending,
  disabled,
  ...props
}: Web3StatusConnectedProps) => (
  <div
    className={cn(
      'flex cursor-pointer flex-row items-center justify-center gap-2.5',
      'text-xs font-medium text-blue-900',
      'rounded-full bg-transparent p-2.5 transition-colors duration-300',
      'hover:bg-gray-600/10',
      {
        'opacity-50': disabled
      }
    )}
    {...props}
  >
    {children}
  </div>
);

const AddressAndChevronContainer = ({
  children,
  loading
}: AddressAndChevronContainerProps) => (
  <div className={cn('flex', { 'opacity-50': loading })}>{children}</div>
);

const Text = ({ children }: TextProps) => (
  <p className='mx-1 flex-auto overflow-hidden text-ellipsis whitespace-nowrap text-base font-medium'>
    {children}
  </p>
);

const Web3StatusInner = () => {
  const connectionReady = useConnectionReady();
  const { account, connector } = useWeb3React();

  const { ENSName, loading: ENSLoading } = useENSName(account);
  const connection = getConnection(connector);
  const dispatch = useAppDispatch();

  const [, toggleAccountDrawer] = useAccountDrawer();
  const handleWalletDropdownClick = useCallback(() => {
    toggleAccountDrawer();
  }, [toggleAccountDrawer]);

  const { hasPendingActivity, pendingActivityCount } = usePendingActivity();

  // Display a loading state while initializing the connection, based on the last session's persisted connection.
  // The connection will go through three states:
  // - startup:       connection is not ready
  // - initializing:  account is available, but ENS (if preset on the persisted initialMeta) is still loading
  // - initialized:   account and ENS are available
  // Subsequent connections are always considered initialized, and will not display startup/initializing states.
  const initialConnection = useRef(getRecentConnectionMeta());
  const isConnectionInitializing = Boolean(
    initialConnection.current?.address === account &&
      initialConnection.current?.ENSName &&
      ENSLoading
  );
  const isConnectionInitialized = connectionReady && !isConnectionInitializing;
  // Clear the initial connection once initialized so it does not interfere with subsequent connections.
  useEffect(() => {
    if (isConnectionInitialized) {
      initialConnection.current = undefined;
    }
  }, [isConnectionInitialized]);
  // Persist the connection if it changes, so it can be used to initialize the next session's connection.
  useEffect(() => {
    if (account || ENSName) {
      const { rdns } = connection.getProviderInfo();
      dispatch(
        updateRecentConnectionMeta({
          type: connection.type,
          address: account,
          ENSName: ENSName ?? undefined,
          rdns
        })
      );
    }
  }, [ENSName, account, connection, dispatch]);

  if (!isConnectionInitialized) {
    return (
      <Web3StatusConnection
        disabled={!isConnectionInitializing}
        onClick={handleWalletDropdownClick}
      >
        <IconWrapper size={24}>
          <LoaderV3 size='24px' />
        </IconWrapper>
        <AddressAndChevronContainer loading>
          <Text>
            {initialConnection.current?.ENSName ??
              shortenAddress(initialConnection.current?.address)}
          </Text>
        </AddressAndChevronContainer>
      </Web3StatusConnection>
    );
  }

  if (account) {
    return (
      <Web3StatusConnection
        onClick={handleWalletDropdownClick}
        pending={hasPendingActivity}
      >
        {!hasPendingActivity && (
          <StatusIcon
            account={account}
            size={24}
            connection={connection}
            showMiniIcons={false}
          />
        )}

        {hasPendingActivity ? (
          <div className='flex flex-row items-center justify-between'>
            <Text>{pendingActivityCount} Pending</Text>{' '}
            <Loader stroke='white' />
          </div>
        ) : (
          <AddressAndChevronContainer>
            <Text>{ENSName ?? shortenAddress(account)}</Text>
          </AddressAndChevronContainer>
        )}
      </Web3StatusConnection>
    );
  }

  return (
    <Button size='sm' onClick={handleWalletDropdownClick} tabIndex={0}>
      Connect
    </Button>
  );
};

const Web3Status = () => {
  const [isDrawerOpen] = useAccountDrawer();
  return (
    <PrefetchBalancesWrapper
      shouldFetchOnAccountUpdate={isDrawerOpen}
      className='flex flex-row items-center justify-center'
    >
      <Web3StatusInner />
      <Portal>
        <PortfolioDrawer />
      </Portal>
    </PrefetchBalancesWrapper>
  );
};

export default Web3Status;
