import { CustomUserProperties } from '@uniswap/analytics-events';
import {
  type Web3ReactHooks,
  Web3ReactProvider,
  useWeb3React
} from '@web3-react/core';
import type { Connector } from '@web3-react/types';
import { user } from 'analytics';
import { connections, getConnection } from 'connection';
import { isSupportedChain } from 'constants/chains';
import { DEPRECATED_RPC_PROVIDERS, RPC_PROVIDERS } from 'constants/providers';
import { useFallbackProviderEnabled } from 'featureFlags/flags/fallbackProvider';
import {
  TraceJsonRpcVariant,
  useTraceJsonRpcFlag
} from 'featureFlags/flags/traceJsonRpc';
import { type ReactNode, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { getCurrentPageFromLocation } from 'utils/urlRoutes';
import { getWalletMeta } from 'utils/walletMeta';

import usePrevious from 'hooks/usePrevious';
import { useConnectedWallets } from 'state/wallets/hooks';

export default function Web3Provider({ children }: { children: ReactNode }) {
  const connectors = connections.map<[Connector, Web3ReactHooks]>(
    ({ hooks, connector }) => [connector, hooks]
  );

  return (
    <Web3ReactProvider connectors={connectors}>
      <Updater />
      {children}
    </Web3ReactProvider>
  );
}

/** A component to run hooks under the Web3ReactProvider context. */
function Updater() {
  const { account, chainId, connector, provider } = useWeb3React();
  const { pathname } = useLocation();
  const currentPage = getCurrentPageFromLocation(pathname);

  const providers = useFallbackProviderEnabled()
    ? RPC_PROVIDERS
    : DEPRECATED_RPC_PROVIDERS;

  // Trace RPC calls (for debugging).
  const networkProvider = isSupportedChain(chainId)
    ? providers[chainId]
    : undefined;
  const shouldTrace = useTraceJsonRpcFlag() === TraceJsonRpcVariant.Enabled;
  useEffect(() => {
    if (shouldTrace) {
      provider?.on('debug', trace);
      if (provider !== networkProvider) {
        networkProvider?.on('debug', trace);
      }
    }
    return () => {
      provider?.off('debug', trace);
      networkProvider?.off('debug', trace);
    };
  }, [networkProvider, provider, shouldTrace]);

  // Send analytics events when the active account changes.
  const previousAccount = usePrevious(account);
  const [connectedWallets, addConnectedWallet] = useConnectedWallets();
  useEffect(() => {
    if (account && account !== previousAccount) {
      const walletType = getConnection(connector).getProviderInfo().name;
      const peerWalletAgent = provider
        ? getWalletMeta(provider)?.agent
        : undefined;

      provider
        ?.send('web3_clientVersion', [])
        .then((clientVersion) => {
          user.set(CustomUserProperties.WALLET_VERSION, clientVersion);
        })
        .catch((error) => {
          console.warn('Failed to get client version', error);
        });

      // User properties *must* be set before sending corresponding event properties,
      // so that the event contains the correct and up-to-date user properties.
      user.set(CustomUserProperties.WALLET_ADDRESS, account);
      user.postInsert(
        CustomUserProperties.ALL_WALLET_ADDRESSES_CONNECTED,
        account
      );

      user.set(CustomUserProperties.WALLET_TYPE, walletType);
      user.set(CustomUserProperties.PEER_WALLET_AGENT, peerWalletAgent ?? '');
      if (chainId) {
        user.set(CustomUserProperties.CHAIN_ID, chainId);
        user.postInsert(CustomUserProperties.ALL_WALLET_CHAIN_IDS, chainId);
      }

      addConnectedWallet({ account, walletType });
    }
  }, [
    account,
    addConnectedWallet,
    currentPage,
    chainId,
    connectedWallets,
    connector,
    previousAccount,
    provider
  ]);

  return null;
}

function trace(event: any) {
  if (!event?.request) return;
  const { method, id, params } = event.request;
  console.groupCollapsed(method, id);
  console.debug(params);
  console.groupEnd();
}
