import { OutputTaxTooltipBody } from './TaxTooltipBody';
import { InterfaceSectionName } from '@uniswap/analytics-events';
import { type Currency, CurrencyAmount, type Token } from '@uniswap/sdk-core';
import { UNIVERSAL_ROUTER_ADDRESS } from '@uniswap/universal-router-sdk';
import { useConnectionReady } from 'connection/eagerlyConnect';
import { getChainInfo } from 'constants/chainInfo';
import { isSupportedChain } from 'constants/chains';
import JSBI from 'jsbi';
import {
  type HTMLProps,
  type ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';
import { useNavigate } from 'react-router-dom';

import { isClassicTrade } from 'state/routing/utils';
import { isPreviewTrade } from 'state/routing/utils';
import { cn } from 'utils/cn';
import { computeFiatValuePriceImpact } from 'utils/computeFiatValuePriceImpact';
import { NumberType, useFormatter } from 'utils/formatNumbers';
import { maxAmountSpend } from 'utils/maxAmountSpend';
import { largerPercentValue } from 'utils/percent';
import { computeRealizedPriceImpact, warningSeverity } from 'utils/prices';

import { useIsSwapUnsupported } from 'hooks/useIsSwapUnsupported';
import { useMaxAmountIn } from 'hooks/useMaxAmountIn';
import usePermit2Allowance, { AllowanceState } from 'hooks/usePermit2Allowance';
import usePrevious from 'hooks/usePrevious';
import { type SwapResult, useSwapCallback } from 'hooks/useSwapCallback';
import { useUSDPrice } from 'hooks/useUSDPrice';
import { useWeb3React } from 'hooks/useWeb3React';
import useWrapCallback, {
  WrapType,
  wrapErrorText
} from 'hooks/useWrapCallback';
import useNativeCurrency from 'lib/hooks/useNativeCurrency';
import { useAppSelector } from 'state/hooks';
import {
  type SwapInfoInputError,
  useSwapActionHandlers,
  useURLLoadedTokens
} from 'state/swap/hooks';

import { type AlertState, AlertType } from 'components/ui/Alert/constants';
import Button from 'components/ui/Button';
import Tabs from 'components/ui/tabs/Tabs';

import SwapCurrencyInputPanel from 'components/CurrencyInputPanel/SwapCurrencyInputPanel';
import { SwapIcon } from 'components/Icons/SwapIcon';
import TokenSafetyModal from 'components/TokenSafety/TokenSafetyModal';
import { ConfirmSwapModal } from 'components/composed/Modal';
import { PriceImpactModal } from 'components/composed/Modal';
import { PriceImpactWarning } from 'components/composed/Swap/PriceImpactWarning';
import SwapSectionWrapper from 'components/composed/Swap/SwapSectionWrapper';
import { confirmPriceImpactWithoutFee } from 'components/composed/Swap/confirmPriceImpactWithoutFee';
import { Field } from 'components/composed/Swap/constants';

import { type InterfaceTrade, TradeState } from 'state/routing/types';
import {
  type CurrencyState,
  useSwapAndLimitContext,
  useSwapContext
} from 'state/swap/SwapContext';

const getIsReviewableQuote = (
  trade: InterfaceTrade | undefined,
  tradeState: TradeState,
  swapInputError?: SwapInfoInputError
): boolean => {
  if (swapInputError) return false;
  // if the current quote is a preview quote, allow the user to progress to the Swap review screen
  if (isPreviewTrade(trade)) return true;

  return Boolean(trade && tradeState === TradeState.VALID);
};

const getSingleUnitAmount = (currency?: Currency) => {
  if (!currency) return;
  return CurrencyAmount.fromRawAmount(
    currency,
    JSBI.BigInt(10 ** currency.decimals)
  );
};

interface WrapperProps extends HTMLProps<HTMLDivElement> {
  children: ReactNode;
}

const Spacer = ({ children, ...props }: WrapperProps) => (
  <div {...props}>{children}</div>
);

interface SwapFormProps {
  disableTokenInputs?: boolean;
  onCurrencyChange?: (selected: CurrencyState) => void;
  setAlert: (alert: AlertState) => void;
  setPriceImpactAlert: (alert: AlertState) => void;
}

export const SwapForm = ({
  disableTokenInputs = false,
  onCurrencyChange,
  setAlert,
  setPriceImpactAlert
}: SwapFormProps) => {
  const [activeTabID, setActiveTabID] = useState('regular');
  const [isSwapButtonDisabled, setIsSwapButtonDisabled] = useState(true);

  const [
    { showConfirm, tradeToConfirm, swapError, swapResult },
    setSwapFormState
  ] = useState<{
    showConfirm: boolean;
    tradeToConfirm?: InterfaceTrade;
    swapError?: Error;
    swapResult?: SwapResult;
  }>({
    showConfirm: false,
    tradeToConfirm: undefined,
    swapError: undefined,
    swapResult: undefined
  });

  const { onSwitchTokens, onCurrencySelection, onUserInput } =
    useSwapActionHandlers();

  const { account, chainId: connectedChainId /*connector*/ } = useWeb3React();

  const nativeCurrency = useNativeCurrency(connectedChainId);

  const { chainId, prefilledState, currencyState } = useSwapAndLimitContext();
  const { swapState, setSwapState, derivedSwapInfo } = useSwapContext();
  const { typedValue, independentField } = swapState;

  const urlLoadedTokens = useURLLoadedTokens(chainId);

  const {
    trade: { state: tradeState, trade },
    allowedSlippage,
    currencyBalances,
    parsedAmount,
    currencies,
    inputError: swapInputError,
    outputFeeFiatValue,
    inputTax,
    outputTax
  } = derivedSwapInfo;

  const inputCurrency = currencies[Field.INPUT] ?? undefined;

  // #region Wrap
  const {
    wrapType,
    execute: onWrap,
    inputError: wrapInputError
  } = useWrapCallback(
    currencies[Field.INPUT],
    currencies[Field.OUTPUT],
    typedValue
  );
  const showWrap: boolean = wrapType !== WrapType.NOT_APPLICABLE;

  const handleOnWrap = useCallback(async () => {
    if (!onWrap) return;
    try {
      const txHash = await onWrap();
      setSwapFormState((currentState) => ({
        ...currentState,
        swapError: undefined,
        txHash
      }));
      onUserInput(Field.INPUT, '');
    } catch (error) {
      setSwapFormState((currentState) => ({
        ...currentState,
        swapError: error,
        txHash: undefined
      }));
    }
  }, [onUserInput, onWrap]);
  // #endregion Wrap

  const [routeNotFound, routeIsLoading, routeIsSyncing] = useMemo(
    () => [
      tradeState === TradeState.NO_ROUTE_FOUND,
      tradeState === TradeState.LOADING,
      tradeState === TradeState.LOADING && Boolean(trade)
    ],
    [trade, tradeState]
  );

  const isReviewableQuote = getIsReviewableQuote(
    trade,
    tradeState,
    swapInputError
  );

  // #region Price Impact
  const [showPriceImpactModal, setShowPriceImpactModal] =
    useState<boolean>(false);

  const fiatValueTradeInput = useUSDPrice(trade?.inputAmount);
  const fiatValueTradeOutput = useUSDPrice(trade?.outputAmount);
  const preTaxFiatValueTradeOutput = useUSDPrice(trade?.outputAmount);

  const [stablecoinPriceImpact, preTaxStablecoinPriceImpact] = useMemo(
    () =>
      routeIsSyncing || !isClassicTrade(trade) || showWrap
        ? [undefined, undefined]
        : [
            computeFiatValuePriceImpact(
              fiatValueTradeInput.data,
              fiatValueTradeOutput.data
            ),
            computeFiatValuePriceImpact(
              fiatValueTradeInput.data,
              preTaxFiatValueTradeOutput.data
            )
          ],
    [
      fiatValueTradeInput,
      fiatValueTradeOutput,
      preTaxFiatValueTradeOutput,
      routeIsSyncing,
      trade,
      showWrap
    ]
  );

  const { priceImpactSeverity, largerPriceImpact } = useMemo(() => {
    if (!isClassicTrade(trade)) {
      return { priceImpactSeverity: 0, largerPriceImpact: undefined };
    }

    const marketPriceImpact = trade?.priceImpact
      ? computeRealizedPriceImpact(trade)
      : undefined;
    const largerPriceImpact = largerPercentValue(
      marketPriceImpact,
      preTaxStablecoinPriceImpact
    );
    return {
      priceImpactSeverity: warningSeverity(largerPriceImpact),
      largerPriceImpact
    };
  }, [preTaxStablecoinPriceImpact, trade]);

  const showPriceImpactWarning =
    isClassicTrade(trade) && largerPriceImpact && priceImpactSeverity > 3;

  useEffect(() => {
    if (showPriceImpactWarning) {
      setPriceImpactAlert({
        show: true,
        message: <PriceImpactWarning priceImpact={largerPriceImpact} />,
        type: AlertType.Warning
      });
      return;
    }

    setPriceImpactAlert({ show: false, message: '', type: AlertType.Info });
  }, [largerPriceImpact, setPriceImpactAlert, showPriceImpactWarning]);
  // #endregion Price Impact

  // #region Amounts
  const parsedAmounts = useMemo(
    () =>
      showWrap
        ? {
            [Field.INPUT]: parsedAmount,
            [Field.OUTPUT]: parsedAmount
          }
        : {
            [Field.INPUT]:
              independentField === Field.INPUT
                ? parsedAmount
                : trade?.inputAmount,
            [Field.OUTPUT]:
              independentField === Field.OUTPUT
                ? parsedAmount
                : trade?.outputAmount
          },
    [independentField, parsedAmount, showWrap, trade]
  );

  const userHasSpecifiedInputOutput = Boolean(
    currencies[Field.INPUT] &&
      currencies[Field.OUTPUT] &&
      parsedAmounts[independentField]?.greaterThan(0)
  );

  const { formatCurrencyAmount } = useFormatter();

  const dependentField: Field =
    independentField === Field.INPUT ? Field.OUTPUT : Field.INPUT;

  const formattedAmounts = useMemo(
    () => ({
      [independentField]: typedValue,
      [dependentField]: showWrap
        ? parsedAmounts[independentField]?.toExact() ?? ''
        : formatCurrencyAmount({
            amount: parsedAmounts[dependentField],
            type: NumberType.SwapTradeAmount,
            placeholder: ''
          })
    }),
    [
      dependentField,
      formatCurrencyAmount,
      independentField,
      parsedAmounts,
      showWrap,
      typedValue
    ]
  );

  const maxInputAmount: CurrencyAmount<Currency> | undefined = useMemo(
    () => maxAmountSpend(currencyBalances[Field.INPUT]),
    [currencyBalances]
  );
  // #endregion Amounts

  // #region Input
  const allowMaxClick = Boolean(
    maxInputAmount?.greaterThan(0) &&
      !parsedAmounts[Field.INPUT]?.equalTo(maxInputAmount)
  );

  const handleMaxInput = useCallback(() => {
    maxInputAmount && onUserInput(Field.INPUT, maxInputAmount.toExact());
  }, [maxInputAmount, onUserInput]);

  const handleTypeInput = useCallback(
    (value: string) => {
      onUserInput(Field.INPUT, value);
    },
    [onUserInput]
  );

  const handleTypeOutput = useCallback(
    (value: string) => {
      onUserInput(Field.OUTPUT, value);
    },
    [onUserInput]
  );

  const showFiatValueInput = Boolean(parsedAmounts[Field.INPUT]);
  const showFiatValueOutput = Boolean(parsedAmounts[Field.OUTPUT]);

  const fiatValueInput = useUSDPrice(
    parsedAmounts[Field.INPUT] ?? getSingleUnitAmount(currencies[Field.INPUT]),
    currencies[Field.INPUT]
  );

  const fiatValueOutput = useUSDPrice(
    parsedAmounts[Field.OUTPUT] ??
      getSingleUnitAmount(currencies[Field.OUTPUT]),
    currencies[Field.OUTPUT]
  );

  const handleInputSelect = useCallback(
    (inputCurrency: Currency) => {
      onCurrencySelection(Field.INPUT, inputCurrency);
      onCurrencyChange?.({
        inputCurrency,
        outputCurrency: currencyState.outputCurrency
      });
    },
    [onCurrencyChange, onCurrencySelection, currencyState]
  );

  const handleOutputSelect = useCallback(
    (outputCurrency: Currency) => {
      onCurrencySelection(Field.OUTPUT, outputCurrency);
      onCurrencyChange?.({
        inputCurrency: currencyState.inputCurrency,
        outputCurrency
      });
    },
    [onCurrencyChange, onCurrencySelection, currencyState]
  );

  const inputCurrencyNumericalInputRef = useRef<HTMLInputElement>(null);

  const [inputTokenHasTax, outputTokenHasTax] = useMemo(
    () => [!inputTax.equalTo(0), !outputTax.equalTo(0)],
    [inputTax, outputTax]
  );

  // #endregion Input

  // #region Token Warning Modal
  const navigate = useNavigate();

  const [dismissTokenWarning, setDismissTokenWarning] =
    useState<boolean>(false);

  const handleConfirmTokenWarning = useCallback(() => {
    setDismissTokenWarning(true);
  }, []);

  // reset if they close warning without tokens in params
  const handleDismissTokenWarning = useCallback(() => {
    setDismissTokenWarning(true);
    navigate('/swap/');
  }, [navigate]);
  // #endregion Token Warning Modal

  // #region Confirmation Modal
  const handleAcceptChanges = useCallback(() => {
    setSwapFormState((currentState) => ({
      ...currentState,
      tradeToConfirm: trade
    }));
  }, [trade]);

  const clearSwapState = useCallback(() => {
    setSwapFormState((currentState) => ({
      ...currentState,
      swapError: undefined,
      swapResult: undefined
    }));
  }, []);

  const swapFiatValues = useMemo(
    () => ({
      amountIn: fiatValueTradeInput.data,
      amountOut: fiatValueTradeOutput.data,
      feeUsd: outputFeeFiatValue
    }),
    [fiatValueTradeInput.data, fiatValueTradeOutput.data, outputFeeFiatValue]
  );

  const maximumAmountIn = useMaxAmountIn(trade, allowedSlippage);

  const allowance = usePermit2Allowance(
    maximumAmountIn ??
      (parsedAmounts[Field.INPUT]?.currency.isToken
        ? (parsedAmounts[Field.INPUT] as CurrencyAmount<Token>)
        : undefined),
    isSupportedChain(chainId) ? UNIVERSAL_ROUTER_ADDRESS(chainId) : undefined,
    trade?.fillType
  );

  const swapCallback = useSwapCallback(
    trade,
    swapFiatValues,
    allowedSlippage,
    allowance.state === AllowanceState.ALLOWED
      ? allowance.permitSignature
      : undefined
  );

  const handleSwap = useCallback(() => {
    if (!swapCallback) {
      return;
    }
    if (
      preTaxStablecoinPriceImpact &&
      !confirmPriceImpactWithoutFee(preTaxStablecoinPriceImpact)
    ) {
      return;
    }
    swapCallback()
      .then((result) => {
        setSwapFormState((currentState) => ({
          ...currentState,
          swapError: undefined,
          swapResult: result
        }));
      })
      .catch((error) => {
        setSwapFormState((currentState) => ({
          ...currentState,
          swapError: error,
          swapResult: undefined
        }));
      });
  }, [swapCallback, preTaxStablecoinPriceImpact]);

  const handleConfirmDismiss = useCallback(() => {
    setSwapFormState((currentState) => ({
      ...currentState,
      showConfirm: false
    }));
    // If there was a swap, we want to clear the input
    if (swapResult) {
      onUserInput(Field.INPUT, '');
    }
  }, [onUserInput, swapResult]);
  // #endregion Confirmation Modal

  const swapIsUnsupported = useIsSwapUnsupported(
    currencies[Field.INPUT],
    currencies[Field.OUTPUT]
  );

  const switchingChain = useAppSelector(
    (state) => state.wallets.switchingChain
  );

  const connectionReady = useConnectionReady();

  useEffect(() => {
    // Force exact input if the user switches to an output token with tax
    if (outputTokenHasTax && independentField === Field.OUTPUT) {
      setSwapState((state) => ({
        ...state,
        independentField: Field.INPUT,
        typedValue: ''
      }));
    }
  }, [independentField, outputTokenHasTax, setSwapState, trade?.outputAmount]);

  const previousConnectedChainId = usePrevious(connectedChainId);
  const previousPrefilledState = usePrevious(prefilledState);
  useEffect(() => {
    const chainChanged =
      previousConnectedChainId && previousConnectedChainId !== connectedChainId;
    const prefilledInputChanged =
      previousPrefilledState?.inputCurrency &&
      !prefilledState.inputCurrency?.equals(
        previousPrefilledState.inputCurrency
      );
    const prefilledOutputChanged =
      previousPrefilledState?.outputCurrency &&
      !prefilledState?.outputCurrency?.equals(
        previousPrefilledState.outputCurrency
      );

    if (chainChanged || prefilledInputChanged || prefilledOutputChanged) {
      // reset local state
      setSwapFormState({
        tradeToConfirm: undefined,
        swapError: undefined,
        showConfirm: false,
        swapResult: undefined
      });
    }
  }, [
    connectedChainId,
    prefilledState,
    previousConnectedChainId,
    previousPrefilledState,
    setSwapState
  ]);

  // #region Alerts
  const showAlert = useCallback(
    ({ message, type }: { message: string | ReactNode; type: AlertType }) => {
      setIsSwapButtonDisabled(true);
      setAlert({ show: true, message, type });
    },
    [setAlert]
  );

  const hideAlert = useCallback(() => {
    setIsSwapButtonDisabled(false);
    setAlert({ show: false, message: '', type: AlertType.Info });
  }, [setAlert]);

  useEffect(() => {
    if (swapIsUnsupported) {
      showAlert({ message: 'Unsupported asset', type: AlertType.Error });
      return;
    }

    if (switchingChain) {
      showAlert({
        message: `Connecting to ${getChainInfo(switchingChain)?.label}`,
        type: AlertType.Info
      });
      return;
    }

    if (connectionReady && !account) {
      showAlert({ message: 'Connect wallet', type: AlertType.Info });
      return;
    }

    if (chainId && chainId !== connectedChainId) {
      showAlert({
        message: `Connect to ${getChainInfo(chainId)?.label}`,
        type: AlertType.Info
      });
      return;
    }

    if (showWrap && !!wrapInputError) {
      const message = wrapErrorText({ nativeCurrency, wrapInputError });
      if (message) {
        showAlert({ message: message, type: AlertType.Error });
        return;
      }
    }

    if (
      routeNotFound &&
      userHasSpecifiedInputOutput &&
      !routeIsLoading &&
      !routeIsSyncing
    ) {
      showAlert({ message: 'Insufficient liquidity', type: AlertType.Warning });
      return;
    }

    if (swapInputError) {
      const message = swapInputError.message;
      const type =
        swapInputError.type === 'error' ? AlertType.Error : AlertType.Info;
      showAlert({ message, type });
      return;
    }

    if (!isReviewableQuote) {
      if (tradeState === TradeState.LOADING) {
        showAlert({
          message: 'Loading trade information',
          type: AlertType.Info
        });
        return;
      }

      showAlert({
        message: 'Not a valid trade',
        type: AlertType.Error
      });
      return;
    }

    hideAlert();
  }, [
    account,
    chainId,
    connectedChainId,
    connectionReady,
    hideAlert,
    isReviewableQuote,
    nativeCurrency,
    routeIsLoading,
    routeIsSyncing,
    routeNotFound,
    showAlert,
    showWrap,
    swapInputError,
    swapIsUnsupported,
    switchingChain,
    tradeState,
    userHasSpecifiedInputOutput,
    wrapInputError
  ]);
  // #endregion Alerts

  const handleContinueToReview = useCallback(() => {
    setSwapFormState({
      tradeToConfirm: trade,
      swapError: undefined,
      showConfirm: true,
      swapResult: undefined
    });
  }, [trade]);

  return (
    <>
      <Tabs
        activeTabId={activeTabID}
        onTabChange={setActiveTabID}
        className='absolute -top-6.5 left-1/2 z-10 w-37.5 -translate-x-1/2'
        size='sm'
        tabs={[
          {
            id: 'regular',
            children: <span className='z-10 -ml-1'>Regular</span>
          },
          {
            id: 'private',
            children: <span className='z-10 -mr-1'>Private</span>
          }
        ]}
      />

      <SwapSectionWrapper field={Field.INPUT}>
        <SwapCurrencyInputPanel
          inputLabel='You pay'
          disabled={disableTokenInputs}
          value={formattedAmounts[Field.INPUT]}
          allowMaxClick={allowMaxClick}
          onMax={handleMaxInput}
          currency={currencies[Field.INPUT] ?? null}
          onUserInput={handleTypeInput}
          fiatValue={showFiatValueInput ? fiatValueInput : undefined}
          onCurrencySelect={handleInputSelect}
          otherCurrency={currencies[Field.OUTPUT]}
          showCommonBases
          id={InterfaceSectionName.CURRENCY_INPUT_PANEL}
          loading={independentField === Field.OUTPUT && routeIsSyncing}
          fieldType={Field.INPUT}
          ref={inputCurrencyNumericalInputRef}
        />
      </SwapSectionWrapper>

      <Spacer className='relative -mb-1.5 -mt-1.5'>
        <SwapIcon
          width={18}
          height={18}
          className={cn(
            'z-11 m-auto stroke-gray-800',
            isSupportedChain(chainId) && 'hover:cursor-pointer hover:opacity-80'
          )}
          data-testid='swap-currency-button'
          onClick={() => {
            if (disableTokenInputs) return;
            onSwitchTokens(inputTokenHasTax, formattedAmounts[dependentField]);
          }}
        />
      </Spacer>

      <SwapSectionWrapper field={Field.OUTPUT}>
        <SwapCurrencyInputPanel
          inputLabel='You receive'
          disabled={disableTokenInputs}
          value={formattedAmounts[Field.OUTPUT]}
          allowMaxClick={false}
          currency={currencies[Field.OUTPUT] ?? null}
          onUserInput={handleTypeOutput}
          fiatValue={showFiatValueOutput ? fiatValueOutput : undefined}
          onCurrencySelect={handleOutputSelect}
          otherCurrency={currencies[Field.INPUT]}
          showCommonBases
          id={InterfaceSectionName.CURRENCY_OUTPUT_PANEL}
          loading={independentField === Field.INPUT && routeIsSyncing}
          fieldType={Field.OUTPUT}
          priceImpact={stablecoinPriceImpact}
          numericalInputSettings={{
            // We disable numerical input here if the selected token has tax, since we cannot guarantee exact_outputs for FOT tokens
            disabled: inputTokenHasTax || outputTokenHasTax,
            // Focus the input currency panel if the user tries to type into the disabled output currency panel
            onDisabledClick: () =>
              inputCurrencyNumericalInputRef.current?.focus(),
            disabledTooltipBody: (
              <OutputTaxTooltipBody
                currencySymbol={
                  currencies[inputTokenHasTax ? Field.INPUT : Field.OUTPUT]
                    ?.symbol
                }
              />
            )
          }}
        />
      </SwapSectionWrapper>

      <Button
        onClick={() => {
          if (!isSwapButtonDisabled) {
            if (showWrap) {
              handleOnWrap();
            } else {
              showPriceImpactWarning
                ? setShowPriceImpactModal(true)
                : handleContinueToReview();
            }
          }
        }}
        id='swap-button'
        data-testid='swap-button'
        variant='primary'
        disabled={isSwapButtonDisabled}
        className='absolute -bottom-6.5 left-1/2 z-11 w-37.5 -translate-x-1/2'
      >
        {showWrap && (wrapType === WrapType.WRAP ? 'Wrap' : 'Unwrap')}
        {!showWrap && 'Swap'}
      </Button>

      <TokenSafetyModal
        isOpen={urlLoadedTokens.length > 0 && !dismissTokenWarning}
        tokenAddress={urlLoadedTokens[0]?.address}
        secondTokenAddress={urlLoadedTokens[1]?.address}
        onContinue={handleConfirmTokenWarning}
        onCancel={handleDismissTokenWarning}
        showCancel
      />

      {showPriceImpactModal && showPriceImpactWarning && (
        <PriceImpactModal
          priceImpact={largerPriceImpact}
          onDismiss={() => setShowPriceImpactModal(false)}
          onContinue={() => {
            setShowPriceImpactModal(false);
            handleContinueToReview();
          }}
        />
      )}

      {trade && showConfirm && (
        <ConfirmSwapModal
          trade={trade}
          inputCurrency={inputCurrency}
          originalTrade={tradeToConfirm}
          onAcceptChanges={handleAcceptChanges}
          onCurrencySelection={onCurrencySelection}
          swapResult={swapResult}
          allowedSlippage={allowedSlippage}
          clearSwapState={clearSwapState}
          onConfirm={handleSwap}
          allowance={allowance}
          swapError={swapError}
          onDismiss={handleConfirmDismiss}
        />
      )}
    </>
  );
};
