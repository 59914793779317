import type { ChainId } from '@uniswap/sdk-core';
import { getChainInfo } from 'constants/chainInfo';

import { cn } from 'utils/cn';

import { useWeb3React } from 'hooks/useWeb3React';

import Loader from 'components/Icons/LoadingSpinner';
import { ChainLogo } from 'components/Logo/ChainLogo';
import { CheckMarkIcon } from 'nft/components/icons';
import styled from 'styled-components';

const LOGO_SIZE = 20;

const Label = styled.div`
  grid-column: 2;
  grid-row: 1;
  font-size: 16px;
  font-weight: 485;
`;
const Status = styled.div`
  grid-column: 3;
  grid-row: 1;
  display: flex;
  align-items: center;
  width: 20px;
`;
const CaptionText = styled.div`
  color: ${({ theme }) => theme.neutral2};
  font-size: 12px;
  grid-column: 2;
  grid-row: 2;
`;

interface ChainSelectorRowProps {
  disabled?: boolean;
  targetChain: ChainId;
  onSelectChain: (targetChain: number) => void;
  isPending: boolean;
}
export default function ChainSelectorRow({
  disabled,
  targetChain,
  onSelectChain,
  isPending
}: ChainSelectorRowProps) {
  const { chainId } = useWeb3React();
  const active = chainId === targetChain;
  const chainInfo = getChainInfo(targetChain);
  const label = chainInfo?.label;

  return (
    <button
      data-testid={`${label}-selector`}
      disabled={!!disabled}
      onClick={() => {
        if (!disabled) onSelectChain(targetChain);
      }}
      className={cn(
        'flex w-full flex-row items-center gap-2 rounded-lg px-2 py-1 hover:bg-blue-900 md:w-60',
        'transition-colors duration-300',
        'border-none bg-none text-left text-sm font-medium text-gray-100',
        'disabled:cursor-auto disabled:opacity-60'
      )}
    >
      <ChainLogo chainId={targetChain} size={LOGO_SIZE} />
      {label && <Label>{label}</Label>}
      {disabled && <CaptionText>Unsupported by your wallet</CaptionText>}
      {isPending && <CaptionText>Approve in wallet</CaptionText>}
      <Status>
        {active && <CheckMarkIcon className='size-5 text-blue-200' />}
        {!active && isPending && (
          <Loader width={LOGO_SIZE} height={LOGO_SIZE} />
        )}
      </Status>
    </button>
  );
}
