import { MenuDropdown } from '../../NavBar/MenuDropdown';
import type { ReactNode } from 'react';
import { NavLink, type NavLinkProps, useLocation } from 'react-router-dom';

import { cn } from 'utils/cn';
import { routes } from 'utils/routes';

import { useIsPoolsPage } from 'hooks/useIsPoolsPage';

interface MenuItemProps {
  href: string;
  id?: NavLinkProps['id'];
  isActive?: boolean;
  children: ReactNode;
}

const MenuItem = ({ href, id, isActive, children }: MenuItemProps) => (
  <NavLink
    to={href}
    className={cn(
      'rounded-full bg-transparent p-2 font-bold no-underline transition-colors duration-300',
      'hover:bg-gray-600/10 hover:text-gray-800',
      {
        'text-gray-600': isActive,
        'text-blue-900': !isActive
      }
    )}
    id={id}
  >
    {children}
  </NavLink>
);

const NavBar = () => {
  const { pathname } = useLocation();
  const isPoolActive = useIsPoolsPage();

  return (
    <nav className='absolute hidden w-full flex-row items-center justify-center gap-4 md:flex'>
      <MenuItem href={routes.swap} isActive={pathname.startsWith('/swap')}>
        Swap
      </MenuItem>
      <MenuItem href={routes.pools} isActive={isPoolActive}>
        Pools
      </MenuItem>
      <MenuItem href={routes.bridge} isActive={pathname.startsWith('/bridge')}>
        Bridge
      </MenuItem>
      <MenuDropdown />
    </nav>
  );
};

export default NavBar;
