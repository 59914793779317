import type { Percent } from '@uniswap/sdk-core';

import { useFormatter } from 'utils/formatNumbers';

import Button from 'components/ui/Button';

import { WarningIcon } from 'components/Icons';
import Modal from 'components/Modal';

interface PriceImpactModalProps {
  priceImpact: Percent;
  onDismiss: () => void;
  onContinue: () => void;
}

export const PriceImpactModal = ({
  priceImpact,
  onDismiss,
  onContinue
}: PriceImpactModalProps) => {
  const { formatPercent } = useFormatter();

  return (
    <Modal isOpen $scrollOverlay onDismiss={onDismiss} closeButton>
      <div className='flex flex-col justify-center gap-2 p-4'>
        <div className='flex justify-center py-8'>
          <WarningIcon className='size-12 stroke-red' />
        </div>

        <div className='flex flex-col justify-center gap-1'>
          <span className='text-md text-center'>
            This transaction will result in a{' '}
            <span className='text-md inline text-red'>
              ~{formatPercent(priceImpact)}
            </span>{' '}
            price impact on the market price of this pool. Do you wish to
            continue?
          </span>
        </div>

        <div className='flex flex-col gap-2'>
          <Button size='md' variant='secondary' onClick={onContinue}>
            Continue
          </Button>
        </div>
      </div>
    </Modal>
  );
};
