import useTransactionDeadline from './useTransactionDeadline';
import { useUniversalRouterSwapCallback } from './useUniversalRouter';
import type { Percent } from '@uniswap/sdk-core';
import { TradeType } from '@uniswap/sdk-core';
import type { FlatFeeOptions } from '@uniswap/universal-router-sdk';
import type { FeeOptions } from '@uniswap/v3-sdk';
import { BigNumber } from 'ethers/lib/ethers';
import { useCallback } from 'react';

import { currencyId } from '../utils/currencyId';
import { isClassicTrade } from 'state/routing/utils';

import { useTransactionAdder } from '../state/transactions/hooks';
import type { PermitSignature } from 'hooks/usePermitAllowance';
import { useWeb3React } from 'hooks/useWeb3React';
import { useAddOrder } from 'state/signatures/hooks';

import type {
  ExactInputSwapTransactionInfo,
  ExactOutputSwapTransactionInfo
} from '../state/transactions/types';
import { TransactionType } from '../state/transactions/types';
import type { InterfaceTrade } from 'state/routing/types';

export type SwapResult = Awaited<
  ReturnType<ReturnType<typeof useSwapCallback>>
>;

type UniversalRouterFeeField =
  | { feeOptions: FeeOptions }
  | { flatFeeOptions: FlatFeeOptions };

function getUniversalRouterFeeFields(
  trade?: InterfaceTrade
): UniversalRouterFeeField | undefined {
  if (!isClassicTrade(trade)) return undefined;
  if (!trade.swapFee) return undefined;

  if (trade.tradeType === TradeType.EXACT_INPUT) {
    return {
      feeOptions: {
        fee: trade.swapFee.percent,
        recipient: trade.swapFee.recipient
      }
    };
  } else {
    return {
      flatFeeOptions: {
        amount: BigNumber.from(trade.swapFee.amount),
        recipient: trade.swapFee.recipient
      }
    };
  }
}

// Returns a function that will execute a swap, if the parameters are all valid
// and the user has approved the slippage adjusted input amount for the trade
export function useSwapCallback(
  trade: InterfaceTrade | undefined, // trade to execute, required
  fiatValues: { amountIn?: number; amountOut?: number; feeUsd?: number }, // usd values for amount in and out, and the fee value, logged for analytics
  allowedSlippage: Percent, // in bips
  permitSignature: PermitSignature | undefined
) {
  const deadline = useTransactionDeadline();

  const addTransaction = useTransactionAdder();
  const addOrder = useAddOrder();
  const { account, chainId } = useWeb3React();

  const universalRouterSwapCallback = useUniversalRouterSwapCallback(
    isClassicTrade(trade) ? trade : undefined,
    fiatValues,
    {
      slippageTolerance: allowedSlippage,
      deadline,
      permit: permitSignature,
      ...getUniversalRouterFeeFields(trade)
    }
  );

  const swapCallback = universalRouterSwapCallback;

  return useCallback(async () => {
    if (!trade) throw new Error('missing trade');
    if (!account || !chainId)
      throw new Error('wallet must be connected to swap');

    const result = await swapCallback();

    const swapInfo:
      | ExactInputSwapTransactionInfo
      | ExactOutputSwapTransactionInfo = {
      type: TransactionType.SWAP,
      inputCurrencyId: currencyId(trade.inputAmount.currency),
      outputCurrencyId: currencyId(trade.outputAmount.currency),
      isUniswapXOrder: false,
      ...(trade.tradeType === TradeType.EXACT_INPUT
        ? {
            tradeType: TradeType.EXACT_INPUT,
            inputCurrencyAmountRaw: trade.inputAmount.quotient.toString(),
            expectedOutputCurrencyAmountRaw:
              trade.outputAmount.quotient.toString(),
            minimumOutputCurrencyAmountRaw: trade
              .minimumAmountOut(allowedSlippage)
              .quotient.toString()
          }
        : {
            tradeType: TradeType.EXACT_OUTPUT,
            maximumInputCurrencyAmountRaw: trade
              .maximumAmountIn(allowedSlippage)
              .quotient.toString(),
            outputCurrencyAmountRaw: trade.outputAmount.quotient.toString(),
            expectedInputCurrencyAmountRaw:
              trade.inputAmount.quotient.toString()
          })
    };

    addTransaction(result.response, swapInfo, deadline?.toNumber());

    return result;
  }, [
    account,
    addOrder,
    addTransaction,
    allowedSlippage,
    chainId,
    deadline,
    swapCallback,
    trade
  ]);
}
