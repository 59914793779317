import { type VariantProps, cva } from 'class-variance-authority';
import { type ButtonHTMLAttributes, forwardRef } from 'react';

import { cn } from 'utils/cn';

import { Slot } from '@radix-ui/react-slot';
import { EdgeShape } from 'components/ui/shapes';

const wrapperVariants = cva(
  'relative flex cursor-pointer disabled:cursor-default items-center justify-center border-0 outline-none group',
  {
    variants: {
      variant: {
        primary: [
          'text-gray-600 text-base font-medium uppercase transition-colors',
          'hover:text-blue-200 active:text-blue-200 focus:text-blue-200 disabled:text-gray-600'
        ],
        'primary-light': [
          'text-blue-200 text-sm font-medium transition-colors',
          'disabled:text-gray-600'
        ],
        secondary: [
          'text-blue-900 text-base font-medium uppercase transition-colors',
          'disabled:text-gray-600'
        ],
        action: 'text-blue-200'
      }
    },
    defaultVariants: {
      variant: 'primary'
    }
  }
);

const buttonVariants = cva('flex flex-grow items-center justify-center', {
  variants: {
    variant: {
      primary:
        'bg-blue-900 group-active:bg-blue-800 group-disabled:bg-gray-800',
      'primary-light':
        'bg-blue-800 group-hover:bg-blue-700 group-disabled:bg-gray-800',
      secondary:
        'bg-blue-200 group-active:bg-blue-300 group-hover:group-enabled:bg-blue-300 group-disabled:bg-gray-800',
      action: 'bg-transparent'
    },
    size: {
      xs: 'h-7.5',
      sm: 'h-10',
      md: 'w-full h-11.5',
      lg: 'w-auto h-12',
      xl: 'w-auto h-14'
    }
  },
  defaultVariants: {
    variant: 'primary',
    size: 'md'
  }
});

const edgeVariants = cva('flex-shrink-0', {
  variants: {
    size: {
      xs: 'w-auto h-7.5',
      sm: 'h-10',
      md: 'w-auto h-11.5',
      lg: 'w-auto h-12',
      xl: 'w-auto h-14'
    },
    variant: {
      primary:
        'fill-blue-900 group-active:fill-blue-800 group-disabled:fill-gray-800',
      'primary-light':
        'fill-blue-800 group-hover:fill-blue-700 group-disabled:fill-gray-800',
      secondary:
        'fill-blue-200 group-active:fill-blue-300 group-hover:fill-blue-300 group-disabled:fill-gray-800',
      action: 'fill-blue-900'
    }
  },
  defaultVariants: {
    variant: 'primary',
    size: 'md'
  }
});

export type ButtonProps = ButtonHTMLAttributes<HTMLButtonElement> &
  VariantProps<typeof buttonVariants> & {
    asChild?: boolean;
    hideRight?: boolean;
    hideLeft?: boolean;
  };

const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      children,
      variant,
      size,
      asChild,
      className,
      disabled,
      hideRight = false,
      hideLeft = false,
      ...props
    },
    ref
  ) => {
    const Comp = asChild ? Slot : 'button';

    return (
      <Comp
        ref={ref}
        className={cn(wrapperVariants({ variant }), className)}
        disabled={disabled}
        {...props}
      >
        <>
          {!hideLeft && (
            <EdgeShape
              className={cn(edgeVariants({ size, variant }), '-mr-px')}
            />
          )}

          <div
            className={cn(buttonVariants({ size, variant }), {
              'rounded-l': hideLeft,
              'rounded-r': hideRight
            })}
          >
            {children}
          </div>

          {!hideRight && (
            <EdgeShape
              className={cn(
                edgeVariants({ size, variant }),
                '-ml-px rotate-180'
              )}
            />
          )}
        </>
      </Comp>
    );
  }
);

Button.displayName = 'Button';
export default Button;
