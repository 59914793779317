import { useAtom } from 'jotai';

import { atomWithStorage } from 'jotai/utils';

import SettingsToggle from 'components/composed/SettingsToggle';

export const showTestnetsAtom = atomWithStorage<boolean>('showTestnets', true);

export function TestnetsToggle() {
  const [showTestnets, updateShowTestnets] = useAtom(showTestnetsAtom);

  return (
    <SettingsToggle
      title='Show testnets'
      dataID='testnets-toggle'
      isActive={showTestnets}
      toggle={() => void updateShowTestnets((value) => !value)}
    />
  );
}
