import SwapLineItem from './SwapLineItem';
import { SwapLineItemType } from './constants';
import type { SwapLineItemProps } from './types';
import type { Percent } from '@uniswap/sdk-core';
import { ReactComponent as ExpandoIconClosed } from 'assets/svg/expando-icon-closed.svg';
import { ReactComponent as ExpandoIconOpened } from 'assets/svg/expando-icon-opened.svg';
import ms from 'ms';
import { useState } from 'react';
import { easings, useSpring } from 'react-spring';

import Button from 'components/ui/Button';

import AnimatedDropdown from 'components/AnimatedDropdown';
import { WarningIcon } from 'components/Icons';
import Spinner from 'components/Icons/Spinner';

import type { InterfaceTrade } from 'state/routing/types';

export const DropdownController = ({
  open,
  onClick
}: {
  open: boolean;
  onClick: () => void;
}) => (
  <div
    onClick={onClick}
    className='mt-4 flex h-7 cursor-pointer items-center p-0'
  >
    <div className='h-px w-full flex-1 bg-gray-800' />

    <div className='flex items-center whitespace-nowrap py-4 pl-1.5 pr-px text-gray-600'>
      <span>{open ? 'Show less' : 'Show more'}</span>
      {open ? <ExpandoIconOpened /> : <ExpandoIconClosed />}
    </div>

    <div className='h-px w-full flex-1 bg-gray-800' />
  </div>
);

const AnimatedLineItem = (
  props: SwapLineItemProps & { open: boolean; delay: number }
) => {
  const { open, delay } = props;

  const animatedProps = useSpring({
    animatedOpacity: open ? 1 : 0,
    config: { duration: ms('300ms'), easing: easings.easeOutSine },
    delay
  });

  return <SwapLineItem {...props} {...animatedProps} />;
};

const ExpandableLineItems = (props: {
  trade: InterfaceTrade;
  allowedSlippage: Percent;
  open: boolean;
}) => {
  const { open, trade, allowedSlippage } = props;

  if (!trade) return null;

  const lineItemProps = { trade, allowedSlippage, syncing: false, open };

  return (
    <AnimatedDropdown
      open={open}
      springProps={{
        marginTop: open ? 0 : -12,
        config: {
          duration: ms('200ms'),
          easing: easings.easeOutSine
        }
      }}
    >
      <div className='flex flex-col gap-2'>
        <AnimatedLineItem
          {...lineItemProps}
          type={SwapLineItemType.PRICE_IMPACT}
          delay={ms('50ms')}
        />

        <AnimatedLineItem
          {...lineItemProps}
          type={SwapLineItemType.MAX_SLIPPAGE}
          delay={ms('100ms')}
        />

        <AnimatedLineItem
          {...lineItemProps}
          type={SwapLineItemType.MINIMUM_OUTPUT}
          delay={ms('120ms')}
        />

        <AnimatedLineItem
          {...lineItemProps}
          type={SwapLineItemType.MAXIMUM_INPUT}
          delay={ms('120ms')}
        />
      </div>
    </AnimatedDropdown>
  );
};

export const SwapModalFooter = ({
  trade,
  allowedSlippage,
  onConfirm,
  swapErrorMessage,
  disabledConfirm,
  showAcceptChanges,
  onAcceptChanges,
  isLoading
}: {
  trade: InterfaceTrade;
  allowedSlippage: Percent;
  onConfirm: () => void;
  swapErrorMessage?: React.ReactNode;
  disabledConfirm: boolean;
  showAcceptChanges: boolean;
  onAcceptChanges: () => void;
  isLoading: boolean;
}) => {
  const [showMore, setShowMore] = useState(false);

  const lineItemProps = { trade, allowedSlippage, syncing: false };

  return (
    <>
      <DropdownController
        open={showMore}
        onClick={() => setShowMore(!showMore)}
      />

      <div className='flex flex-col gap-4'>
        <SwapLineItem
          {...lineItemProps}
          type={SwapLineItemType.EXCHANGE_RATE}
        />

        <ExpandableLineItems {...lineItemProps} open={showMore} />

        <SwapLineItem
          {...lineItemProps}
          type={SwapLineItemType.INPUT_TOKEN_FEE_ON_TRANSFER}
        />

        <SwapLineItem
          {...lineItemProps}
          type={SwapLineItemType.OUTPUT_TOKEN_FEE_ON_TRANSFER}
        />
        <SwapLineItem {...lineItemProps} type={SwapLineItemType.SWAP_FEE} />

        <SwapLineItem {...lineItemProps} type={SwapLineItemType.NETWORK_COST} />
      </div>

      {showAcceptChanges ? (
        <div className='mt-4 flex flex-col items-center justify-center rounded-lg bg-blue-900 text-gray-100'>
          <div className='flex w-full flex-col gap-4'>
            <div className='flex flex-row items-center justify-center gap-1'>
              <WarningIcon className='size-5 stroke-yellow' />

              <span className='text-yellow'>Price updated</span>
            </div>

            <Button
              variant='secondary'
              className='w-full'
              onClick={onAcceptChanges}
            >
              Accept
            </Button>
          </div>
        </div>
      ) : (
        <div className='mt-4 flex w-full flex-col gap-2'>
          {swapErrorMessage ? (
            <div className='flex flex-row items-center justify-center gap-1'>
              <WarningIcon className='size-5 stroke-red' />

              <span className='text-red'>{swapErrorMessage}</span>
            </div>
          ) : null}

          <Button
            variant='secondary'
            className='w-full'
            onClick={onConfirm}
            disabled={disabledConfirm}
          >
            {isLoading ? (
              <span className='flex flex-row items-center gap-1 text-gray-800'>
                <Spinner className='size-6 stroke-gray-100' />
                Finalizing quote...
              </span>
            ) : (
              <span>Confirm swap</span>
            )}
          </Button>
        </div>
      )}
    </>
  );
};
