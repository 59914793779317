import type { SupportedChainsType } from '@uniswap/sdk-core';
import { ChainId, SUPPORTED_CHAINS } from '@uniswap/sdk-core';

export const CHAIN_IDS_TO_NAMES: Record<number, string> = {
  [ChainId.MAINNET]: 'mainnet',
  [ChainId.GOERLI]: 'goerli',
  [ChainId.SEPOLIA]: 'sepolia',
  [ChainId.POLYGON]: 'polygon',
  [ChainId.POLYGON_MUMBAI]: 'polygon_mumbai',
  [ChainId.CELO]: 'celo',
  [ChainId.CELO_ALFAJORES]: 'celo_alfajores',
  [ChainId.ARBITRUM_ONE]: 'arbitrum',
  [ChainId.ARBITRUM_GOERLI]: 'arbitrum_goerli',
  [ChainId.OPTIMISM]: 'optimism',
  [ChainId.OPTIMISM_GOERLI]: 'optimism_goerli',
  [ChainId.BNB]: 'bnb',
  [ChainId.AVALANCHE]: 'avalanche',
  [ChainId.BASE]: 'base',
  // NEBY_OASIS_CHANGES
  [ChainId.OASIS_EMERALD_MAINNET]: 'oasis_emerald_mainnet',
  [ChainId.OASIS_EMERALD_TESTNET]: 'oasis_emerald_testnet',
  [ChainId.OASIS_SAPPHIRE_MAINNET]: 'oasis_sapphire_mainnet',
  [ChainId.OASIS_SAPPHIRE_TESTNET]: 'oasis_sapphire_testnet',
  [ChainId.BASE_SEPOLIA_TESTNET]: 'base_sepolia_testnet',
  [ChainId.BSC_TESTNET]: 'bsc_testnet'
} as const;

export const SWAP_SUPPORTED_CHAIN_IDS = [
  ChainId.OASIS_EMERALD_MAINNET,
  ChainId.OASIS_EMERALD_TESTNET,
  ChainId.OASIS_SAPPHIRE_MAINNET,
  ChainId.OASIS_SAPPHIRE_TESTNET
] as const;

const NOT_YET_UX_SUPPORTED_CHAIN_IDS: number[] = [
  ChainId.MAINNET,
  ChainId.GOERLI,
  ChainId.SEPOLIA,
  ChainId.POLYGON,
  ChainId.CELO,
  ChainId.CELO_ALFAJORES,
  ChainId.ARBITRUM_ONE,
  ChainId.ARBITRUM_GOERLI,
  ChainId.OPTIMISM,
  ChainId.OPTIMISM_GOERLI,
  ChainId.BNB,
  ChainId.AVALANCHE,
  ChainId.BASE,
  ChainId.OASIS_EMERALD_MAINNET,
  ChainId.OASIS_EMERALD_TESTNET,
  ChainId.OASIS_SAPPHIRE_MAINNET
];

// TODO: include BASE_GOERLI when routing is implemented
export type SupportedInterfaceChain = Exclude<
  SupportedChainsType,
  ChainId.BASE_GOERLI
>;

export function isSupportedChain(
  chainId: number | null | undefined | ChainId,
  featureFlags?: Record<number, boolean>
): chainId is SupportedInterfaceChain {
  if (featureFlags && chainId && chainId in featureFlags) {
    return featureFlags[chainId];
  }
  return (
    !!chainId &&
    SUPPORTED_CHAINS.indexOf(chainId) !== -1 &&
    NOT_YET_UX_SUPPORTED_CHAIN_IDS.indexOf(chainId) === -1
  );
}

export function asSupportedChain(
  chainId: number | null | undefined | ChainId,
  featureFlags?: Record<number, boolean>
): SupportedInterfaceChain | undefined {
  if (!chainId) return undefined;
  if (featureFlags && chainId in featureFlags && !featureFlags[chainId]) {
    return undefined;
  }
  return isSupportedChain(chainId) ? chainId : undefined;
}

export const SUPPORTED_GAS_ESTIMATE_CHAIN_IDS = [
  ChainId.MAINNET,
  ChainId.POLYGON,
  ChainId.CELO,
  ChainId.OPTIMISM,
  ChainId.ARBITRUM_ONE,
  ChainId.BNB,
  ChainId.AVALANCHE,
  ChainId.BASE
] as const;

/**
 * Supported networks for V2 pool behavior.
 */
export const SUPPORTED_V2POOL_CHAIN_IDS = [
  ChainId.MAINNET,
  ChainId.GOERLI
] as const;

export const TESTNET_CHAIN_IDS = [
  ChainId.GOERLI,
  ChainId.SEPOLIA,
  ChainId.POLYGON_MUMBAI,
  ChainId.ARBITRUM_GOERLI,
  ChainId.OPTIMISM_GOERLI,
  ChainId.CELO_ALFAJORES,
  ChainId.OASIS_EMERALD_TESTNET,
  ChainId.OASIS_SAPPHIRE_TESTNET,
  ChainId.BASE_SEPOLIA_TESTNET,
  ChainId.BSC_TESTNET
] as const;

/**
 * All the chain IDs that are running the Ethereum protocol.
 */
export const L1_CHAIN_IDS = [
  ChainId.MAINNET,
  ChainId.GOERLI,
  ChainId.SEPOLIA,
  ChainId.POLYGON,
  ChainId.POLYGON_MUMBAI,
  ChainId.CELO,
  ChainId.CELO_ALFAJORES,
  ChainId.BNB,
  ChainId.AVALANCHE,
  ChainId.OASIS_EMERALD_MAINNET,
  ChainId.OASIS_EMERALD_TESTNET,
  ChainId.OASIS_SAPPHIRE_MAINNET,
  ChainId.OASIS_SAPPHIRE_TESTNET,
  ChainId.BSC_TESTNET
] as const;

export type SupportedL1ChainId = (typeof L1_CHAIN_IDS)[number];

/**
 * Controls some L2 specific behavior, e.g. slippage tolerance, special UI behavior.
 * The expectation is that all of these networks have immediate transaction confirmation.
 */
export const L2_CHAIN_IDS = [
  ChainId.ARBITRUM_ONE,
  ChainId.ARBITRUM_GOERLI,
  ChainId.OPTIMISM,
  ChainId.OPTIMISM_GOERLI,
  ChainId.BASE,
  ChainId.BASE_SEPOLIA_TESTNET
] as const;

export type SupportedL2ChainId = (typeof L2_CHAIN_IDS)[number];

/**
 * Get the priority of a chainId based on its relevance to the user.
 * @param {ChainId} chainId - The chainId to determine the priority for.
 * @returns {number} The priority of the chainId, the lower the priority, the earlier it should be displayed, with base of MAINNET=0.
 */
export function getChainPriority(chainId: ChainId): number {
  switch (chainId) {
    case ChainId.MAINNET:
    case ChainId.GOERLI:
    case ChainId.SEPOLIA:
      return 0;
    case ChainId.OASIS_EMERALD_MAINNET:
    case ChainId.OASIS_EMERALD_TESTNET:
    case ChainId.OASIS_SAPPHIRE_MAINNET:
    case ChainId.OASIS_SAPPHIRE_TESTNET:
    case ChainId.BASE_SEPOLIA_TESTNET:
    case ChainId.BSC_TESTNET:
      return 1;
    case ChainId.ARBITRUM_ONE:
    case ChainId.ARBITRUM_GOERLI:
      return 2;
    case ChainId.OPTIMISM:
    case ChainId.OPTIMISM_GOERLI:
      return 3;
    case ChainId.POLYGON:
    case ChainId.POLYGON_MUMBAI:
      return 4;
    case ChainId.BASE:
      return 5;
    case ChainId.BNB:
      return 6;
    case ChainId.AVALANCHE:
      return 7;
    case ChainId.CELO:
    case ChainId.CELO_ALFAJORES:
      return 8;
    default:
      return 9;
  }
}

export function isUniswapXSupportedChain(chainId: number) {
  return chainId === ChainId.MAINNET;
}

export const DEFAULT_CHAIN_ID = ChainId.OASIS_SAPPHIRE_TESTNET;
