import { NetworkType } from '@neby/chains';
import { ChainId } from '@uniswap/sdk-core';
import { getChainInfo } from 'constants/chainInfo';
import type { SupportedL2ChainId } from 'constants/chains';

export function isL2ChainId(
  chainId: number | undefined
): chainId is SupportedL2ChainId {
  const chainInfo = getChainInfo(chainId);
  return chainInfo?.networkType === NetworkType.L2;
}

export const TESTNET_CHAIN_IDS = [
  ChainId.GOERLI,
  ChainId.OPTIMISM_GOERLI,
  ChainId.ARBITRUM_GOERLI,
  ChainId.POLYGON_MUMBAI,
  ChainId.CELO_ALFAJORES,
  ChainId.OASIS_SAPPHIRE_TESTNET,
  ChainId.OASIS_EMERALD_TESTNET,
  ChainId.BASE_SEPOLIA_TESTNET,
  ChainId.BSC_TESTNET
];

export const isChainIdTestnet = (chainId: number | undefined) => {
  if (!chainId) return false;

  return TESTNET_CHAIN_IDS.includes(chainId);
};
