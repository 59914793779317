import { AnalyticsToggle } from './AnalyticsToggle';
import { GitVersionRow } from './GitVersionRow';
import { SlideOutMenu } from './SlideOutMenu';
import { SmallBalanceToggle } from './SmallBalanceToggle';
import { SpamToggle } from './SpamToggle';
import { TestnetsToggle } from './TestnetsToggle';
import { useCurrencyConversionFlagEnabled } from 'featureFlags/flags/currencyConversion';
import type { ReactNode } from 'react';
import { ChevronRight } from 'react-feather';

import { useActiveLocalCurrency } from 'hooks/useActiveLocalCurrency';

import Column from 'components/Column';
import Row from 'components/Row';
import styled from 'styled-components';
import { ClickableStyle, ThemedText } from 'theme/components';

const Container = styled(Column)`
  height: 100%;
  justify-content: space-between;
`;

const SectionTitle = styled(ThemedText.SubHeader)`
  color: ${({ theme }) => theme.neutral2};
  padding-bottom: 24px;
`;

const ToggleWrapper = styled.div<{ currencyConversionEnabled?: boolean }>`
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-bottom: ${({ currencyConversionEnabled }) =>
    currencyConversionEnabled ? '10px' : '24px'};
`;

const SettingsButtonWrapper = styled(Row)`
  ${ClickableStyle}
  padding: 16px 0px;
`;

const StyledChevron = styled(ChevronRight)`
  color: ${({ theme }) => theme.neutral2};
`;

const LanguageLabel = styled(Row)`
  white-space: nowrap;
`;

const SettingsButton = ({
  title,
  currentState,
  onClick,
  testId
}: {
  title: string;
  currentState: ReactNode;
  onClick: () => void;
  testId?: string;
}) => (
  <SettingsButtonWrapper
    data-testid={testId}
    align='center'
    justify='space-between'
    onClick={onClick}
  >
    <ThemedText.SubHeaderSmall color='textPrimary'>
      {title}
    </ThemedText.SubHeaderSmall>
    <LanguageLabel gap='xs' align='center' width='min-content'>
      <ThemedText.LabelSmall color='textPrimary'>
        {currentState}
      </ThemedText.LabelSmall>
      <StyledChevron size={20} />
    </LanguageLabel>
  </SettingsButtonWrapper>
);

export default function SettingsMenu({
  onClose,
  openLocalCurrencySettings
}: {
  onClose: () => void;
  openLocalCurrencySettings: () => void;
}) {
  const currencyConversionEnabled = useCurrencyConversionFlagEnabled();
  const activeLocalCurrency = useActiveLocalCurrency();

  return (
    <SlideOutMenu title='Settings' onClose={onClose}>
      <Container>
        <div>
          <SectionTitle data-testid='wallet-header'>Preferences</SectionTitle>
          <ToggleWrapper currencyConversionEnabled={currencyConversionEnabled}>
            <SmallBalanceToggle />
            <SpamToggle />
            <AnalyticsToggle />
            <TestnetsToggle />
          </ToggleWrapper>

          {currencyConversionEnabled && (
            <Column>
              <SettingsButton
                title='Currency'
                currentState={activeLocalCurrency}
                onClick={openLocalCurrencySettings}
                testId='local-currency-settings-button'
              />
            </Column>
          )}
        </div>
        <GitVersionRow />
      </Container>
    </SlideOutMenu>
  );
}
